import { Helmet } from "react-helmet-async";

import ActivateView from "src/sections/_auth/view/activate-view";

// ----------------------------------------------------------------------

export default function ActivatePage() {
  return (
    <>
      <ActivateView />
    </>
  );
}
