import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getQuestion } from "src/actions/questionActions";

export default function ResultsQuestionAbout() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { results: getResultQuestionsId, question: questionId } = useParams();

  const { isLoading: isLoadingQuestion, question } = useSelector(
    (state) => state.questionData
  );

  useEffect(() => {
    if (!question[questionId] && !isLoadingQuestion) {
      dispatch(getQuestion(questionId));
    }
  }, [location]);

  return (
    <Stack
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          py: 5,

          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography variant="h3">{question[questionId]?.question}</Typography>
        <Typography sx={{ color: "text.secondary" }}>
          Explore the insights of your question
        </Typography>
      </Stack>
    </Stack>
  );
}
