export const FETCH_CONVERSATION = "FETCH_CONVERSATION"; // Mostly for testing purposes

export const START_LOADING_CREATE_CONVERSATION =
  "START_LOADING_CREATE_CONVERSATION";
export const END_LOADING_CREATE_CONVERSATION =
  "END_LOADING_CREATE_CONVERSATION";
export const CREATE_CONVERSATION = "CREATE_CONVERSATION";

export const FETCH_QUESTION = "FETCH_QUESTION";
export const FETCH_QUESTIONS = "FETCH_QUESTIONS";
export const CREATE_USER = "CREATE_USER";

export const SEND_MESSAGE = "SEND_MESSAGE";

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";

export const START_LOADING_GPTRESPONSE = "START_LOADING_GPTRESPONSE";
export const END_LOADING_GPTRESPONSE = "END_LOADING_GPTRESPONSE";

export const FETCH_SUMMARY = "FETCH_SUMMARY";
export const START_LOADING_SUMMARY = "START_LOADING_SUMMARY";
export const END_LOADING_SUMMARY = "END_LOADING_SUMMARY";

export const FETCH_TABLE = "FETCH_TABLE";
export const START_LOADING_TABLE = "START_LOADING_TABLE";
export const END_LOADING_TABLE = "END_LOADING_TABLE";

export const ASK_QUESTION = "ASK_QUESTION";
export const START_LOADING_ASK = "START_LOADING_ASK";
export const END_LOADING_ASK = "END_LOADING_ASK";

export const FETCH_QUESTION_ANSWER = "FETCH_QUESTION_ANSWER";
export const START_LOADING_QUESTION_ANSWER = "START_LOADING_QUESTION_ANSWER";
export const END_LOADING_QUESTION_ANSWER = "END_LOADING_QUESTION_ANSWER";

export const RATE_MESSAGE = "RATE_MESSAGE";

export const FETCH_CONVERSATION_RESULTS = "FETCH_CONVERSATION_RESULTS";

export const FETCH_CONVERSATION_STATS = "FETCH_CONVERSATION_STATS";
export const START_LOADING_CONVERSATION_STATS =
  "START_LOADING_CONVERSATION_STATS";
export const END_LOADING_CONVERSATION_STATS = "END_LOADING_CONVERSATION_STATS";

export const FETCH_ALL_QUESTION_STATS = "FETCH_ALL_QUESTION_STATS";
export const START_LOADING_ALL_QUESTION_STATS =
  "START_LOADING_ALL_QUESTION_STATS";
export const END_LOADING_ALL_QUESTION_STATS = "END_LOADING_ALL_QUESTION_STATS";

export const ADD_RESULTS_MESSAGE = "ADD_RESULTS_MESSAGE";

export const FETCH_CONVERSATION_IDEAS_STATS = "FETCH_CONVERSATION_IDEAS_STATS";
export const START_LOADING_CONVERSATION_IDEAS_STATS =
  "START_LOADING_CONVERSATION_IDEAS_STATS";
export const END_LOADING_CONVERSATION_IDEAS_STATS =
  "END_LOADING_CONVERSATION_IDEAS_STATS";

export const FETCH_QUESTION_IDEAS_STATS = "FETCH_QUESTION_IDEAS_STATS";
export const START_LOADING_QUESTION_IDEAS_STATS =
  "START_LOADING_QUESTION_IDEAS_STATS";
export const END_LOADING_QUESTION_IDEAS_STATS =
  "END_LOADING_QUESTION_IDEAS_STATS";

export const FETCH_QUESTION_CATEGORIES = "FETCH_QUESTION_CATEGORIES";
export const START_LOADING_QUESTION_CATEGORIES =
  "START_LOADING_QUESTION_CATEGORIES";
export const END_LOADING_QUESTION_CATEGORIES =
  "END_LOADING_QUESTION_CATEGORIES";

export const FETCH_IDEA_FEEDBACK = "FETCH_IDEA_FEEDBACK";
export const START_LOADING_IDEA_FEEDBACK = "START_LOADING_IDEA_FEEDBACK";
export const END_LOADING_IDEA_FEEDBACK = "END_LOADING_IDEA_FEEDBACK";

export const FETCH_USER_RESPONSE_DATA = "FETCH_USER_RESPONSE_DATA";
export const START_LOADING_USER_RESPONSE_DATA =
  "START_LOADING_USER_RESPONSE_DATA";
export const END_LOADING_USER_RESPONSE_DATA = "END_LOADING_USER_RESPONSE_DATA";

export const SET_IDEAS_TABLE_STATE = "SET_IDEAS_TABLE_STATE";
export const SET_USER_RESPONSES_TABLE_STATE = "SET_USER_RESPONSES_TABLE_STATE";
export const SET_CATEGORIES_TABLE_STATE = "SET_CATEGORIES_TABLE_STATE";

export const GET_USER_RESPONSE = "GET_USER_RESPONSE";
export const START_LOADING_USER_RESPONSE = "START_LOADING_USER_RESPONSE";
export const END_LOADING_USER_RESPONSE = "END_LOADING_USER_RESPONSE";

// AUTH actions

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAIL = "ACTIVATION_FAIL";
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";
export const START_GOOGLE_AUTH = "START_GOOGLE_AUTH";
export const END_GOOGLE_AUTH = "END_GOOGLE_AUTH";
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const LOGOUT = "LOGOUT";

// ACCOUNT actions
export const START_LOADING_CREATED_CONVERSATIONS =
  "START_LOADING_CREATED_CONVERSATIONS";
export const END_LOADING_CREATED_CONVERSATIONS =
  "END_LOADING_CREATED_CONVERSATIONS";
export const FETCH_CREATED_CONVERSATIONS = "FETCH_CREATED_CONVERSATIONS";

export const START_LOADING_CREATED_CONVERSATION =
  "START_LOADING_CREATED_CONVERSATION";
export const END_LOADING_CREATED_CONVERSATION =
  "END_LOADING_CREATED_CONVERSATION";
export const FETCH_CREATED_CONVERSATION = "FETCH_CREATED_CONVERSATION";

export const CHANGE_CONVERSATION_STATUS = "CHANGE_CONVERSATION_STATUS";
export const CHANGE_CONVERSATION_REJOIN = "CHANGE_CONVERSATION_REJOIN";
export const DELETE_CONVERSATION = "DELETE_CONVERSATION";

export const START_LOADING_ADD_ACCOUNT = "START_LOADING_ADD_ACCOUNT";
export const END_LOADING_ADD_ACCOUNT = "END_LOADING_ADD_ACCOUNT";
export const ADD_ACCOUNT = "ADD_ACCOUNT";

export const START_LOADING_REMOVE_ACCOUNT = "START_LOADING_REMOVE_ACCOUNT";
export const END_LOADING_REMOVE_ACCOUNT = "END_LOADING_REMOVE_ACCOUNT";
export const REMOVE_ACCOUNT = "REMOVE_ACCOUNT";

// PAYMENT actions
export const PURCHASE_PRODUCT = "PURCHASE_PRODUCT";

// Single Category Actions
export const FETCH_SINGLE_CATEGORY = "FETCH_SINGLE_CATEGORY";
export const START_LOADING_SINGLE_CATEGORY = "START_LOADING_SINGLE_CATEGORY";
export const END_LOADING_SINGLE_CATEGORY = "END_LOADING_SINGLE_CATEGORY";

export const CREATE_TRAITS_SUMMARY = "CREATE_TRAITS_SUMMARY";
export const START_LOADING_TRAITS_SUMMARY = "START_LOADING_TRAITS_SUMMARY";
export const END_LOADING_TRAITS_SUMMARY = "END_LOADING_TRAITS_SUMMARY";

export const CREATE_TRAITS_TOP_ANSWER = "CREATE_TRAITS_TOP_ANSWER";
export const START_LOADING_TRAITS_TOP_ANSWER =
  "START_LOADING_TRAITS_TOP_ANSWER";
export const END_LOADING_TRAITS_TOP_ANSWER = "END_LOADING_TRAITS_TOP_ANSWER";
