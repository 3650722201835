import { Helmet } from "react-helmet-async";

import RegisterView from "src/sections/_auth/view/register-view";

// ----------------------------------------------------------------------

export default function RegisterPage() {
  return (
    <>
      <RegisterView />
    </>
  );
}
