import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import axios from "axios";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";

import { paths } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import { RouterLink } from "src/routes/components";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signup } from "src/actions/authActions";
import { set } from "nprogress";
import Alert from "@mui/material/Alert";
// ----------------------------------------------------------------------

export default function RegisterView() {
  const [accountCreated, setAccountCreated] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const passwordShow = useBoolean();
  const [signupError, setSignupError] = useState(null);

  useEffect(() => {
    if (accountCreated) {
      navigate("/login");
    }
  }, [accountCreated]);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Full name is required")
      .min(1, "Mininum 1 characters")
      .max(25, "Maximum 25 characters"),
    lastName: Yup.string()
      .required("Full name is required")
      .min(1, "Mininum 1 characters")
      .max(25, "Maximum 25 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("That is not an email"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password should be of minimum 6 characters length"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password")], "Password's don't match"),
  });

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await dispatch(
        signup(
          data.firstName,
          data.lastName,
          data.email,
          data.password,
          data.confirmPassword
        )
      );

      if (response !== "Success") {
        setSignupError("An error occurred during signup: " + response);
        console.log(response);
      } else {
        setAccountCreated(true);
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  });

  const email = watch("email");

  useEffect(() => {
    if (email) {
      setSignupError(null);
    }
  }, [email]);

  const continueWithGoogle = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_URL}`
      );
      window.location.replace(res.data.authorization_url);
    } catch (error) {
      console.log(error);
    }
  };

  const renderHead = (
    <div>
      <Typography variant="h3" paragraph>
        Get Started
      </Typography>
      <Typography variant="h5" paragraph>
        New accounts get 4 weeks complimentary
      </Typography>

      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {`Already have an account? `}
        <Link
          component={RouterLink}
          rel="noopener"
          to="/login"
          variant="subtitle2"
          color="primary"
        >
          Login
        </Link>
      </Typography>
    </div>
  );

  const renderSocials = (
    <Stack direction="row" spacing={2}>
      <Button
        fullWidth
        size="large"
        color="inherit"
        variant="outlined"
        onClick={continueWithGoogle}
      >
        <Iconify icon="logos:google-icon" width={24} />
      </Button>

      {/* <Button fullWidth size="large" color="inherit" variant="outlined">
        <Iconify
          icon="carbon:logo-facebook"
          width={24}
          sx={{ color: "#1877F2" }}
        />
      </Button>

      <Button color="inherit" fullWidth variant="outlined" size="large">
        <Iconify
          icon="carbon:logo-github"
          width={24}
          sx={{ color: "text.primary" }}
        />
      </Button> */}
    </Stack>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        <RHFTextField name="firstName" label="First Name" />
        <RHFTextField name="lastName" label="Last Name" />

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={passwordShow.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordShow.onToggle} edge="end">
                  <Iconify
                    icon={
                      passwordShow.value ? "carbon:view" : "carbon:view-off"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label="Confirm Password"
          type={passwordShow.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordShow.onToggle} edge="end">
                  <Iconify
                    icon={
                      passwordShow.value ? "carbon:view" : "carbon:view-off"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Register
        </LoadingButton>
        {signupError && <Alert severity="warning">{signupError}</Alert>}

        <Typography
          variant="caption"
          align="center"
          sx={{ color: "text.secondary", mt: 3 }}
        >
          {`I agree to `}
          <Link color="text.primary" href="#" underline="always">
            Terms of Service
          </Link>
          {` and `}
          <Link color="text.primary" href="#" underline="always">
            Privacy Policy.
          </Link>
        </Typography>
      </Stack>
    </FormProvider>
  );

  return (
    <>
      {renderHead}

      {renderForm}

      <Divider>
        <Typography variant="body2" sx={{ color: "text.disabled" }}>
          or continue with
        </Typography>
      </Divider>

      {renderSocials}
    </>
  );
}
