import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  List,
  ListItemText,
  IconButton,
  Snackbar,
  Grid,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import CodeIcon from "@mui/icons-material/Code";
import { styled } from "@mui/system";
import { alpha } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const CustomIconButton = styled(IconButton)({
  color: "#03045E",
});

const ConversationFinishedResults = ({ results }) => {
  const baseURL = window.location.origin;
  const responseURL = `${baseURL}/results/${results}`;

  const [open, setOpen] = useState(false);

  const handleClickResults = () => {
    navigator.clipboard.writeText(responseURL);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    // <Box
    //   sx={{
    //     borderWidth: 2,
    //     borderRadius: 3,
    //     textAlign: "center",
    //     borderStyle: "dashed",
    //     borderColor: (theme) => alpha(theme.palette.grey[500], 0.32),
    //     px: { xs: 3, md: 8 },
    //     py: { xs: 6, md: 8 },
    //     width: { xs: "95%", md: "50%" },
    //     margin: "auto",
    //   }}
    // >
    <Container>
      <List>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={9}>
            <ListItemText>
              <Typography variant="h5">
                Interested in the results of this conversation?
              </Typography>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Use this URL
              </Typography>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                <a
                  href={responseURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {responseURL}
                </a>
              </Typography>
            </ListItemText>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Tooltip title="Copy to Clipboard">
              <CustomIconButton
                edge="end"
                aria-label="share"
                onClick={handleClickResults}
              >
                <CodeIcon />
              </CustomIconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </List>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Link copied to clipboard"
      />
    </Container>
  );
};

export default ConversationFinishedResults;
