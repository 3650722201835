import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import CircularProgress from "@mui/material/CircularProgress";

import { PieIdeasRatings } from "src/components/dashboard/pie-chart-idea-ratings";
import { PieDiversity } from "src/components/dashboard/pie-chart-diversity";
import { RatingDistribution } from "src/components/dashboard/rating-distribution";
import { StackedQuestionRatings } from "src/components/dashboard/stacked-question-ratings";
import { TotalRatingsCard } from "src/components/dashboard/total-ratings-card";
import { BarCard } from "src/components/dashboard/bar-card";
import { getConversationIdeaStats } from "src/actions/resultsIdeasActions";
import { Divider, Typography } from "@mui/material";
import ResultsIdeasPhase2 from "./results-ideas-phase2";

import { PieTraits } from "src/components/dashboard/pie-traits";
const ResultsIdeasDashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { results: conversationResultsId } = useParams();
  const { isLoading: isLoadingIdeaStats, ideaStats } = useSelector(
    (state) => state.conversationIdeasData
  );

  const { isLoading: isLoadingConversation, conversation } = useSelector(
    (state) => state.conversationData
  );

  useEffect(() => {
    if (!ideaStats && !isLoadingIdeaStats) {
      dispatch(getConversationIdeaStats(conversationResultsId));
    }
  }, []);

  if (isLoadingIdeaStats) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {ideaStats && (
        <Stack
          direction={{ xs: "column", md: "column" }}
          justifyContent="space-between"
          sx={{
            paddingLeft: { xs: 0, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 8,
            }}
          >
            <Typography variant="h2" sx={{ mb: 2 }}>
              Results
            </Typography>
            {/* <Container
              sx={{
                flexGrow: 1,
                textAlign: "left",
                display: "grid",
                gap: { xs: 5, md: 2 },
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(4, 1fr)",
                },
              }}
            >
              <TotalRatingsCard
                title="Total Ideas"
                value={ideaStats?.num_ideas}
                color={theme.palette.success.main}
                icon="carbon:list"
                info="Real-time count of all ideas generated throughout your Conversation."
              />
              <TotalRatingsCard
                title="IDEAS PER PARTICIPANT"
                value={ideaStats?.average_ideas_per_participant}
                color={theme.palette.warning.main}
                icon="carbon:idea"
                info="Average number of ideas generated per participant in the conversation."
              />
              {/* <BarCard
                  title="Related Ideas"
                  number={ideaStats?.num_related_ideas}
                  percent={
                    (ideaStats?.num_related_ideas / ideaStats?.num_ideas) * 100
                  }
                  color={theme.palette.warning.main}
                  icon="carbon:idea"
                  info="Tsdt dsfsd f sd fsd f "
                /> */}
            {/* <TotalRatingsCard
                title="IDEA EXPOSURE COUNT"
                value={ideaStats?.total_exposure_count}
                color={theme.palette.error.main}
                icon="carbon:asset"
                info="Total count of participant ratings collected across all ideas"
              />
              <BarCard
                title="Average Idea Rating"
                number={ideaStats?.average_rating}
                percent={ideaStats?.average_rating * 20}
                color={theme.palette.primary.main}
                icon="carbon:star"
                info="Average participant rating level across all ideas"
              />
            </Container> */}
            <Grid
              container
              spacing={3}
              sx={{
                p: 3,
                textAlign: { xs: "center", md: "left" },
                display: "flex",
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex", // Add this line
                  flexDirection: "column",
                }}
              >
                <PieTraits
                  title="Contributing Demographics"
                  chartSeries={Object.values(ideaStats?.traits)}
                  labels={Object.keys(ideaStats?.traits)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex", // Add this line
                  flexDirection: "column",
                }}
              >
                {/* <RatingDistribution
                  chartSeries={ideaStats?.histogram} // Example data for ratings 1 to 5 with 0.5 intervals
                  sx={{ height: "100%" }}
                  title="Rating Distribution"
                /> */}
                <PieIdeasRatings
                  title="Idea Diversity"
                  chartSeries={ideaStats?.diversity_donut_ratings}
                  labels={["Unique Ideas", "Common Ideas"]}
                  sx={{ height: "100%" }}
                />
              </Grid>
            </Grid>
            {ideaStats?.phase_2 && <ResultsIdeasPhase2 />}
            {/* <Grid
              container
              spacing={3}
              sx={{
                p: 3,
                textAlign: { xs: "center", md: "left" },
                display: "flex",
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex", // Add this line
                  flexDirection: "column",
                }}
              >
                <PieIdeasRatings
                  title="Idea Diversity"
                  chartSeries={ideaStats?.diversity_donut_ratings}
                  labels={["Unique Ideas", "Common Ideas"]}
                  sx={{ height: "100%" }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex", // Add this line
                  flexDirection: "column",
                }}
              >
                <StackedQuestionRatings
                  chartSeries={ideaStats?.ratings_per_question}
                  sx={{ height: "100%" }}
                  title="Ratings For Question"
                />
              </Grid>
            </Grid> */}
          </Box>
        </Stack>
      )}
    </>
  );
};

export default ResultsIdeasDashboard;
