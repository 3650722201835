import {
  SET_IDEAS_TABLE_STATE,
  SET_USER_RESPONSES_TABLE_STATE,
  SET_CATEGORIES_TABLE_STATE,
} from "./actionTypes";

export const setIdeaTableState = (state) => ({
  type: SET_IDEAS_TABLE_STATE,
  payload: state,
});

export const setUserResponsesTableState = (state) => ({
  type: SET_USER_RESPONSES_TABLE_STATE,
  payload: state,
});

export const setCategoriesTableState = (state) => ({
  type: SET_CATEGORIES_TABLE_STATE,
  payload: state,
});
