import PropTypes from "prop-types";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { bgBlur } from "src/theme/css";
import Logo from "src/components/logo";
// import { paths } from "src/routes/paths";
// import Label from "src/components/label";
import { useResponsive } from "src/hooks/use-responsive";
import { useOffSetTop } from "src/hooks/use-off-set-top";

import { HEADER } from "../config-layout";
// import Searchbar from "../common/searchbar";
import HeaderShadow from "../common/header-shadow";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  checkAuthenticated,
  load_user,
  logout,
  googleAuthenticate,
} from "src/actions/authActions";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { set } from "nprogress";
import { CircularProgress } from "@mui/material";
// ----------------------------------------------------------------------

export default function Header({ headerOnDark }) {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const theme = useTheme();

  const offset = useOffSetTop();

  const mdUp = useResponsive("up", "md");

  const dispatch = useDispatch();
  const prevToken = localStorage.getItem("token");

  const { access, refresh, isAuthenticated, user, googleLoading } = useSelector(
    (state) => state.authData
  );

  const regex = /^\/questions\/[A-Za-z0-9-]+$/;

  const isMatch = regex.test(path);

  // useEffect(() => {
  //   const values = queryString.parse(location.search);
  //   const state = values.state ? values.state : null;
  //   const code = values.code ? values.code : null;

  //   console.log("state: ", state);
  //   console.log("code: ", code);
  //   if (state && code) {
  //     dispatch(googleAuthenticate(state, code));
  //   } else {
  //     dispatch(checkAuthenticated());
  //     dispatch(load_user());
  //   }
  // }, [location]);

  useEffect(() => {
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Extract code from URL parameters
    const code = urlParams.get("code");

    if (code) {
      const data = dispatch(googleAuthenticate(code, navigate));
      // Redirect to the clean URL
      navigate("/");
    }

    dispatch(checkAuthenticated());
    dispatch(load_user());
  }, [navigate]);

  const handleLogout = () => {
    dispatch(logout()); // Update redux state
  };

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(["height", "background-color"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: "common.white",
          }),
          ...(offset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: "text.primary",
            height: {
              md: HEADER.H_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container
          sx={{
            height: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ lineHeight: 0, position: "relative" }}>
            <Logo />
          </Box>
          {!isMatch && (
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {user && (
                <Button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                    textTransform: "none", // to prevent uppercase text if you don't want it
                  }}
                  rel="noopener"
                  component={RouterLink}
                  to="/account/created-conversations"
                >
                  {user?.first_name}
                </Button>
              )}

              {!isAuthenticated ? (
                <Button
                  color="inherit"
                  variant="contained"
                  rel="noopener"
                  component={RouterLink}
                  to="/login"
                >
                  Login
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleLogout}
                  rel="noopener"
                  component={RouterLink}
                  to="/"
                >
                  Log out
                </Button>
              )}
            </Stack>
          )}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}

Header.propTypes = {
  headerOnDark: PropTypes.bool,
};
