import React from "react";
import TrialBenefits from "../trial-benefits";
import TrialPlans from "../trial-plans";
import TrialHowItWorks from "../trial-how-it-works";
import RequestPricing from "../trial-request-pricing";
import { useRef, useEffect } from "react";

const ICONS = [
  "carbon:time",
  "carbon:zoom-in",
  "carbon:intent-request-scale-out",
  "carbon:network-1",
  "carbon:information",
  "carbon:virtual-column-key",
  "carbon:rocket",
  "carbon:event-schedule",
];

export const _jobsByCategories = [
  {
    id: 0,
    icon: ICONS[0],
    name: "Time-saving Automation",
  },
  {
    id: 1,
    icon: ICONS[1],
    name: "Deeper Insights",
  },
  {
    id: 2,
    icon: ICONS[2],
    name: "Scale to Any Size",
  },
  {
    id: 3,
    icon: ICONS[3],
    name: "Asynchronous Collaboration",
  },
  {
    id: 4,
    icon: ICONS[4],
    name: "No Information Overload",
  },
  {
    id: 5,
    icon: ICONS[5],
    name: "Key Insight Identification",
  },
  {
    id: 6,
    icon: ICONS[6],
    name: "Launch in Minutes",
  },
  {
    id: 7,
    icon: ICONS[7],
    name: "No Scheduling Issues",
  },
];

const TrialView = () => {
  const targetRef = useRef(null);
  const pricingRef = useRef(null);
  const categorieRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <TrialPlans targetRef={targetRef} pricingRef={pricingRef} />
      <TrialHowItWorks ref={targetRef} targetRef={categorieRef} />
      <TrialBenefits categories={_jobsByCategories} ref={categorieRef} />
      <RequestPricing ref={pricingRef} />
    </div>
  );
};

export default TrialView;
