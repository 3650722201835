import React from "react";
import { useEffect } from "react";
import ConversationForm from "../create-conversation-form";
import CreateConversationHowItWork from "../create-conversation-how-it-works";
import CreateConversationSteps from "../create-conversation-steps";
// ----------------------------------------------------------------------

export default function CreateConversationView() {
  const targetRef = React.useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ConversationForm targetRef={targetRef} />
      <CreateConversationSteps ref={targetRef} />
    </>
  );
}
