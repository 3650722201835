import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Stack, Typography, useTheme } from "@mui/material";

const TraitsSelector = ({
  traits,
  selectedTraits,
  setSelectedTraits,
  traitSelected,
  setTraitSelected,
}) => {
  const theme = useTheme();

  // Adding a sequential number to each trait
  const enrichedTraits = traits?.map((trait, index) => ({
    ...trait,
    index: index,
  }));

  const handleTraitClick = (traitId) => {
    setSelectedTraits((prevSelectedTraits) => {
      if (prevSelectedTraits.includes(traitId)) {
        return prevSelectedTraits.filter((id) => id !== traitId);
      } else {
        return [...prevSelectedTraits, traitId];
      }
    });
  };

  useEffect(() => {
    setTraitSelected(selectedTraits.length > 0);
  }, [selectedTraits, setTraitSelected]);

  const getButtonColor = (index) => {
    return index % 2 === 0
      ? theme.palette.primary.main
      : theme.palette.primary.lighter;
  };

  return (
    <>
      <Typography variant="h7">Please select all that apply to you:</Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          mt: 1,
          maxWidth: "560px",
          flexWrap: "wrap",
          justifyContent: { sm: "center", md: "flex-start" },
        }}
      >
        {enrichedTraits
          ?.filter((trait) => trait.text !== "Other")
          .map((trait) => (
            <Button
              key={trait.trait_id}
              variant={
                selectedTraits.includes(trait.trait_id)
                  ? "contained"
                  : "outlined"
              }
              onClick={() => handleTraitClick(trait.trait_id)}
              sx={{
                border: "1px solid",
              }}
            >
              {trait.text}
            </Button>
          ))}

        {enrichedTraits?.find((trait) => trait.text === "Other") && (
          <Button
            key={
              enrichedTraits.find((trait) => trait.text === "Other").trait_id
            }
            variant={
              selectedTraits.includes(
                enrichedTraits.find((trait) => trait.text === "Other").trait_id
              )
                ? "contained"
                : "outlined"
            }
            onClick={() =>
              handleTraitClick(
                enrichedTraits.find((trait) => trait.text === "Other").trait_id
              )
            }
            sx={{
              border: "1px solid",
            }}
          >
            Other
          </Button>
        )}
      </Stack>
    </>
  );
};

export default TraitsSelector;
