import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const InstructionsAlert = ({ instructionsOpen, setInstructionsOpen }) => {
  const handleClose = () => {
    setInstructionsOpen(false);
  };
  const theme = useTheme();

  return (
    <Dialog
      open={instructionsOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Instructions</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You are now entering the collective validation phase of this
          conversation where you will be asked to{" "}
          <span style={{ color: theme.palette.primary.main }}>anonymously</span>{" "}
          evaluate other participants ideas.
        </DialogContentText>
        <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
          Use the{" "}
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            slider
          </span>{" "}
          (pictured below) to rate the quality of each idea and use the text box
          to provide optional qualitative feedback.
        </DialogContentText>
      </DialogContent>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center" // Center items horizontally
        sx={{ width: "100%" }} // Ensure Stack takes full width
      >
        <Slider
          track={false}
          defaultValue={3}
          step={1}
          min={1}
          max={5}
          marks
          sx={{
            width: "200px",
            color: "black",
            mt: 3,
            // Remove or adjust marginLeft if necessary
          }}
        />
      </Stack>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstructionsAlert;
