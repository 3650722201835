import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
  alpha,
} from "@mui/material";
import ApexChart from "react-apexcharts";
import { styled } from "@mui/material/styles";
import { useResponsive, useWidth } from "src/hooks/use-responsive";
import { useDispatch, useSelector } from "react-redux";
export const Chart = styled(ApexChart)``;

const useChartOptions = (labels, numberOfQuestions) => {
  const theme = useTheme();
  const mdUp = useResponsive("up", "md");

  const { isLoading: isLoadingQuestion, questions } = useSelector(
    (state) => state.questionData
  );
  const categories = questions.reduce((acc, question, index) => {
    if (question.phase_2) {
      acc.push(`Question ${index + 1}`);
    }
    return acc;
  }, []);
  return {
    chart: {
      background: "transparent",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.success.main,
      theme.palette.warning.main,
      theme.palette.error.main,
      theme.palette.info.dark,
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      position: "right",
      offsetY: 10,
    },
    plotOptions: {
      bar: {
        horizontal: false,

        columnWidth: `${numberOfQuestions * 10}%`,
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: categories,
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

export const StackedQuestionRatings = (props) => {
  const { chartSeries, labels, sx, title } = props;
  const numberOfQuestions = chartSeries[0]?.data?.length || 0; // Get the number of questions based on the data length
  const chartOptions = useChartOptions(labels, numberOfQuestions);

  const widthKey = useWidth();
  const mdUp = useResponsive("up", "md");

  // Define the height based on the width key
  let height;
  switch (widthKey) {
    case "xs":
      height = 160;
      break;
    case "sm":
      height = 250;
      break;
    case "md":
      height = 200;
      break;

    default:
      height = 300;
  }

  return (
    <Stack
      spacing={3}
      sx={{ p: 1, borderRadius: 2, bgcolor: "background.neutral" }}
    >
      <Typography variant="h6" sx={{ p: 3 }}>
        {title}
      </Typography>
      <Chart
        height={height}
        options={chartOptions}
        series={chartSeries}
        type="bar"
        width="100%"
      />
    </Stack>
  );
};

StackedQuestionRatings.propTypes = {
  chartSeries: PropTypes.array.isRequired,
  sx: PropTypes.object,
  title: PropTypes.string.isRequired,
};
