import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Switch,
  CircularProgress,
  Chip,
} from "@mui/material";
import SubjectIcon from "@mui/icons-material/Subject";
import DescriptionIcon from "@mui/icons-material/Description";
import Question from "src/components/questionForm/question/question";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDispatch } from "react-redux";
import { createConversation } from "src/actions/conversationActions";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { alpha, useTheme } from "@mui/material/styles";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InfoTooltip from "src/components/dashboard/info-tooltip";
import { useResponsive } from "src/hooks/use-responsive";
import Iconify from "src/components/iconify";
import { date } from "yup";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { set } from "nprogress";
import HelpIcon from "@mui/icons-material/Help";
import Link from "@mui/material/Link";

dayjs.extend(utc);

const ConversationForm = ({ targetRef }) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const now = dayjs();
  const dispatch = useDispatch();

  const [questions, setQuestions] = useState([
    {
      question: "",
      // prompt: "",
      depth: 3,
      phase2: false,
      exposeAll: false,
    },
  ]);
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [description, setDescription] = useState("");
  const [numParticipants, setNumParticipants] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [showCodeWarning, setShowCodeWarning] = useState(false);
  const [prompt, setPrompt] = useState(
    "You are an experienced journalist known for your ability to explore topics impartially and extract valuable insights through direct and concise questioning. To gather my insights on a subject, please ask me one question at a time, I am currently responding to {question}, and wait for my response before proceeding with another question. My name is {sender}. Please keep asking questions until I end the conversation. \\n{{history}} \\n{sender}: {{human_input}} \\nJournalist: "
  );
  const [code, setCode] = useState("");
  const [selectedTimeLocal, setSelectedTimeLocal] = useState(
    now.add(24, "hour")
  );
  const [selectedTimeUTC, setSelectedTimeUTC] = useState(now.utc().format());
  const [shareResults, setShareResults] = useState(false);
  const [shareNames, setShareNames] = useState(false);
  const [demographics, setDemographics] = useState([]);
  const [demographic, setDemographic] = useState("");
  const [demographicWarning, setDemographicWarning] = useState(false);
  const [showDemographicWarning, setShowDemographicWarning] = useState(false);

  const [conversationPhase2, setConversationPhase2] = useState(false);
  const [phase1EndDate, setPhase1EndDate] = useState(dayjs().add(1, "day"));
  const [phase2EndDate, setPhase2EndDate] = useState(dayjs().add(2, "day"));

  const [showPhase1DateWarning, setShowPhase1DateWarning] = useState(false);
  const [phase1DateWarning, setPhase1DateWarning] = useState("");
  const [showPhase2DateWarning, setShowPhase2DateWarning] = useState(false);
  const [phase2DateWarning, setPhase2DateWarning] = useState("");

  const [userSelectedDate, setUserSelectedDate] = useState(false);

  useEffect(() => {
    // If in the questions array any of the questions have phase2 true, then set conversationPhase2 to true
    setConversationPhase2(questions.some((q) => q.phase2));
  }, [questions]);

  const handlePhase1EndDateChange = (newValue) => {
    setPhase1EndDate(newValue);

    // Check if the phase 1 end date is before the current date
    if (newValue.isBefore(dayjs())) {
      setPhase1DateWarning("Ideation end date must be after the current date");
      setShowPhase1DateWarning(true);
    } else {
      setShowPhase1DateWarning(false);
    }
    if (
      conversationPhase2 &&
      newValue.isAfter(phase2EndDate.subtract(15, "minute"))
    ) {
      setPhase2DateWarning(
        "Data analysis needs to run before the Collective Validation phase and may take up some of this short window's time. Please select a Collective Validation end date that is at least 15 minutes after the ideation end date."
      );
      setShowPhase2DateWarning(true);
    } else {
      setShowPhase2DateWarning(false);
    }
  };

  const handlePhase2EndDateChange = (newValue) => {
    setPhase2EndDate(newValue);

    // Check if the phase 2 end date is after the phase 1 end date
    if (newValue.isBefore(dayjs(phase1EndDate).add(15, "minute"))) {
      setPhase2DateWarning(
        "Data analysis needs to run before the Collective Validation phase and may take up some of this short window's time. Please select a Collective Validation end date that is at least 15 minutes after the ideation end date."
      );
      setShowPhase2DateWarning(true);
    } else {
      setShowPhase2DateWarning(false);
    }
  };

  const { isLoading: isLoadingCreate, conversation } = useSelector(
    (state) => state.conversationData
  );

  const { access, refresh, isAuthenticated, user, googleLoading } = useSelector(
    (state) => state.authData
  );

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate("/trial");
  //   }
  // }, [isAuthenticated]);

  const handleTimeChange = (newTime) => {
    setSelectedTimeLocal(newTime);
    setSelectedTimeUTC(newTime ? dayjs(newTime).utc().format() : "");
  };

  const mdUp = useResponsive("up", "md");

  const LooksIcon = ({ index }) => {
    switch (index) {
      case 0:
        return <LooksOneIcon />;
      case 1:
        return <LooksTwoIcon />;
      case 2:
        return <Looks3Icon />;
      case 3:
        return <Looks4Icon />;
      case 4:
        return <Looks5Icon />;
      default:
        return <span>{index + 1}</span>; // If you have more than 5 items, it will just display the number
    }
  };

  const addQuestion = (index) => {
    const newQuestion = {
      question: "",
      depth: 3,
      phase2: false,
      exposeAll: false,
    };
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(index + 1, 0, newQuestion);
      return updatedQuestions;
    });
    console.log(questions);
  };
  const handleQuestionChange = (index, event, part) => {
    const values = [...questions];
    values[index][part] = event.target.value;
    setQuestions(values);
  };

  const handleSwitchChange = (index, valueOrEvent, part) => {
    const values = [...questions];
    // Check if valueOrEvent is an event object or a direct boolean value
    const newValue =
      typeof valueOrEvent === "boolean"
        ? valueOrEvent
        : valueOrEvent.target.checked;

    values[index][part] = newValue;
    setQuestions(values);
  };

  const moveUp = (index) => {
    if (index === 0) return;

    const values = [...questions];
    const temp = values[index];
    values[index] = values[index - 1];
    values[index - 1] = temp;

    setQuestions(values);
  };

  const moveDown = (index) => {
    if (index === questions.length - 1) return;

    const values = [...questions];
    const temp = values[index];
    values[index] = values[index + 1];
    values[index + 1] = temp;

    setQuestions(values);
  };

  const deleteQuestion = (index) => {
    const values = [...questions];
    values.splice(index, 1);
    setQuestions(values);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const orderedQuestions = questions.map((question, index) => ({
      ...question,
      order: index + 1, // +1 because orders usually start at 1, not 0
    }));

    try {
      const conversation = {
        questions: orderedQuestions,
        conversation: {
          title: title,
          description: description,
          email: email,
          code: code,
          token: localStorage.getItem("token"),
          traits: demographics,
          end_time: selectedTimeUTC,
          share_results: shareResults,
          anonymous: !shareNames,
          num_participants: Math.round(numParticipants * 1.25),
          phase_2: conversationPhase2,
          analysis_time: userSelectedDate ? phase1EndDate : null,
          completed_time:
            userSelectedDate && conversationPhase2 ? phase2EndDate : null, // Backend will check if the conversation is phase 2
          // expose_num: exposeAll ? -1 : 15,
        },
      };
      console.log(conversation);
      const response = await dispatch(createConversation(conversation));
      navigate(
        `/links/${response.conversation.conversation_unique_id}/${response.results.conversation_result_id}`
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // You can replace the alert with your own error handling mechanism.
        setShowCodeWarning(true);
      } else {
        console.error(error);
      }
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value === "") {
      setEmailError(false);
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
      setEmailError(!emailRegex.test(event.target.value));
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCodeChange = (event) => {
    setShowCodeWarning(false);
    setCode(event.target.value);
  };

  const MAX_NUM_PARTICIPANTS = 100;

  const handleNumChange = (e) => {
    const value = e.target.value;

    // Validate the input is a number.
    if (!isNaN(value)) {
      setNumParticipants(value);

      // Check if the number is greater than MAX_NUM_PARTICIPANTS.
      if (
        parseInt(value, 10) > MAX_NUM_PARTICIPANTS ||
        parseInt(value, 10) <= 0
      ) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  };

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const totalDepth = questions.reduce((sum, question) => {
    if (question.phase2) {
      return sum + question.depth + 2;
    } else {
      return sum + question.depth;
    }
  }, 0);

  const totalTime = totalDepth;

  const handleKeyDown = (event) => {
    // Check if the Enter key was pressed
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action
      addDemographic();
    }
    if (event.key === "Backspace") {
      event.preventDefault();
      setDemographic(demographic.slice(0, -1));
      setShowDemographicWarning(false);
    }
  };

  const addDemographic = () => {
    if (demographic && demographic.length > 30) {
      setDemographicWarning("Demographic must be less than 30 characters");
      setShowDemographicWarning(true);
      return; // Exit the function early
    }

    // Check for case-insensitive match in the existing demographics
    if (
      demographic &&
      demographics.some((d) => d.toLowerCase() === demographic.toLowerCase())
    ) {
      setDemographicWarning("This demographic already exists");
      setShowDemographicWarning(true);
      return; // Exit the function early
    }

    if (demographics.length < 10) {
      const capitalizedDemographic =
        demographic.charAt(0).toUpperCase() + demographic.slice(1);
      setDemographics([capitalizedDemographic, ...demographics]);
      setDemographic("");

      setShowDemographicWarning(false);
    } else {
      setDemographicWarning("You can only add up to 10 demographics");
      setShowDemographicWarning(true);
    }
  };

  const removeDemographic = (demographicToRemove) => {
    setDemographics(
      demographics.filter((demo) => demo !== demographicToRemove)
    );
    setShowDemographicWarning(false);
  };

  const handleSelectDateChange = (event) => {
    setUserSelectedDate(event.target.checked);
  };

  return (
    <Box
      sx={{
        py: 5,
        bgcolor: (theme) =>
          theme.palette.mode === "light" ? "grey.200" : "grey.800",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2" gutterBottom>
            Create a Conversation
          </Typography>
          <Link
            href="https://scribehow.com/shared/How_to_Create_and_Share_a_Conversation__bP2xTQXZTtGlv7dp2HjfpA"
            target="_blank"
          >
            <HelpIcon fontSize="medium" sx={{ color: "black", mb: 2 }} />
          </Link>
        </Stack>

        <form onSubmit={handleSubmit} style={{ width: mdUp ? "75%" : "95%" }}>
          <Box mb={2}>
            <TextField
              label="Title"
              variant="outlined"
              multiline
              maxRows={2}
              value={title.slice(0, 254)}
              onChange={handleTitleChange}
              fullWidth
              InputProps={{
                style: {
                  fontSize: 16,
                  //backgroundColor: theme.palette.grey[100],
                },
                placeholder:
                  "Empowering Innovation: Navigating the Future of Tech",
              }}
              disabled={isLoadingCreate}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Description"
              variant="outlined"
              multiline
              rows={3}
              value={description.slice(0, 1999)}
              onChange={handleDescriptionChange}
              fullWidth
              InputProps={{
                style: {
                  fontSize: 16,
                  //backgroundColor: theme.palette.grey[100],
                },
                placeholder:
                  "Join us for an immersive discussion as we delve into...",
              }}
              disabled={isLoadingCreate}
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Estimated Number of Participants"
              variant="outlined"
              value={numParticipants}
              onChange={handleNumChange}
              fullWidth
              error={showWarning}
              helperText={
                showWarning
                  ? `Your conversation is currently restricted to ${MAX_NUM_PARTICIPANTS} or less participants`
                  : ""
              }
              InputProps={{
                style: {
                  //backgroundColor: theme.palette.grey[100],
                  fontSize: 16,
                },
                type: "number",
              }}
              disabled={isLoadingCreate}
            />
          </Box>

          <Box mb={2}>
            <Typography variant="h5" gutterBottom>
              Questions
            </Typography>
            <Typography variant="h7">
              Estimated time for particpant to complete:{" "}
              <b>{totalTime} minutes</b>
            </Typography>
          </Box>

          {questions.map((question, index) => (
            <Box
              mb={2}
              key={index}
              sx={{
                border: `1px solid ${theme.palette.grey[400]}`, // Or any other color value you'd prefer
                borderRadius: "8px", // You can adjust this to your preferred rounding
                padding: "16px", // Optional: To give space inside the box
              }}
            >
              <LooksIcon index={index} />
              <Question
                index={index}
                question={question}
                handleSwitchChange={handleSwitchChange}
                handleQuestionChange={handleQuestionChange}
                moveUp={moveUp}
                moveDown={moveDown}
                deleteQuestion={deleteQuestion}
                addQuestion={addQuestion}
                numQuestions={questions.length}
                isLoadingCreate={isLoadingCreate}
                numParticipants={numParticipants}
              />
            </Box>
          ))}
          <Accordion sx={{ mt: 3 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">Advanced Controls </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Typography>Select Conversation Dates</Typography>
                <Box display="flex" alignItems="center">
                  <Switch
                    checked={userSelectedDate}
                    onChange={handleSelectDateChange}
                    name="user select date"
                    disabled={isLoadingCreate}
                  />

                  <InfoTooltip
                    info={
                      "The collective validation process can also be manually triggered from the created conversation's control dashboard. If no date or participant count triggers input, this manual trigger will be the default."
                    }
                  />
                </Box>
              </Box>
              {!conversationPhase2 && userSelectedDate && (
                <Box mb={2} sx={{ mt: 5 }}>
                  <Stack direction="column" spacing={1}>
                    <Typography variant="h5" gutterBottom>
                      Conversation End Date
                    </Typography>
                    {/* <Typography variant="h7">
                  The collective validation process can also be manually
                  triggered from the created conversation's control dashboard.
                  If no date or participant count triggers input, this manual
                  trigger will be the default.
                </Typography> */}
                  </Stack>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Select date and time"
                      value={phase1EndDate}
                      onChange={handlePhase1EndDateChange}
                      slotProps={{ textField: { variant: "outlined" } }}
                      minDateTime={dayjs()}
                    />
                  </LocalizationProvider>
                </Box>
              )}

              {conversationPhase2 && userSelectedDate && (
                <>
                  <Stack direction="column" spacing={1} sx={{ mb: 3 }}>
                    <Typography variant="h5" gutterBottom>
                      Ideation and Collective Validation End Date
                    </Typography>
                    {/* <Typography variant="h7">
                  The collective validation process can also be manually
                  triggered from the created conversation's control dashboard.
                  If no date or participant count triggers input, this manual
                  trigger will be the default.
                </Typography> */}
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Ideation End Date"
                        value={phase1EndDate}
                        onChange={handlePhase1EndDateChange}
                        slotProps={{ textField: { variant: "outlined" } }}
                        minDateTime={dayjs().add(5, "minute")}
                      />
                    </LocalizationProvider>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Collective Validation Start"
                        value={dayjs(phase1EndDate).add(30, "minute")}
                        disabled
                        slotProps={{ textField: { variant: "outlined" } }}
                      />
                    </LocalizationProvider> */}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Collective Validation End"
                        value={phase2EndDate}
                        onChange={handlePhase2EndDateChange}
                        slotProps={{ textField: { variant: "outlined" } }}
                        minDateTime={dayjs(phase1EndDate).add(30, "minute")}
                      />
                    </LocalizationProvider>
                  </Stack>
                </>
              )}
              <Stack direction="column" spacing={1}>
                {showPhase1DateWarning && userSelectedDate && (
                  <Typography
                    variant="caption"
                    color={theme.palette.error.main}
                  >
                    {phase1DateWarning}
                  </Typography>
                )}
                {showPhase2DateWarning &&
                  userSelectedDate &&
                  conversationPhase2 && (
                    <Typography
                      variant="caption"
                      color={theme.palette.error.main}
                    >
                      {phase2DateWarning}
                    </Typography>
                  )}
              </Stack>

              <Box mb={2} sx={{ mt: 5 }}>
                <Typography variant="h5" gutterBottom>
                  Demographic Inputs
                </Typography>
                <Typography>
                  Add demographics for participants to select before joining the
                  conversation (optional)
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} marginBottom={2}>
                <TextField
                  label="New Demographic"
                  variant="outlined"
                  value={demographic}
                  onChange={(e) => setDemographic(e.target.value)}
                  onKeyDown={handleKeyDown}
                  size="medium"
                  InputProps={{
                    style: { fontSize: 16, width: "250px" },
                    placeholder: "Marketing",
                  }}
                  disabled={isLoadingCreate}
                  error={showDemographicWarning}
                  helperText={showDemographicWarning && demographicWarning}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addDemographic}
                  disabled={isLoadingCreate}
                  sx={{ height: "50px" }}
                >
                  Add
                </Button>
              </Stack>
              <Stack
                direction={mdUp ? "row" : "column"}
                spacing={1}
                marginBottom={2}
                sx={{
                  mt: 1,
                  maxWidth: "660px",
                  flexWrap: "wrap",
                }}
              >
                {demographics.map((demo, index) => (
                  <Chip
                    variant="outlined"
                    key={index}
                    label={demo}
                    onDelete={() => removeDemographic(demo)}
                    color="default"
                  />
                ))}
              </Stack>

              <Box mb={2}>
                <Typography>Share Results Dashboard</Typography>
                <Box display="flex" alignItems="center">
                  <Switch
                    checked={shareResults}
                    onChange={() => setShareResults(!shareResults)}
                    name="shareResults"
                    disabled={isLoadingCreate}
                  />

                  <InfoTooltip
                    info={
                      "Enable 'Share Results Dashboard' to automatically provide participants with a link to the results dashboard upon conversation completion. Ideal for collaborative discussions, allowing all participants to delve into the outcomes and insights collectively."
                    }
                  />
                </Box>
              </Box>

              <Box mb={2}>
                <Typography>Show Participant Names</Typography>
                {isAuthenticated === false && (
                  <Typography
                    variant="caption"
                    color={theme.palette.error.main}
                  >
                    Sign in to an account to enable this feature.
                  </Typography>
                )}
                <Box display="flex" alignItems="center">
                  <Switch
                    checked={shareNames}
                    onChange={() => setShareNames(!shareNames)}
                    name="shareNames"
                    disabled={isLoadingCreate || isAuthenticated === false}
                  />

                  <InfoTooltip
                    info={
                      "By default, participant names are hidden to ensure anonymity in the conversation. Toggle ON to reveal participant identities in the Results Dashboard if you prefer a non-anonymous discussion."
                    }
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {showCodeWarning && (
            <Typography color="red">
              Trial no longer active. Contact team@tacit.solutions for pricing.
            </Typography>
          )}

          {!isLoadingCreate ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: 3 }}
              disabled={
                !title ||
                !description ||
                questions.some((q) => q.question === "") ||
                // !(code || user?.is_trial_active) ||
                numParticipants > MAX_NUM_PARTICIPANTS ||
                numParticipants <= 0 ||
                emailError ||
                (showPhase1DateWarning && userSelectedDate) ||
                (showPhase2DateWarning &&
                  userSelectedDate &&
                  conversationPhase2)
              }
            >
              Submit
            </Button>
          ) : (
            <CircularProgress />
          )}
        </form>
        <Button
          onClick={handleScroll}
          size="small"
          color="inherit"
          endIcon={<Iconify icon="carbon:chevron-right" />}
        >
          Learn more
        </Button>
      </Box>
    </Box>
  );
};

export default ConversationForm;
