import PropTypes from "prop-types";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { alpha } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import { useEffect } from "react";

import { paths } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { useActiveLink } from "src/routes/hooks/use-active-link";
import { RouterLink } from "src/routes/components";
import TextMaxLine from "src/components/text-max-line";
import { useResponsive } from "src/hooks/use-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavSectionVertical } from "src/components/nav-section";
// ----------------------------------------------------------------------

const navigations = [
  {
    title: "Conversations",
    path: "/account/created-conversations",
    icon: <Iconify icon="carbon:group-access" />,
  },
  {
    title: "Personal Info",
    path: "/account/profile",
    icon: <Iconify icon="carbon:user" />,
  },

  //   {
  //     title: "Orders",
  //     path: paths.eCommerce.account.orders,
  //     icon: <Iconify icon="carbon:document" />,
  //   },
  //   {
  //     title: "Payment",
  //     path: paths.eCommerce.account.payment,
  //     icon: <Iconify icon="carbon:purchase" />,
  //   },
];

// ----------------------------------------------------------------------

export default function Nav({ open, onClose }) {
  const navigate = useNavigate();
  const { access, refresh, isAuthenticated, user, googleLoading } = useSelector(
    (state) => state.authData
  );

  if (user?.email.includes("@tacit.solutions")) {
    if (navigations.length == 2) {
      navigations.push({
        title: "Payment",
        path: "/account/payment",
        icon: <Iconify icon="carbon:purchase" />,
      });
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isAuthenticated) {
        navigate("/");
      }
    }, 100); // 1000ms or 1 second delay

    if (
      window.location.href.includes("payment") &&
      !user?.email.includes("@tacit.solutions")
    ) {
      navigate("/account/profile");
    }
    // Cleanup function to clear the timeout if the component is unmounted
    return () => {
      clearTimeout(timer);
    };
  }, [isAuthenticated]);
  const mdUp = useResponsive("up", "md");

  const renderContent = (
    <Stack
      sx={{
        flexShrink: 0,
        borderRadius: 2,
        width: 1,
        ...(mdUp && {
          width: 280,
          border: (theme) =>
            `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
        }),
      }}
    >
      <Stack spacing={2} sx={{ p: 3, pb: 2 }}>
        <Stack spacing={0.5}>
          <TextMaxLine variant="subtitle1" line={1}>
            {user && user?.first_name + " " + user?.last_name}
          </TextMaxLine>
          <TextMaxLine
            variant="body2"
            line={1}
            sx={{ color: "text.secondary" }}
          >
            {user && user?.email}
          </TextMaxLine>
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Stack sx={{ my: 1, px: 2 }}>
        {navigations.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Stack sx={{ my: 1, px: 2 }}>
        <ListItemButton
          sx={{
            px: 1,
            height: 44,
            borderRadius: 1,
          }}
        >
          <ListItemIcon>
            <Iconify icon="carbon:logout" />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{
              typography: "body2",
            }}
          />
        </ListItemButton>
      </Stack>
    </Stack>
  );

  return (
    <>
      {mdUp ? (
        renderContent
      ) : (
        <Drawer
          open={open}
          onClose={onClose}
          PaperProps={{
            sx: {
              width: 280,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}

Nav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const active = useActiveLink(item.path);

  return (
    <Link
      component={RouterLink}
      key={item.title}
      href={item.path}
      color={active ? "primary" : "inherit"}
      underline="none"
    >
      <ListItemButton
        sx={{
          px: 1,
          height: 44,
          borderRadius: 1,
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            typography: "body2",
            ...(active && {
              typography: "subtitle2",
            }),
          }}
        />
      </ListItemButton>
    </Link>
  );
}

NavItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};
