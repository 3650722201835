import * as api from "../api/index.js";

import { CREATE_USER } from "./actionTypes.js";

export const createUser = (formData) => async (dispatch) => {
  try {
    const { data } = await api.createUser(formData);
    console.log(data);
    dispatch({ type: CREATE_USER, payload: data });
    return data;
  } catch (error) {
    return { error: error.response.data.detail };
  }
};
