import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { countries } from "src/assets/data";
import Iconify from "src/components/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
} from "src/components/hook-form";
import { useDispatch, useSelector } from "react-redux";
// ----------------------------------------------------------------------

const GENDER_OPTIONS = ["Male", "Female", "Other"];

// ----------------------------------------------------------------------

export default function AccountPersonalView() {
  const passwordShow = useBoolean();

  const { access, refresh, isAuthenticated, user, googleLoading } = useSelector(
    (state) => state.authData
  );

  const EcommerceAccountPersonalSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    emailAddress: Yup.string().required("Email address is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    birthday: Yup.mixed().nullable().required("Birthday is required"),
    gender: Yup.string().required("Gender is required"),
    streetAddress: Yup.string().required("Street address is required"),
    city: Yup.string().required("City is required"),
    zipCode: Yup.string().required("Zip code is required"),
  });

  const defaultValues = {
    firstName: user?.first_name,
    lastName: user?.last_name,
    emailAddress: user?.email,
    phoneNumber: "365-374-4961",
    birthday: null,
    gender: "Male",
    streetAddress: "",
    zipCode: "",
    city: "",
    country: "United States",
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(EcommerceAccountPersonalSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      console.log("DATA", data);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Personal Information
      </Typography>

      <Box
        rowGap={2.5}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{ xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
      >
        <RHFTextField name="firstName" label="First Name" disabled={true} />

        <RHFTextField name="lastName" label="Last Name" disabled={true} />

        <RHFTextField
          name="emailAddress"
          label="Email Address"
          disabled={true}
        />

        {/* <Controller
          name="birthday"
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              label="Birthday"
              slotProps={{
                textField: {
                  helperText: error?.message,
                  error: !!error?.message,
                },
              }}
              {...field}
              value={field.value}
            />
          )}
        /> */}
      </Box>

      {/* <LoadingButton
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Save Changes
      </LoadingButton> */}
    </FormProvider>
  );
}
