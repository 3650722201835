import React from "react";
import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import Button from "@mui/material/Button";

import Label from "src/components/label";
import Iconify from "src/components/iconify";
//  utils
import { fDate } from "src/utils/format-time";
import { fCurrency } from "src/utils/format-number";
import { setCategoriesTableState } from "src/actions/tableActions";
import { set } from "date-fns";
import { useTheme } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";

const ResultsQuestionCategoriesTableRow = ({
  row,
  searchTerm,
  order,
  orderBy,
  page,
  rowsPerPage,
  selectedDemographics,
  showDemographics,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { results: conversationResultId, question: questionId } = useParams();

  const handleCategoryView = () => {
    dispatch(
      setCategoriesTableState({
        order: order,
        orderBy: orderBy,
        searchTerm: searchTerm,
        page: page,
        rowsPerPage: rowsPerPage,
        demographics: selectedDemographics,
      })
    );
    navigate(
      `/results/${conversationResultId}/categories/${questionId}/category/${row.category_id}`
    );
  };

  const inputStyles = {
    pl: 1,
    [`&.${inputBaseClasses.focused}`]: {
      bgcolor: "action.selected",
    },
  };

  const highlightSearchTerm = (text, term) => {
    if (!term) return text;

    const parts = text.split(new RegExp(`(${term})`, "gi"));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === term.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: "yellow" }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </>
    );
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ px: 1, minWidth: "300px" }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.title && highlightSearchTerm(row.title, searchTerm)}
          </Typography>
        </TableCell>

        <TableCell>
          {row.rating ? (
            <Tooltip title={`${Number(row.rating).toFixed(2)} out of 5`}>
              <LinearProgress
                variant="determinate"
                value={(row.rating / 5) * 100} // Convert rating to percentage
                sx={{ width: "50px" }}
              />
            </Tooltip>
          ) : (
            <Typography variant="body2" sx={inputStyles}>
              N/A
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Label
            color={
              row.rating >= 4.5
                ? theme.palette.primary.main
                : row.rating >= 3.5
                ? theme.palette.success.main
                : row.rating >= 2.5
                ? theme.palette.warning.main
                : row.rating >= 1.75
                ? theme.palette.error.main
                : row.rating > 0
                ? theme.palette.info.dark
                : theme.palette.grey[500]
            }
          >
            {row.rating >= 4.5
              ? "Excellent"
              : row.rating >= 3.5
              ? "Good"
              : row.rating >= 2.5
              ? "Neutral"
              : row.rating >= 1.75
              ? "Fair"
              : row.rating > 0
              ? "Poor"
              : "N/A"}
          </Label>
        </TableCell>

        <TableCell>
          <Label
            color={
              row.stddev > 1
                ? theme.palette.primary.darker
                : row.stddev < 0.5
                ? theme.palette.primary.main
                : theme.palette.primary.dark
            }
          >
            {row.stddev > 1 ? "Low" : row.stddev < 0.5 ? "High" : "Med."}
          </Label>
        </TableCell>

        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.num_users}
          </Typography>
        </TableCell>

        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.num_ideas}
          </Typography>
        </TableCell>

        {/*
     <TableCell align="right" padding="none">
      <IconButton onClick={handleOpen}>
        <Iconify icon="carbon:overflow-menu-vertical" />
      </IconButton>
    </TableCell> */}
        <TableCell align="right" padding="none">
          <MenuItem onClick={handleCategoryView}>
            <Iconify icon="carbon:view" sx={{ mr: 1 }} />
            View
          </MenuItem>
        </TableCell>
      </TableRow>

      {/* <Popover
    open={Boolean(open)}
    anchorEl={open}
    onClose={handleClose}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    slotProps={{
      paper: {
        sx: { width: 160 },
      },
    }}
  >
    <MenuItem onClick={handleIdeaView}>
      <Iconify icon="carbon:view" sx={{ mr: 1 }} />
      View
    </MenuItem>
  </Popover> */}
    </>
  );
};

export default ResultsQuestionCategoriesTableRow;
