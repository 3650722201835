import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://tacit-backend-8fdf693eb148.herokuapp.com",
  headers: {
    "X-API-KEY": process.env.REACT_APP_API_KEY_TACIT,
  },
});

API.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("access");
    if (token) {
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const fetchConversations = (id) => {
  return API.get(`/api/conversation/${id}`);
};

export const createConversation = (data) => {
  return API.post(`/api/conversation-and-questions/`, data);
};

export const fetchQuestion = (id) => {
  return API.get(`/api/question/${id}`);
};

export const fetchQuestions = (conversationId) => {
  return API.get(`/api/questions/${conversationId}`);
};

export const createUser = (data) => {
  return API.post(`/api/user/`, data);
};

export const sendMessage = (data) => {
  return API.post(`/api/messages/`, data);
};

export const fetchQuestionsResults = (resultsConversationId) => {
  return API.get(
    `/api/conversation-results/${resultsConversationId}/questions/`
  );
};

export const fetchConversationResults = (resultsConversationId) => {
  return API.get(
    `/api/conversation-results/${resultsConversationId}/conversation/`
  );
};

export const summarizeQuestion = (resultsConversationId, questionId, data) => {
  return API.post(
    `/api/conversation-results/${resultsConversationId}/summarize/${questionId}/`,
    data
  );
};

export const tableQuestion = (resultsConversationId, questionId, data) => {
  return API.post(
    `/api/conversation-results/${resultsConversationId}/table/${questionId}/`,
    data
  );
};

export const askQuestion = (resultsConversationId, questionId, data) => {
  return API.post(
    `/api/conversation-results/${resultsConversationId}/ask/${questionId}/`,
    data
  );
};

export const rateQuestion = (data) => {
  return API.post(`/api/messages/rate/`, data);
};

export const rateQuestionText = (data) => {
  return API.post(`/api/messages/rate-text/`, data);
};

export const addMessagesToPinecone = (data) => {
  return API.post(`/api/messages/pinecone/`, data);
};

export const summarizeAndIdeas = (data) => {
  return API.post(`/api/messages/summarize-and-ideas/`, data);
};

export const tacitAnalysis = (conversationId) => {
  return API.post(`/api/tacit-analysis/${conversationId}/`);
};

export const fetchQuestionAnswer = (resultsConversationId, questionId) => {
  return API.get(
    `/api/conversation-results/ask-messages/${resultsConversationId}/${questionId}/`
  );
};

export const fetchResultsForUser = (conversationId) => {
  return API.get(`/api/conversation-results/user/${conversationId}/`);
};

export const createContactForm = (data) => {
  return API.post(`/api/contact-form/`, data);
};

export const fetchConversationStats = (conversationResultsId) => {
  return API.get(`/api/conversation-results/stats/${conversationResultsId}/`);
};

export const fetchAllQuestionStats = (conversationResultsId) => {
  return API.get(
    `/api/conversation-results/stats/${conversationResultsId}/questions/`
  );
};

export const createResultsFeedbackForm = (data, conversationResultsId) => {
  return API.post(`/api/feedback-form/${conversationResultsId}/`, data);
};

export const getConversationIdeaStats = (conversationResultsId) => {
  return API.get(`/api/ideas/conversation/${conversationResultsId}/`);
};

export const getQuestionIdeaStats = (conversationResultsId, questionId) => {
  return API.get(
    `/api/ideas/conversation/${conversationResultsId}/${questionId}/`
  );
};

export const getQuestionCategories = (conversationResultsId, questionId) => {
  return API.get(`/api/categories/${conversationResultsId}/${questionId}/`);
};

export const getIdeaFeedback = (ideaId) => {
  return API.get(`/api/idea/${ideaId}/`);
};

export const getUserResponseData = (questionId) => {
  return API.get(`/api/user-question-stats/${questionId}/`);
};

export const googleLogin = (code) => {
  return API.post(`/api/google-login/moderator/`, { code });
};

export const getUserFromToken = (token) => {
  return API.post(`/api/token/`, { token });
};

export const getCreatedConversations = () => {
  return API.get(`/api/account/created-conversations/`);
};

export const getCreatedConversation = (conversationId) => {
  return API.get(`/api/account/conversation/${conversationId}/`);
};

export const changeConversationStatus = (conversationId, status) => {
  return API.put(`/api/account/conversation/${conversationId}/status/`, {
    status,
  });
};

export const changeConversationRejoin = (conversationId, rejoin) => {
  return API.put(`/api/account/conversation/${conversationId}/rejoin/`, {
    rejoin,
  });
};

export const deleteAccountConversation = (conversationId) => {
  return API.delete(`/api/account/conversation/${conversationId}/delete/`);
};

// Payment

export const purchaseProduct = (formData) => {
  return API.post(`/api/payment/`, formData);
};

// Conversation Account Access

export const addAcountToConversation = (formData) => {
  return API.post(`/api/account/conversation/conversation-access/`, formData);
};

export const removeAcountFromConversation = (formData) => {
  return API.delete(
    `/api/account/conversation/conversation-access-remove/${formData.conversation_id}/${formData.email}/`
  );
};

export const removeSelfFromConversation = (conversationId) => {
  return API.delete(
    `/api/account/conversation/conversation-access-remove-self/${conversationId}/`
  );
};

export const getUserResponse = (questionId, userQuestionStatsId) => {
  return API.get(`/api/user-responses/${questionId}/${userQuestionStatsId}/`);
};

export const removeUserTask = (conversationEventId) => {
  return API.delete(`/api/account/conversation-task/${conversationEventId}/`);
};

export const getCategory = (categoryId) => {
  return API.get(`/api/category/${categoryId}/`);
};

export const createTraitsSummary = (questionId, data) => {
  return API.post(`/api/traits-summary/${questionId}/`, data);
};

export const createTraitsTopAnswer = (questionId, data) => {
  return API.post(`/api/traits-top-answer/${questionId}/`, data);
};
