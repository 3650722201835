import HomeView from "src/sections/_home/view/home-view";

// ----------------------------------------------------------------------

export default function HomePage() {
  return (
    <>
      <HomeView />
    </>
  );
}
