import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export default function ResultsIdeaAbout() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          p: 3,
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Stack>
          <Button
            onClick={handleBack}
            sx={{
              width: "40px", // or any specific width you want
              height: "40px", // or any specific height you want
            }}
          >
            <ArrowBackIcon />
          </Button>
        </Stack>
        <Typography variant="h2">Idea Deep Dive</Typography>
        <Typography sx={{ color: "text.secondary" }}>
          Take a look at the specific feedback and ratings for the idea
        </Typography>
      </Stack>
    </Stack>
  );
}
