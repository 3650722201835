import { useState, useCallback, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TablePagination from "@mui/material/TablePagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

import {
  stableSort,
  getComparator,
} from "src/sections/_results/question/utils";
import ResultsUserResponsesTableRow from "./results-user-responses-table-row";
import ResultsUserResponsesTableHead from "./results-user-responses-table-head";
import { getUserResponseData } from "src/actions/resultsUserResponsesActions";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

export default function ResultsUserResponseTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tableState = useSelector((state) => state.userResponseTableData);
  const { results: conversationResultsId, question: questionId } = useParams();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("category");

  const { isLoading: isLoadingUserResponses, userResponseStats } = useSelector(
    (state) => state.userResponseData
  );

  const { isLoading: isLoadingQuestion, questions } = useSelector(
    (state) => state.questionData
  );

  const [order, setOrder] = useState(tableState.order);

  const [orderBy, setOrderBy] = useState(tableState.orderBy);

  const [searchTerm, setSearchTerm] = useState(tableState.searchTerm);

  const [page, setPage] = useState(tableState.page);

  const [dense, setDense] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(tableState.rowsPerPage);

  const [filteredResponses, setFilteredResponses] = useState(
    userResponseStats[questionId]?.stats.users || []
  );

  const [selectedCategories, setSelectedCategories] = useState(
    categoryId ? [categoryId] : tableState.categories
  );

  useEffect(() => {
    searchParams.delete("category");
    navigate(
      {
        pathname: `/results/${conversationResultsId}/user-responses/${questionId}`,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }, [selectedCategories]);

  useEffect(() => {
    // Dispatch action to fetch user response data if not already loaded
    if (!userResponseStats[questionId] && !isLoadingUserResponses) {
      dispatch(getUserResponseData(questionId));
    }

    // Function to check if the user's response matches the search term and selected categories
    const filterResponses = (response) => {
      const matchesSearchTerm = response.summary
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      const hasMatchingCategories =
        selectedCategories.length === 0 ||
        (response.categories.length > 0 &&
          selectedCategories.every((selectedCategory) =>
            response.categories.some(
              (category) => category.category_id === selectedCategory
            )
          ));

      return matchesSearchTerm && hasMatchingCategories;
    };

    if (userResponseStats[questionId]?.stats.users) {
      setFilteredResponses(
        userResponseStats[questionId].stats.users.filter(filterResponses)
      );
    }
  }, [
    questionId,
    searchTerm,
    selectedCategories,
    userResponseStats,
    isLoadingUserResponses,
    dispatch,
  ]);

  const handleSort = useCallback(
    (id) => {
      const isAsc = orderBy === id && order === "asc";
      if (id !== "") {
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(id);
      }
    },
    [order, orderBy]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            userResponseStats[questionId]?.stats.users.length
        )
      : 0;

  const handleCategoryChange = (event) => {
    setSelectedCategories(event.target.value);
  };

  const TABLE_HEAD = [
    { id: "local_id", label: "Id", width: 100 },
    { id: "summary", label: "Summary", width: 500 },
    ...(userResponseStats[questionId]?.stats.categories.length > 0
      ? [{ id: "category", label: "Category", width: 100 }]
      : []),
    { id: "updated_at", label: "Finish Time", width: 160 },
    { id: "num_seconds", label: "Time Spent", width: 100 },
    { id: "", width: 20 },
  ];

  if (isLoadingUserResponses) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {userResponseStats[questionId]?.stats.users && (
        <Stack
          sx={{
            paddingLeft: { xs: 0, md: 8 },
            paddingRight: { xs: 0, md: 8 },
          }}
        >
          <Typography variant="h5">User Response Data</Typography>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ mt: 5, mb: 3 }}
          >
            {userResponseStats[questionId]?.stats.categories.length > 0 && (
              <>
                <FormControl sx={{ width: 300 }}>
                  {selectedCategories.length === 0 && (
                    <InputLabel
                      htmlFor="trait-select-label"
                      style={{ color: "rgba(0, 0, 0, 0.87)" }}
                    >
                      All Categories
                    </InputLabel>
                  )}
                  <Select
                    labelId="trait-select-label"
                    multiple
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        // When no traits are selected, show "All Up"
                        return;
                      } else {
                        // When traits are selected, show the chips
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                              mt: -1.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={
                                  userResponseStats[
                                    questionId
                                  ]?.stats?.categories.find(
                                    (category) => category.category_id === value
                                  ).title
                                }
                              />
                            ))}
                          </Box>
                        );
                      }
                    }}
                  >
                    {userResponseStats[questionId]?.stats.categories.length >
                      0 &&
                      userResponseStats[questionId]?.stats.categories.map(
                        (category) => (
                          <MenuItem
                            key={category.category_id}
                            value={category.category_id}
                          >
                            {category.title}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </>
            )}
            <TextField
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setPage(0);
              }}
              fullWidth
              hiddenLabel
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="carbon:search"
                      width={24}
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {/* <Stack
              spacing={2}
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
            >
              <DatePicker label="Start Date" sx={{ width: 1, minWidth: 180 }} />
              <DatePicker label="End Date" sx={{ width: 1, minWidth: 180 }} />
            </Stack> */}
          </Stack>

          <TableContainer
            sx={{
              overflow: "unset",

              [`& .${tableCellClasses.head}`]: {
                color: "text.primary",
              },
              [`& .${tableCellClasses.root}`]: {
                bgcolor: "background.default",
                borderBottomColor: (theme) => theme.palette.divider,
              },
            }}
          >
            <Scrollbar>
              <Table
                sx={{
                  minWidth: 720,
                }}
                size={dense ? "small" : "medium"}
              >
                <ResultsUserResponsesTableHead
                  order={order}
                  orderBy={orderBy}
                  onSort={handleSort}
                  headCells={TABLE_HEAD}
                  rowCount={userResponseStats[questionId]?.stats.users.length}
                />

                <TableBody>
                  {stableSort(filteredResponses, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <ResultsUserResponsesTableRow
                        key={row.user_question_stats_id}
                        row={row}
                        searchTerm={searchTerm}
                        order={order}
                        orderBy={orderBy}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        selectedCategories={selectedCategories}
                        showCategory={
                          userResponseStats[questionId]?.stats.categories
                            .length > 0
                        }
                      />
                    ))}

                  {emptyRows > 0 && (
                    <TableRow
                      sx={{
                        height: (dense ? 36 : 57) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              page={page}
              component="div"
              count={filteredResponses.length}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[2, 5, 10, 25]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Stack>
      )}
    </>
  );
}
