import * as api from "../api/index.js";

import {
  FETCH_USER_RESPONSE_DATA,
  START_LOADING_USER_RESPONSE_DATA,
  END_LOADING_USER_RESPONSE_DATA,
  GET_USER_RESPONSE,
  START_LOADING_USER_RESPONSE,
  END_LOADING_USER_RESPONSE,
} from "./actionTypes.js";

export const getUserResponseData = (questionId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_USER_RESPONSE_DATA });
    const { data } = await api.getUserResponseData(questionId);
    console.log(data);
    dispatch({
      type: FETCH_USER_RESPONSE_DATA,
      payload: { questionId, stats: data },
    });
    dispatch({ type: END_LOADING_USER_RESPONSE_DATA });
  } catch (error) {
    dispatch({ type: END_LOADING_USER_RESPONSE_DATA });
    console.log(error.message);
  }
};

export const getUserResponse =
  (questionId, userQuestionStatsId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_USER_RESPONSE });
      const { data } = await api.getUserResponse(
        questionId,
        userQuestionStatsId
      );
      console.log(data);
      dispatch({ type: GET_USER_RESPONSE, payload: data });
      dispatch({ type: END_LOADING_USER_RESPONSE });
      return data;
    } catch (error) {
      dispatch({ type: END_LOADING_USER_RESPONSE });
      console.log(error.message);
    }
  };
