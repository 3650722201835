import React from "react";
import { Box, Typography, Container, Stack } from "@mui/material";
import ChatBox from "../chatbox/chatbox";
import Phase2ChatBox from "../chatbox/phase2-chatbox";

const Question = ({
  question,
  questionId,
  order,
  setNextQuestion,
  currentQuestionIndex,
  numQuestions,
  results,
  phase2,
  username,
  analysisComplete,
  categories,
  rejoinPhase1,
  conversationPhase2,
}) => {
  return (
    <Stack>
      {/* <Typography variant="h5">
        Question {order}: {question}
      [</Typography> */}

      {analysisComplete && phase2 && !rejoinPhase1 ? (
        <Phase2ChatBox
          questionId={questionId}
          question={question}
          setNextQuestion={setNextQuestion}
          currentQuestionIndex={currentQuestionIndex}
          numQuestions={numQuestions}
          results={results}
          phase2={phase2}
          order={order}
          username={username}
          categories={categories}
        />
      ) : (
        <ChatBox
          questionId={questionId}
          question={question}
          setNextQuestion={setNextQuestion}
          currentQuestionIndex={currentQuestionIndex}
          numQuestions={numQuestions}
          results={results}
          phase2={phase2}
          order={order}
          username={username}
        />
      )}
    </Stack>
  );
};

export default Question;
