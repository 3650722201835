import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import Button from "@mui/material/Button";

import Label from "src/components/label";
import Iconify from "src/components/iconify";
//  utils
import { fDate } from "src/utils/format-time";
import { fCurrency } from "src/utils/format-number";
import { setUserResponsesTableState } from "src/actions/tableActions";
import { set } from "date-fns";
// ----------------------------------------------------------------------

export default function ResultsUserResponsesTableRow({
  row,
  searchTerm,
  order,
  orderBy,
  page,
  rowsPerPage,
  selectedCategories,
  showCategory,
}) {
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { results: conversationResultId, question: questionId } = useParams();

  const inputStyles = {
    pl: 1,
    [`&.${inputBaseClasses.focused}`]: {
      bgcolor: "action.selected",
    },
  };

  const handleIdeaView = () => {
    dispatch(
      setUserResponsesTableState({
        order: order,
        orderBy: orderBy,
        searchTerm: searchTerm,
        page: page,
        rowsPerPage: rowsPerPage,
        categories: selectedCategories,
      })
    );
    navigate(
      `/results/${conversationResultId}/user-responses/${questionId}/user/${row.user_question_stats_id}`
    );
  };

  let date = new Date(row?.updated_at);
  let hours = date.getHours();
  let minutes = date.getMinutes().toString().padStart(2, "0");

  // Determine AM or PM suffix
  let ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour format to 12-hour format
  hours = hours % 12;
  // Convert 0 hours (midnight) to 12
  hours = hours ? hours : 12;

  let formattedTime = hours + ":" + minutes + " " + ampm;
  let localDate = date.toLocaleDateString() + " " + formattedTime;

  const highlightSearchTerm = (text, term) => {
    if (!term) return text;

    const parts = text.split(new RegExp(`(${term})`, "gi"));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === term.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: "yellow" }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </>
    );
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.user_name ? row.user_name : row.local_id}
          </Typography>
        </TableCell>

        <TableCell sx={{ px: 1, minWidth: "200px" }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.summary == "" && row.num_words > 1
              ? "Did Not Submit"
              : row.summary == ""
              ? "No Response"
              : highlightSearchTerm(row.summary, searchTerm)}
          </Typography>
        </TableCell>

        {showCategory && (
          <TableCell sx={{ px: 1, minWidth: "200px" }}>
            {row.categories.map((category) => (
              <Typography
                variant="body2"
                sx={inputStyles}
                key={category.category_id}
              >
                {category.title}
              </Typography>
            ))}
          </TableCell>
        )}

        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {localDate}
          </Typography>
        </TableCell>

        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.num_seconds > 59
              ? (row.num_seconds / 60).toFixed(1) + " min"
              : row.num_seconds + " sec"}
          </Typography>
        </TableCell>
        <TableCell align="right" padding="none">
          <MenuItem onClick={handleIdeaView}>
            <Iconify icon="carbon:view" sx={{ mr: 1 }} />
            View
          </MenuItem>
        </TableCell>
      </TableRow>
    </>
  );
}

ResultsUserResponsesTableRow.propTypes = {
  row: PropTypes.object,
};
