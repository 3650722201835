import React from "react";

import Router from "./routes/sections";
import "src/global.css";
import ThemeProvider from "src/theme";
import { SettingsDrawer, SettingsProvider } from "src/components/settings";
import { Analytics } from "@vercel/analytics/react";

const App = () => {
  return (
    <>
      <SettingsProvider
        defaultSettings={{
          themeMode: "light", // 'light' | 'dark'
          themeDirection: "ltr", //  'rtl' | 'ltr'
          themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
        }}
      >
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </SettingsProvider>
      <Analytics />
    </>
  );
};

export default App;
