import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Label from "src/components/label";
import Iconify from "src/components/iconify";
//  utils
import { fDate } from "src/utils/format-time";
import { fCurrency } from "src/utils/format-number";
import { setIdeaTableState } from "src/actions/tableActions";
import { TheaterComedy } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
// ----------------------------------------------------------------------

export default function ResultsQuestionIdeasTableRow({
  row,
  searchTerm,
  tab,
  order,
  orderBy,
  page,
  rowsPerPage,
  selectedCategories,
  showCategory,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { results: conversationResultId, question: questionId } = useParams();

  const handleOpen = useCallback((event) => {
    setOpen(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(null);
  }, []);

  let date = new Date(row.created_at);
  let localDate = date.toLocaleString();

  const inputStyles = {
    pl: 1,
    [`&.${inputBaseClasses.focused}`]: {
      bgcolor: "action.selected",
    },
  };

  const handleIdeaView = () => {
    dispatch(
      setIdeaTableState({
        tab: tab,
        order: order,
        orderBy: orderBy,
        searchTerm: searchTerm,
        page: page,
        rowsPerPage: rowsPerPage,
        categories: selectedCategories,
      })
    );
    navigate(
      `/results/${conversationResultId}/ideas/${questionId}/${row.idea_id}`
    );
  };

  const highlightSearchTerm = (text, term) => {
    if (!term) return text;

    const parts = text.split(new RegExp(`(${term})`, "gi"));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === term.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: "yellow" }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </>
    );
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ px: 1, minWidth: "300px" }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.idea && highlightSearchTerm(row.idea, searchTerm)}
          </Typography>
        </TableCell>
        {showCategory ? (
          <TableCell sx={{ px: 1, minWidth: "150px" }}>
            <Typography variant="body2" sx={inputStyles}>
              {row.category_title}
            </Typography>
          </TableCell>
        ) : null}

        <TableCell>
          {row.rating ? (
            <Tooltip title={`${Number(row.rating).toFixed(2)} out of 5`}>
              <LinearProgress
                variant="determinate"
                value={(row.rating / 5) * 100} // Convert rating to percentage
                sx={{ width: "50px" }}
              />
            </Tooltip>
          ) : (
            <Typography variant="body2" sx={inputStyles}>
              N/A
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Label
            color={
              row.rating >= 4.5
                ? theme.palette.primary.main
                : row.rating >= 3.5
                ? theme.palette.success.main
                : row.rating >= 2.5
                ? theme.palette.warning.main
                : row.rating >= 1.75
                ? theme.palette.error.main
                : row.rating > 0
                ? theme.palette.info.dark
                : theme.palette.grey[500]
            }
          >
            {row.rating >= 4.5
              ? "Excellent"
              : row.rating >= 3.5
              ? "Good"
              : row.rating >= 2.5
              ? "Neutral"
              : row.rating >= 1.75
              ? "Fair"
              : row.rating > 0
              ? "Poor"
              : "N/A"}
          </Label>
        </TableCell>

        {/* <TableCell>
          <Label
            color={
              (row.related > 0.7 && "success") ||
              (row.related < 0.3 && "error") ||
              "warning"
            }
          >
            {row.related > 0.7 ? "Yes" : row.related < 0.3 ? "No" : "Maybe"}
          </Label>
        </TableCell> */}

        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.proposal_count}
          </Typography>
        </TableCell>

        {/* <TableCell>{localDate}</TableCell> */}

        {/* <TableCell>
          <Label
            color={
              row.stddev > 0.7
                ? theme.palette.primary.darker
                : row.stddev < 0.3
                ? theme.palette.primary.main
                : theme.palette.primary.dark
            }
          >
            {row.stddev > 0.6 ? "High" : row.stddev < 0.3 ? "Low" : "Med."}
          </Label>
        </TableCell> */}

        {/* <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.expose_count}
          </Typography>
        </TableCell> */}

        {/*
         <TableCell align="right" padding="none">
          <IconButton onClick={handleOpen}>
            <Iconify icon="carbon:overflow-menu-vertical" />
          </IconButton>
        </TableCell> */}
        <TableCell align="right" padding="none">
          <MenuItem onClick={handleIdeaView}>
            <Iconify icon="carbon:view" sx={{ mr: 1 }} />
            View
          </MenuItem>
        </TableCell>
      </TableRow>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        slotProps={{
          paper: {
            sx: { width: 160 },
          },
        }}
      >
        <MenuItem onClick={handleIdeaView}>
          <Iconify icon="carbon:view" sx={{ mr: 1 }} />
          View
        </MenuItem>
      </Popover> */}
    </>
  );
}

ResultsQuestionIdeasTableRow.propTypes = {
  row: PropTypes.object,
};
