import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Stack, Container, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";

import Iconify from "src/components/iconify";
import CountUp from "src/components/count-up";
import { useResponsive } from "src/hooks/use-responsive";
import { fShortenNumber } from "src/utils/format-number";
import { getConversationStats } from "src/actions/resultActions";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const StyledIcon = styled("div", {
  shouldForwardProp: (prop) => prop !== "color",
})(({ color, theme }) => ({
  width: 160,
  height: 160,
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  position: "relative",
  justifyContent: "center",
  color: theme.palette[color].darker,
  border: `dashed 2px ${alpha(theme.palette[color].main, 0.24)}`,
  "&:before": {
    zIndex: 8,
    content: '""',
    borderRadius: "50%",
    position: "absolute",
    width: "calc(100% - 48px)",
    height: "calc(100% - 48px)",
    background: `conic-gradient(from 0deg at 50% 50%, ${theme.palette[color].main} 0deg, ${theme.palette[color].light} 360deg)`,
  },
  "& svg": {
    zIndex: 9,
  },
}));

// ----------------------------------------------------------------------

export default function ResultsHomeStats() {
  const dispatch = useDispatch();
  const mdUp = useResponsive("up", "md");
  const { results: resultQuestionsId } = useParams();

  const { isLoading: isLoadingStats, stats } = useSelector(
    (state) => state.conversationStatsData
  );
  const { isLoading: isLoadingConversation, conversation } = useSelector(
    (state) => state.conversationData
  );

  const { isLoading: isLoadingQuestion, questions } = useSelector(
    (state) => state.questionData
  );

  const questionHasPhase2 = questions?.some(
    (question) => question.phase_2 === true
  );

  let COLORS;
  // if (questionHasPhase2) {
  COLORS = ["primary", "secondary", "warning", "success"];
  // } else {
  //   COLORS = ["primary", "secondary", "warning", "greyBasic"];
  // }

  useEffect(() => {
    if (!stats && !isLoadingStats) {
      dispatch(getConversationStats(resultQuestionsId));
    }
  }, []);

  const SUMMARY = [
    {
      title: "Conversation Status",
      total:
        conversation?.status.charAt(0).toUpperCase() +
        conversation?.status.slice(1),
      icon: "carbon:checkmark-outline",
    },
    {
      title: "Total Participants",
      total: stats?.user_count ? stats.user_count : "0",
      icon: "carbon:user-certification",
    },

    {
      title: "Collaboration Minutes",
      total: stats?.num_seconds > 0 ? Math.max(1, stats.num_seconds / 60) : "0",
      icon: "carbon:time",
    },
    {
      title: "Total Ideas Generated",

      total: stats?.idea_count ? stats.idea_count : "0",
      icon: "carbon:data-vis-4",
    },
  ];
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: 5,
        borderRadius: 2,
      }}
    >
      <Container
        sx={{
          textAlign: "center",
          display: "grid",
          gap: { xs: 5, md: 8 },
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          },
        }}
      >
        {SUMMARY.map((value, index) => (
          <div key={value.title}>
            <StyledIcon color={COLORS[index]}>
              <Iconify icon={value.icon} width={48} />
            </StyledIcon>

            <Typography variant="h2" sx={{ mt: 2, mb: 1 }}>
              {typeof value.total === "string" ? (
                value.total
              ) : (
                <CountUp
                  start={value.total / 5}
                  end={value.total}
                  formattingFn={(newValue) => fShortenNumber(newValue)}
                />
              )}
            </Typography>

            <Typography sx={{ color: "text.secondary" }}>
              {value.title}
            </Typography>
          </div>
        ))}
      </Container>
    </Stack>
  );
}
