import ResultsUserResponsesAbout from "../user-responses/results-user-responses-about";

import ResultsUserResponseTable from "../user-responses/results-user-responses-table";
export default function ResultsUserResponsesView() {
  return (
    <>
      <ResultsUserResponsesAbout />
      <ResultsUserResponseTable />
    </>
  );
}
