import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material";

export default function ResultsHomeConversation() {
  const dispatch = useDispatch();
  const { results: getResultQuestionsId } = useParams();

  const { isLoading: isLoadingQuestion, questions } = useSelector(
    (state) => state.questionData
  );
  const { isLoading: isLoadingConversation, conversation } = useSelector(
    (state) => state.conversationData
  );

  if (isLoadingQuestion || isLoadingConversation) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          py: 5,

          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography variant="h2">{conversation?.title}</Typography>

        <Typography sx={{ color: "text.secondary" }}>
          {conversation?.description}
        </Typography>
      </Stack>
    </Stack>
  );
}
