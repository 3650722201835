// ----------------------------------------------------------------------

export function descendingComparator(a, b, orderBy, activeTab) {
  if (activeTab === "Top Unique Ideas") {
    if (b.rating - b.proposal_count < a.rating - a.proposal_count) {
      return 1;
    }
    if (b.rating - b.proposal_count > a.rating - a.proposal_count) {
      return -1;
    }
    return 0;
  }

  if (activeTab === "Top Common Ideas") {
    if (b.rating + b.proposal_count < a.rating + a.proposal_count) {
      return 1;
    }
    if (b.rating + b.proposal_count > a.rating + a.proposal_count) {
      return -1;
    }
    return 0;
  }

  if (activeTab === "Controversial Ideas") {
    if (b.stddev < a.stddev) {
      return 1;
    }
    if (b.stddev > a.stddev) {
      return -1;
    }
    return 0;
  }

  if (activeTab === "Most Text Feedback") {
    if (b.text_feedback_num < a.text_feedback_num) {
      return 1;
    }
    if (b.text_feedback_num > a.text_feedback_num) {
      return -1;
    }
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
// ----------------------------------------------------------------------

export function getComparator(order, orderBy, activeTab = "All Ideas") {
  if (order === "asc") {
    return (a, b) => -descendingComparator(a, b, orderBy, activeTab);
  }
  return (a, b) => descendingComparator(a, b, orderBy, activeTab);
}

// ----------------------------------------------------------------------

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------

export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
