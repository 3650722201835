import AccountConversationView from "src/sections/_account/view/account-conversation-view";
// ----------------------------------------------------------------------

export default function AccountConversationPage() {
  return (
    <>
      <AccountConversationView />
    </>
  );
}
