import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ApexChart from "react-apexcharts";
import { styled } from "@mui/material/styles";
import { useResponsive, useWidth } from "src/hooks/use-responsive";

export const Chart = styled(ApexChart)``;

const useChartOptions = (labels) => {
  const theme = useTheme();
  const mdUp = useResponsive("up", "md");

  return {
    chart: {
      background: "transparent",
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.success.main,
      theme.palette.warning.main,
      theme.palette.error.main,
      theme.palette.info.dark,
    ],
    dataLabels: {
      enabled: false,
    },
    labels,
    legend: {
      show: mdUp,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      fillSeriesColor: false,
    },
  };
};

export const PieDiversity = (props) => {
  const { chartSeries, labels, sx, title } = props;
  const chartOptions = useChartOptions(labels);

  const total = chartSeries.reduce((sum, value) => sum + value, 0);
  const normalizedChartSeries = chartSeries.map((value) =>
    parseFloat(((value / total) * 100).toFixed(1))
  );
  const containerStyle = {
    height: "308px", // Set this to the desired fixed height
    display: "flex",
    alignItems: "center", // This centers the Typography text vertically
    justifyContent: "center", // This centers the Typography text horizontally
  };

  const widthKey = useWidth();
  const mdUp = useResponsive("up", "md");

  // Define the height based on the width key
  let height;
  switch (widthKey) {
    case "xs":
      height = 160;
      break;
    case "sm":
      height = 250;
      break;
    default:
      height = 300;
  }

  return (
    <Stack
      spacing={3}
      sx={{ p: 1, borderRadius: 2, bgcolor: "background.neutral" }}
    >
      <Typography variant="h6" sx={{ p: 3 }}>
        {title}
      </Typography>
      {total ? (
        <Chart
          height={height}
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width="100%"
        />
      ) : null}
    </Stack>
  );
};

PieDiversity.propTypes = {
  chartSeries: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  sx: PropTypes.object,
  title: PropTypes.string.isRequired,
};
