import ResultsDataAbout from "../data/results-data-about";
import ResultsDataBasic from "../data/results-data-basic";

export default function ResultsDataView() {
  return (
    <>
      <ResultsDataAbout />
      <ResultsDataBasic />
    </>
  );
}
