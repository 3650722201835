import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Typography } from "@mui/material";

import { visuallyHidden } from "./utils";
import InfoTooltip from "src/components/dashboard/info-tooltip";
// ----------------------------------------------------------------------

export default function ResultsQuestionIdeasTableHead({
  order,
  onSort,
  orderBy,
  headCells,
  activeTab,
}) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? "right" : "left"}
            padding={headCell.disablePadding ? "normal" : "normal"}
            sortDirection={orderBy === headCell.sortKey ? order : false}
            sx={{ width: headCell.width, minWidth: headCell.minWidth }}
          >
            {activeTab == "All Ideas" ? (
              <TableSortLabel
                active={orderBy === headCell.sortKey}
                direction={orderBy === headCell.sortKey ? order : "asc"}
                onClick={() => onSort(headCell.sortKey)}
              >
                {headCell.label}
                {orderBy === headCell.sortKey ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel disabled>{headCell.label}</TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ResultsQuestionIdeasTableHead.propTypes = {
  headCells: PropTypes.array,
  numSelected: PropTypes.number,
  onSelectAllRows: PropTypes.func,
  onSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
