import * as api from "../api/index.js";

import {
  FETCH_QUESTION_CATEGORIES,
  START_LOADING_QUESTION_CATEGORIES,
  END_LOADING_QUESTION_CATEGORIES,
  CREATE_TRAITS_TOP_ANSWER,
  START_LOADING_TRAITS_TOP_ANSWER,
  END_LOADING_TRAITS_TOP_ANSWER,
} from "./actionTypes.js";

export const getQuestionCategories =
  (conversationResultsId, questionId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_QUESTION_CATEGORIES });
      const { data } = await api.getQuestionCategories(
        conversationResultsId,
        questionId
      );
      console.log(data);
      dispatch({
        type: FETCH_QUESTION_CATEGORIES,
        payload: { questionId, data: data },
      });
      dispatch({ type: END_LOADING_QUESTION_CATEGORIES });
    } catch (error) {
      dispatch({ type: END_LOADING_QUESTION_CATEGORIES });
      console.log(error.message);
    }
  };

export const createTraitsTopAnswer =
  (questionId, traitsAndCat) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_TRAITS_TOP_ANSWER });
      const { data } = await api.createTraitsTopAnswer(
        questionId,
        traitsAndCat
      );
      console.log(data);
      dispatch({
        type: CREATE_TRAITS_TOP_ANSWER,
        payload: data,
      });
      dispatch({ type: END_LOADING_TRAITS_TOP_ANSWER });
    } catch (error) {
      dispatch({ type: END_LOADING_TRAITS_TOP_ANSWER });
      console.log(error.message);
    }
  };
