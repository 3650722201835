import ResultsQuestionCategory from "../question/categories/results-question-category";
import { useEffect } from "react";
export default function ResultsUserResponsesMessagesView() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ResultsQuestionCategory />
    </>
  );
}
