import ResultsUserResponsesMessagesView from "src/sections/_results/view/results-user-responses-messages-view";
const ResultsUserResponsesMessagesPage = () => {
  return (
    <>
      <ResultsUserResponsesMessagesView />
    </>
  );
};

export default ResultsUserResponsesMessagesPage;
