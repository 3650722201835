import CreateConversationView from "src/sections/_create-conversation/view/create-conversation-view";

// ----------------------------------------------------------------------

export default function CreateConversationPage() {
  return (
    <>
      <CreateConversationView />
    </>
  );
}
