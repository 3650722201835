import ResultsIdeasView from "src/sections/_results/view/results-ideas-view";

const ResultsIdeasPage = () => {
  return (
    <>
      <ResultsIdeasView />
    </>
  );
};

export default ResultsIdeasPage;
