import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import Image from "src/components/image";
import { useResponsive } from "src/hooks/use-responsive";
import FormProvider, { RHFTextField } from "src/components/hook-form";

import { createContactForm } from "src/actions/contactFormActions";
import { createResultsFeedbackForm } from "src/actions/resultsFeedbackFormActions";

// ----------------------------------------------------------------------useEffe

export default function ResultsFeedbackForm() {
  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const { results: resultQuestionsId } = useParams();

  const FeedbackFormSchema = Yup.object().shape({
    feedback: Yup.string().required("Feedback is required"),
    slider: Yup.number().required("Slider value is required").min(1).max(10),
  });

  const defaultValues = {
    feedback: "",
    slider: 3,
  };

  const methods = useForm({
    resolver: yupResolver(FeedbackFormSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log("DATA", data);
      dispatch(createResultsFeedbackForm(data, resultQuestionsId));
      reset();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Stack
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Grid xs={12} md={6} lg={6}>
        <Stack
          spacing={2}
          sx={{
            mb: 2,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h3">Feedback Form</Typography>
        </Stack>

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack spacing={2.5} alignItems="flex-start">
            <RHFTextField
              name="feedback"
              multiline
              rows={4}
              label="Feedback"
              sx={{ pb: 2.5 }}
              inputProps={{
                maxLength: 1000,
              }}
            />
            <Typography variant="body" sx={{ color: "text.secondary" }}>
              On a scale of 1-5 how useful are the results? 1 = Not Useful, 5 =
              Very Useful
            </Typography>
            <Controller
              name="slider"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <Slider
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={5}
                  value={value}
                  onChange={(_, value) => onChange(value)}
                />
              )}
            />

            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              color="inherit"
              loading={isSubmitting}
              sx={{
                alignSelf: { xs: "center", md: "unset" },
              }}
            >
              Send Feedback
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Grid>
    </Stack>
  );
}
