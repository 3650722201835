import PropTypes from "prop-types";
import { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { bgGradient } from "src/theme/css";

import { useLocation } from "react-router-dom";
import Header from "../common/header-simple";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthenticated, load_user } from "src/actions/authActions";

// ----------------------------------------------------------------------

export default function AuthBackgroundLayout({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (localStorage.getItem("access")) {
      dispatch(checkAuthenticated());
      dispatch(load_user());
    }
  }, []);

  const { access, refresh, isAuthenticated, user, googleLoading } = useSelector(
    (state) => state.authData
  );

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/account/created-conversations");
    }
  }, [isAuthenticated]);

  return (
    <>
      <Header />

      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.background.default, 0.9),
            imgUrl: "/assets/background/overlay_1.jpg",
          }),
          px: 2,
          py: 12,
          minHeight: "100vh",
        }}
      >
        <Stack
          spacing={4}
          sx={{
            p: 4,
            width: 1,
            mx: "auto",
            flexShrink: 0,
            maxWidth: 400,
            borderRadius: 2,
            bgcolor: "background.default",
            boxShadow: theme.customShadows.z24,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {children}
        </Stack>
      </Stack>
    </>
  );
}

AuthBackgroundLayout.propTypes = {
  children: PropTypes.node,
};
