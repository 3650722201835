import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TablePagination from "@mui/material/TablePagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import { RouterLink } from "src/routes/components";
import { Button } from "@mui/material";
import {
  stableSort,
  getComparator,
} from "src/sections/_results/question/utils";
import AccountConversationsTableHead from "./account-conversations-table-head";
import AccountConversationsTableRow from "./account-conversations-table-row";

import { getCreatedConversations } from "src/actions/accountActions";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";

// ----------------------------------------------------------------------

export const TABLE_HEAD = [
  { id: "title", label: "Title", width: 300 },
  // { id: "description", label: "Description", width: 300 },
  { id: "created_at", label: "Created At", width: 150 },
  { id: "user_count", label: "Num Participants", width: 100 },
  { id: "status", label: "Status", width: 100 },
  { id: "", width: 20 },
];

export default function AccountConversationTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tableState = useSelector((state) => state.userResponseTableData);

  const {
    isLoadingCreatedConversations,
    isLoadingConversation,
    createdConversations,
    createdConversation,
  } = useSelector((state) => state.accountData);

  const { access, refresh, isAuthenticated, user, googleLoading } = useSelector(
    (state) => state.authData
  );

  const [order, setOrder] = useState(tableState.order);

  const [orderBy, setOrderBy] = useState(tableState.orderBy);

  const [searchTerm, setSearchTerm] = useState(tableState.searchTerm);

  const [page, setPage] = useState(tableState.page);

  const [dense, setDense] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(tableState.rowsPerPage);

  const [filteredResponses, setFilteredResponses] = useState(
    createdConversations || []
  );
  const hasFetched = useRef(false);

  useEffect(() => {
    if (
      !hasFetched.current &&
      createdConversations.length == 0 &&
      !isLoadingCreatedConversations &&
      isAuthenticated
    ) {
      hasFetched.current = true;
      dispatch(getCreatedConversations());
    }

    if (createdConversations) {
      setFilteredResponses(
        createdConversations.filter((response) =>
          response.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [
    searchTerm,
    createdConversations,
    isLoadingCreatedConversations,
    isAuthenticated,
  ]);
  const handleSort = useCallback(
    (id) => {
      const isAsc = orderBy === id && order === "asc";
      if (id !== "") {
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(id);
      }
    },
    [order, orderBy]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - createdConversations.length)
      : 0;

  let localDate = "N/A"; // Default value if trial_end_time doesn't exist

  if (user && user?.trial_end_time) {
    let date = new Date(user?.trial_end_time);

    let hours = date.getHours();
    let minutes = date.getMinutes().toString().padStart(2, "0");

    // Determine AM or PM suffix
    let ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12;
    // Convert 0 hours (midnight) to 12
    hours = hours ? hours : 12;

    let formattedTime = hours + ":" + minutes + " " + ampm;
    localDate = date.toLocaleDateString() + " " + formattedTime;
  }

  if (isLoadingCreatedConversations) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Stack
        sx={{
          paddingLeft: { xs: 1, md: 0 },
          paddingRight: { xs: 1, md: 0 },
          mb: 5,
        }}
      >
        {user?.is_trial_active && (
          <>
            {" "}
            <Typography variant="h5">Free Trial Active</Typography>
            <Typography variant="body1" sx={{ pb: 3 }}>
              {" "}
              Trial Ends {localDate}
            </Typography>
          </>
        )}

        {!user?.is_trial_active && localDate != "N/A" && (
          <>
            {" "}
            <Typography variant="h5">Free Trial Ended</Typography>
            <Button
              color="inherit"
              size="large"
              variant="contained"
              rel="noopener"
              component={RouterLink}
              to="/trial"
              sx={{ maxWidth: 250, mb: 3 }}
            >
              Contact Tacit
            </Button>
          </>
        )}
        <Button
          size="large"
          variant="contained"
          endIcon={<Iconify icon="carbon:launch" />}
          rel="noopener"
          component={RouterLink}
          to="/create"
          // color="primary"
          sx={{
            maxWidth: 250,
            backgroundColor: (theme) => theme.palette.primary.dark, // Using lighter primary color
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.darker, // Even lighter on hover, if defined
            },
          }}
        >
          Create Conversation
        </Button>
      </Stack>
      {createdConversations && (
        <Stack
          sx={{
            paddingLeft: { xs: 1, md: 0 },
            paddingRight: { xs: 1, md: 0 },
          }}
        >
          {createdConversations?.length == 0 ? (
            <Typography variant="h3" sx={{ pt: 5 }} align="center">
              Conversations you create will appear here
            </Typography>
          ) : (
            <>
              <Typography variant="h5">
                Previous Conversations{" "}
                <Link
                  href="https://scribehow.com/shared/Navigating_Your_Moderator_Dashboard__mazE2GuiRJCmfxZhTkUo5A"
                  target="_blank"
                >
                  <HelpIcon fontSize="small" sx={{ color: "black" }} />
                </Link>
              </Typography>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={2}
                sx={{ mt: 5, mb: 3 }}
              >
                <TextField
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setPage(0);
                  }}
                  fullWidth
                  hiddenLabel
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="carbon:search"
                          width={24}
                          sx={{ color: "text.disabled" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Stack
              spacing={2}
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
            >
              <DatePicker label="Start Date" sx={{ width: 1, minWidth: 180 }} />
              <DatePicker label="End Date" sx={{ width: 1, minWidth: 180 }} />
            </Stack> */}
              </Stack>

              <TableContainer
                sx={{
                  overflow: "unset",

                  [`& .${tableCellClasses.head}`]: {
                    color: "text.primary",
                  },
                  [`& .${tableCellClasses.root}`]: {
                    bgcolor: "background.default",
                    borderBottomColor: (theme) => theme.palette.divider,
                  },
                }}
              >
                <Scrollbar>
                  <Table
                    sx={{
                      minWidth: 720,
                    }}
                    size={dense ? "small" : "medium"}
                  >
                    <AccountConversationsTableHead
                      order={order}
                      orderBy={orderBy}
                      onSort={handleSort}
                      headCells={TABLE_HEAD}
                      rowCount={createdConversations.length}
                    />

                    <TableBody>
                      {stableSort(
                        filteredResponses,
                        getComparator(order, orderBy)
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <AccountConversationsTableRow
                            key={row.conversation_id}
                            row={row}
                            searchTerm={searchTerm}
                            order={order}
                            orderBy={orderBy}
                            page={page}
                            rowsPerPage={rowsPerPage}
                          />
                        ))}

                      {emptyRows > 0 && (
                        <TableRow
                          sx={{
                            height: (dense ? 36 : 57) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={9} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>

              <Box sx={{ position: "relative" }}>
                <TablePagination
                  page={page}
                  component="div"
                  count={filteredResponses.length}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[2, 5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </>
          )}
        </Stack>
      )}
    </>
  );
}
