import ResultsIdeaView from "src/sections/_results/view/results-idea-view";

const ResultsIdeaPage = () => {
  return (
    <>
      <ResultsIdeaView />
    </>
  );
};

export default ResultsIdeaPage;
