import {
  FETCH_QUESTION_IDEAS_STATS,
  START_LOADING_QUESTION_IDEAS_STATS,
  END_LOADING_QUESTION_IDEAS_STATS,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  ideaStats: {},
};

const questionsIdeasReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_QUESTION_IDEAS_STATS:
      return { ...state, isLoading: true };
    case END_LOADING_QUESTION_IDEAS_STATS:
      return { ...state, isLoading: false };
    case FETCH_QUESTION_IDEAS_STATS:
      return {
        ...state,
        ideaStats: {
          ...state.ideaStats,
          [action.payload.questionId]: {
            stats: action.payload.stats,
          },
        },
      };
    default:
      return state;
  }
};

export default questionsIdeasReducer;
