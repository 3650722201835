import React from "react";

import Box from "@mui/material/Box";
import Timeline from "@mui/lab/Timeline";
import TimelineDot from "@mui/lab/TimelineDot";
import Container from "@mui/material/Container";
import TimelineItem from "@mui/lab/TimelineItem";
import Typography from "@mui/material/Typography";
import TimelineContent from "@mui/lab/TimelineContent";
import { alpha, useTheme } from "@mui/material/styles";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineSeparator from "@mui/lab/TimelineSeparator";

import { bgGradient } from "src/theme/css";
import { useResponsive } from "src/hooks/use-responsive";

// ----------------------------------------------------------------------

const TIMELINES = [
  {
    step: "STEP 1",
    title: "Topic Exploration",
    description:
      "After launching the conversation, you will be presented with an initial topic question your Moderator is looking to explore with you.",
  },
  {
    step: "STEP 2",
    title: "Ideation",
    description:
      'Simply type your initial thoughts into the response box and tap "Reply". The Tacit AI chat agent will then continue the conversation to dig deeper into your individual insights.',
  },
  {
    step: "STEP 3",
    title: "Collective Validation",
    description:
      "Once you have provided your own individual insights, you will then interact with and rate fellow participants' ideas.",
  },
  {
    step: "STEP 4",
    title: "Insight Aggregation",
    description:
      "After rating a few ideas or by tapping 'End Conversation,' your insights are instantly collected for sharing by the Moderator!",
  },
];

const COLORS = [
  "primary.lighter",
  "primary.light",
  "primary.main",
  "primary.dark",
];

// ----------------------------------------------------------------------

const StartHowItWork = React.forwardRef((props, ref) => {
  const theme = useTheme();

  const mdUp = useResponsive("up", "md");

  return (
    <>
      <div ref={ref}></div>
      <Box
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.grey[900], 0.8),
            imgUrl: "/assets/background/overlay_2.jpg",
          }),
          color: "common.white",
          py: { xs: 10, md: 15 },
        }}
      >
        <Container>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            How Tacit Works
          </Typography>

          <Timeline position={mdUp ? "alternate" : "right"}>
            {TIMELINES.map((value, index) => (
              <TimelineItem
                key={value.title}
                sx={{
                  "&:before": {
                    ...(!mdUp && { display: "none" }),
                  },
                }}
              >
                <TimelineSeparator>
                  <TimelineDot sx={{ bgcolor: `${COLORS[index]}` }} />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent sx={{ pb: { xs: 3, md: 5 } }}>
                  <Typography
                    variant="overline"
                    sx={{ color: `${COLORS[index]}` }}
                  >
                    {value.step}
                  </Typography>

                  <Typography variant="h4" sx={{ mt: 0.5, mb: 1 }}>
                    {value.title}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      opacity: 0.72,
                      maxWidth: { md: 360 },
                      ...(index % 2 && {
                        ml: "auto",
                      }),
                    }}
                  >
                    {value.description}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Container>
      </Box>
    </>
  );
});

export default StartHowItWork;
