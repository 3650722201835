import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import Button from "@mui/material/Button";

import Label from "src/components/label";
import Iconify from "src/components/iconify";
//  utils
import { fDate } from "src/utils/format-time";
import { fCurrency } from "src/utils/format-number";
import { setUserResponsesTableState } from "src/actions/tableActions";
import { set } from "date-fns";
// ----------------------------------------------------------------------

export default function AccountConversationsTableRow({
  row,
  searchTerm,
  order,
  orderBy,
  page,
  rowsPerPage,
}) {
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputStyles = {
    pl: 1,
    [`&.${inputBaseClasses.focused}`]: {
      bgcolor: "action.selected",
    },
  };

  let date = new Date(row.created_at);

  let hours = date.getHours();
  let minutes = date.getMinutes().toString().padStart(2, "0");

  // Determine AM or PM suffix
  let ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour format to 12-hour format
  hours = hours % 12;
  // Convert 0 hours (midnight) to 12
  hours = hours ? hours : 12;

  let formattedTime = hours + ":" + minutes + " " + ampm;
  let localDate = date.toLocaleDateString() + " " + formattedTime;

  const highlightSearchTerm = (text, term) => {
    if (!term) return text;

    const parts = text.split(new RegExp(`(${term})`, "gi"));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === term.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: "yellow" }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </>
    );
  };

  const handleConversationView = () => {
    dispatch(
      setUserResponsesTableState({
        order: order,
        orderBy: orderBy,
        searchTerm: searchTerm,
        page: page,
        rowsPerPage: rowsPerPage,
      })
    );
    navigate(`/account/conversation/${row?.conversation_id}`);
  };

  function truncateDescription(description, length = 50) {
    if (description.length <= length) {
      return description;
    }

    return description.substring(0, length) + "...";
  }

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {highlightSearchTerm(row.title, searchTerm)}
          </Typography>
        </TableCell>

        {/* <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {truncateDescription(row.description)}
          </Typography>
        </TableCell> */}

        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {localDate}
          </Typography>
        </TableCell>

        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.user_count}
          </Typography>
        </TableCell>

        <TableCell>
          <Label
            color={
              (row.status == "active" && "success") ||
              (row.status == "complete" && "primary") ||
              "warning"
            }
          >
            {row.status}
          </Label>
        </TableCell>

        <TableCell align="right" padding="none">
          <MenuItem onClick={handleConversationView}>
            <Iconify icon="carbon:view" sx={{ mr: 1 }} />
            View
          </MenuItem>
        </TableCell>
      </TableRow>
    </>
  );
}

AccountConversationsTableRow.propTypes = {
  row: PropTypes.object,
};
