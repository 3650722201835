import {
  SEND_MESSAGE,
  START_LOADING_GPTRESPONSE,
  END_LOADING_GPTRESPONSE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  messages: [],
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_GPTRESPONSE:
      return { ...state, isLoading: true };
    case END_LOADING_GPTRESPONSE:
      return { ...state, isLoading: false };
    case SEND_MESSAGE:
      return { ...state, messages: action.payload };
    default:
      return state;
  }
};

export default messageReducer;
