import InfoTooltip from "src/components/dashboard/info-tooltip";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  useTheme,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";

export const BarCard = ({ title, number, percent, color, icon, info }) => {
  const theme = useTheme();

  return (
    <Stack
      spacing={2.2}
      sx={{ p: 3, borderRadius: 2, bgcolor: "background.neutral" }}
    >
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="space-between"
        spacing={3}
      >
        <Box sx={{ height: "30px", display: "flex", alignItems: "center" }}>
          <Typography color="text.secondary" variant="overline">
            {title}
          </Typography>
        </Box>

        <InfoTooltip info={info} />
      </Stack>

      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={5}
      >
        {" "}
        {!number ? (
          <Typography variant="h2">N/A</Typography>
        ) : (
          <Typography variant="h2">{number == 0 ? "N/A" : number}</Typography>
        )}
        <Avatar
          sx={{
            backgroundColor: color,
            height: 56,
            width: 56,
          }}
        >
          <Iconify icon={icon} width={25} />
        </Avatar>
      </Stack>
      <LinearProgress value={percent} variant="determinate" />
    </Stack>
  );
};
