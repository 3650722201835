import ResultsUserResponsesMessages from "../user-responses/results-user-responses-messages";
import { useEffect } from "react";
export default function ResultsUserResponsesMessagesView() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ResultsUserResponsesMessages />
    </>
  );
}
