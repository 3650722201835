import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSummaryQuestion } from "src/actions/resultActions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Container } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import { getQuestion } from "src/actions/questionActions";
import { useLocation } from "react-router-dom";

import { TotalRatingsCard } from "src/components/dashboard/total-ratings-card";
import { BarCard } from "src/components/dashboard/bar-card";
import useTheme from "@mui/material/styles/useTheme";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import o from "react-lazy-load-image-component";
import { set } from "nprogress";
import { createTraitsSummary } from "src/actions/resultActions";
import { createTraitsTopAnswer } from "src/actions/resultsCategoriesActions";

const ResultsQuestionAnswer = ({
  selectedDemographics,
  filteredCategories,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();

  const [orginalDemographics, setOrginalDemographics] =
    useState(selectedDemographics);

  const [showGenerateAnswer, setShowGenerateAnswer] = useState(false);

  const { results: conversationResultsId, question: questionId } = useParams();
  const { isLoading: isLoadingSummary, summaries } = useSelector(
    (state) => state.resultData
  );
  const { isLoading: isLoadingConversation, conversation } = useSelector(
    (state) => state.conversationData
  );

  const { isLoading: isLoadingQuestion, question } = useSelector(
    (state) => state.questionData
  );

  const {
    isLoading: isLoadingCategories,
    categories,
    isLoadingAnswer,
    topTraitAnswer,
  } = useSelector((state) => state.questionsCategoriesData);

  useEffect(() => {
    if (!question[questionId] && !isLoadingQuestion) {
      dispatch(getQuestion(questionId));
    }
  }, [location]);

  useEffect(() => {
    if (!summaries[questionId] && !isLoadingSummary) {
      dispatch(
        getSummaryQuestion(conversationResultsId, questionId, { reload: false })
      );
    }
  }, [
    conversationResultsId,
    questionId,
    dispatch,
    isLoadingSummary,
    summaries,
  ]);

  useEffect(() => {
    console.log("filteredCategories", filteredCategories);
    console.log("selectedDemographics", selectedDemographics);
    if (selectedDemographics.length == 0 && orginalDemographics.length == 0) {
      setShowGenerateAnswer(false);
    } else {
      setShowGenerateAnswer(selectedDemographics !== orginalDemographics);
    }

    console.log("showGenerateAnswer", showGenerateAnswer);
  }, [selectedDemographics, orginalDemographics, showGenerateAnswer]);

  const createTraitSummary = () => {
    // Create json of the categories and traits
    setOrginalDemographics(selectedDemographics);
    const data = {
      categories: filteredCategories,
      traits: selectedDemographics,
    };

    dispatch(createTraitsSummary(questionId, data));
  };

  const createTraitAnswer = () => {
    // Create json of the categories and traits
    setOrginalDemographics(selectedDemographics);
    const data = {
      categories: filteredCategories,
      traits: selectedDemographics,
    };

    dispatch(createTraitsTopAnswer(questionId, data));
  };

  // if (isLoadingQuestion || isLoadingCategories) {
  //   return (
  //     <Stack
  //       sx={{
  //         paddingLeft: { xs: 0, md: 5 },
  //         paddingRight: { xs: 0, md: 5 },
  //       }}
  //     >
  //       <CircularProgress />
  //     </Stack>
  //   );
  // }

  return (
    <Stack
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Stack sx={{ p: 2 }}>
        <Typography variant="h5" color="darkgrey">
          Question:
        </Typography>
        <Typography variant="h3" color="grey">
          {question[questionId]?.question}
        </Typography>
      </Stack>

      <>
        <Stack sx={{ p: 2 }}>
          <Box
            sx={{
              border: "3px solid #0096C7", // Dark blue color

              borderRadius: "16px", // Rounded borders
              padding: "16px", // Padding inside the box
              backgroundColor: "white", // Optional: Background color
              mb: 2,
            }}
          >
            {!question[questionId]?.phase_2 && (
              <>
                <Typography variant="h4" color="primary">
                  Summary
                </Typography>
                {isLoadingSummary ||
                isLoadingQuestion ||
                isLoadingCategories ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Typography variant="h6" sx={{ color: "gray" }}>
                      {!conversation?.data_analysis_complete
                        ? "Initiate Tacit Data Analysis on the account conversation page to view results"
                        : summaries[questionId]?.summary}
                    </Typography>
                    {showGenerateAnswer &&
                      conversation?.data_analysis_complete &&
                      filteredCategories?.length > 0 &&
                      conversation?.traits?.length != 0 && (
                        <Button
                          color="primary"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          onClick={createTraitSummary}
                        >
                          Generate Summary for Selected Demographics
                        </Button>
                      )}
                  </>
                )}
              </>
            )}
            {question[questionId]?.phase_2 && (
              <>
                <Typography variant="h4" color="primary">
                  Top Collective Answer
                </Typography>
                {isLoadingAnswer || isLoadingQuestion || isLoadingCategories ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Typography variant="h6" sx={{ color: "gray" }}>
                      {!conversation?.data_analysis_complete
                        ? "Initiate Tacit Data Analysis on the account conversation page to view results"
                        : !categories[questionId]?.data?.top_category
                            ?.top_category_answer
                        ? " Not enough Collective Validation data to show an answer"
                        : topTraitAnswer
                        ? topTraitAnswer
                        : categories[questionId]?.data?.top_category
                            ?.top_category_answer}
                    </Typography>

                    {showGenerateAnswer &&
                      categories[questionId]?.data?.top_category
                        ?.top_category_answer &&
                      filteredCategories?.length > 0 &&
                      conversation?.traits?.length != 0 && (
                        <Button
                          color="primary"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          onClick={createTraitAnswer}
                        >
                          Generate Answer for Selected Demographics
                        </Button>
                      )}
                  </>
                )}
              </>
            )}
          </Box>
        </Stack>
        <Container
          sx={{
            flexGrow: 1,
            textAlign: "left",
            display: "grid",
            gap: { xs: 5, md: 2 },
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(4, 1fr)",
            },
            mb: 3,
          }}
        >
          <BarCard
            title="Average Category Rating"
            number={categories[questionId]?.data?.metrics?.average_rating}
            percent={categories[questionId]?.data?.metrics?.average_rating * 20}
            color={theme.palette.primary.main}
            icon="carbon:star"
            info="Average participant rating level across this question's categories"
          />
          <TotalRatingsCard
            title="Agreement"
            value={
              categories[questionId]?.data?.metrics?.agreement
                ? categories[questionId]?.data?.metrics?.agreement
                : "N/A"
            }
            color={theme.palette.error.main}
            icon="carbon:asset"
            info="Average rating agreement across this question's categories"
            percent={false}
          />

          <TotalRatingsCard
            title="Collective Validation Participation"
            value={
              categories[questionId]?.data?.metrics?.phase_2_participation
                ? categories[questionId]?.data?.metrics?.phase_2_participation
                : "N/A"
            }
            color={theme.palette.warning.main}
            icon="carbon:group"
            info="Percent of participants who rejoined for Collective Validation"
            percent={true}
          />
          <TotalRatingsCard
            title="Total Categories"
            value={categories[questionId]?.data?.categories?.length}
            color={theme.palette.success.main}
            icon="carbon:list"
            info="Number of categories created by your conversation"
            percent={false}
          />
        </Container>
      </>
    </Stack>
  );
};

export default ResultsQuestionAnswer;
