import {
  START_LOADING_QUESTION_ANSWER,
  END_LOADING_QUESTION_ANSWER,
  FETCH_QUESTION_ANSWER,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  messages: null,
};

const fetchAskQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_QUESTION_ANSWER:
      return { ...state, isLoadingMessages: true };
    case END_LOADING_QUESTION_ANSWER:
      return { ...state, isLoadingMessages: false };
    case FETCH_QUESTION_ANSWER:
      return { ...state, messages: action.payload };
    default:
      return state;
  }
};

export default fetchAskQuestionReducer;
