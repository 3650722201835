import {
  FETCH_IDEA_FEEDBACK,
  START_LOADING_IDEA_FEEDBACK,
  END_LOADING_IDEA_FEEDBACK,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  ideaFeedback: null,
};

const ideaReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_IDEA_FEEDBACK:
      return { ...state, isLoading: true };
    case END_LOADING_IDEA_FEEDBACK:
      return { ...state, isLoading: false };
    case FETCH_IDEA_FEEDBACK:
      return { ...state, ideaFeedback: action.payload };
    default:
      return state;
  }
};

export default ideaReducer;
