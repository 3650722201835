import { Helmet } from "react-helmet-async";

import ResetPasswordView from "src/sections/_auth/view/reset-password-view";

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  return (
    <>
      <ResetPasswordView />
    </>
  );
}
