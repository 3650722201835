import ResultsQuestionCategoryView from "src/sections/_results/view/results-question-category-view";
const ResultsQuestionCategoryPage = () => {
  return (
    <>
      <ResultsQuestionCategoryView />
    </>
  );
};

export default ResultsQuestionCategoryPage;
