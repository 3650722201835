import {
  FETCH_TABLE,
  START_LOADING_TABLE,
  END_LOADING_TABLE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  table: null,
};

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_TABLE:
      return { ...state, isLoading: true };
    case END_LOADING_TABLE:
      return { ...state, isLoading: false };
    case FETCH_TABLE:
      return { ...state, table: action.payload };
    default:
      return state;
  }
};

export default tableReducer;
