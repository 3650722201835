import * as Yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";

import { paths } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import { RouterLink } from "src/routes/components";
import FormProvider, { RHFTextField } from "src/components/hook-form";

import { login } from "src/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
// ----------------------------------------------------------------------

export default function LogindView() {
  const dispatch = useDispatch();
  const passwordShow = useBoolean();
  const [errorMessage, setErrorMessage] = useState(null);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("That is not an email"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password should be of minimum 6 characters length"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      //   reset();
      await dispatch(login(data.email, data.password));
    } catch (error) {
      setErrorMessage(
        "Username or password not correct. Ensure that account has been activated through email"
      );
    }
  });

  const continueWithGoogle = async () => {
    console.log(
      `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_URL}`
    );
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_URL}`
      );
      window.location.replace(res.data.authorization_url);
    } catch (error) {
      console.log(error);
    }
  };

  const renderHead = (
    <div>
      <Typography variant="h3" paragraph>
        Login
      </Typography>

      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {`Don’t have an account? `}
        <Link
          component={RouterLink}
          rel="noopener"
          to="/signup"
          variant="subtitle2"
          color="primary"
        >
          Get started
        </Link>
      </Typography>
    </div>
  );

  const renderSocials = (
    <Stack direction="row" spacing={2}>
      <Button
        fullWidth
        size="large"
        color="inherit"
        variant="outlined"
        onClick={continueWithGoogle}
      >
        <Iconify icon="logos:google-icon" width={24} />
      </Button>

      {/* <Button fullWidth size="large" color="inherit" variant="outlined">
        <Iconify
          icon="carbon:logo-facebook"
          width={24}
          sx={{ color: "#1877F2" }}
        />
      </Button>

      <Button color="inherit" fullWidth variant="outlined" size="large">
        <Iconify
          icon="carbon:logo-github"
          width={24}
          sx={{ color: "text.primary" }}
        />
      </Button> */}
    </Stack>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5} alignItems="flex-end">
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={passwordShow.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordShow.onToggle} edge="end">
                  <Iconify
                    icon={
                      passwordShow.value ? "carbon:view" : "carbon:view-off"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
        <Link
          component={RouterLink}
          rel="noopener"
          to="/reset-password"
          variant="body2"
          underline="always"
          color="text.secondary"
        >
          Forgot password?
        </Link>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <>
      {renderHead}

      {renderForm}

      <Divider>
        <Typography variant="body2" sx={{ color: "text.disabled" }}>
          or continue with
        </Typography>
      </Divider>

      {renderSocials}
    </>
  );
}
