import ResultsCategoriesQuestionSummary from "../question/categories/results-question-categories-summary";
import ResultsQuestionCategoriesNone from "../question/categories/results-question-categories-none";
import ResultsQuestionCategoriesList from "../question/categories/results-question-categories-list";
import ResultsQuestionCategoriesTable from "../question/categories-table/results-question-categories-table";
import ResultsQuestionAnswer from "../question/categories/results-question-answer";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getQuestionCategories } from "src/actions/resultsCategoriesActions";
import { useParams, useSearchParams } from "react-router-dom";
export default function ResultsCategoriesQuestionView() {
  const dispatch = useDispatch();
  const tableState = useSelector((state) => state.categoriesTableData);
  const { isLoading: isLoadingCategories, categories } = useSelector(
    (state) => state.questionsCategoriesData
  );

  const [selectedDemographics, setSelectedDemographics] = useState(
    tableState.demographics || []
  );

  const { results: conversationResultsId, question: questionId } = useParams();
  const [filteredCategories, setFilteredCategories] = useState(
    categories[questionId]?.data.categories || []
  );

  useEffect(() => {
    if (!categories[questionId] && !isLoadingCategories) {
      dispatch(getQuestionCategories(conversationResultsId, questionId));
    }
    setSelectedDemographics(tableState.demographics || []);
  }, [questionId]);
  return (
    <>
      {/* {conversation?.data_analysis_complete ? ( */}
      <>
        <ResultsQuestionAnswer
          selectedDemographics={selectedDemographics}
          filteredCategories={filteredCategories}
        />
        <ResultsQuestionCategoriesTable
          selectedDemographics={selectedDemographics}
          setSelectedDemographics={setSelectedDemographics}
          filteredCategories={filteredCategories}
          setFilteredCategories={setFilteredCategories}
        />
        {/* <ResultsCategoriesQuestionSummary
            selectedTraits={selectedTraits}
            setSelectedTraits={setSelectedTraits}
          />
          <ResultsQuestionCategoriesList selectedTraits={selectedTraits} /> */}
      </>
      {/* ) : (
        <ResultsQuestionCategoriesNone />
      )} */}
    </>
  );
}
