import { ADD_RESULTS_MESSAGE } from "../actions/actionTypes";

const initialState = {
  messages: {},
};

const resultsChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RESULTS_MESSAGE:
      const { questionID, message } = action.payload;
      const questionMessages = state.messages[questionID] || [];
      return {
        ...state,
        messages: {
          ...state.messages,
          [questionID]: [...questionMessages, message],
        },
      };
    default:
      return state;
  }
};

export default resultsChatReducer;
