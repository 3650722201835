import ResultsHomeConversation from "../home/results-home-conversation";
import ResultsHomeStats from "../home/results-home-stats";
import ResultsIdeasDashboard from "../ideas/results-ideas-dashboard";
export default function ResultsHomeView() {
  return (
    <>
      <ResultsHomeConversation />
      <ResultsHomeStats />
      <ResultsIdeasDashboard />
    </>
  );
}
