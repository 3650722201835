export const _socials = [
  // {
  //   value: "facebook",
  //   label: "FaceBook",
  //   icon: "carbon:logo-facebook",
  //   color: "#1877F2",
  // },
  {
    value: "email",
    label: "Email",
    icon: "carbon:email",
    color: "#E02D69",
    link: "mailto:team@tacit.solutions",
  },
  {
    value: "linkedin",
    label: "Linkedin",
    icon: "carbon:logo-linkedin",
    color: "#007EBB",
    link: "https://www.linkedin.com/company/tacitsolutions/about/",
  },
  // {
  //   value: "twitter",
  //   label: "Twitter",
  //   icon: "carbon:logo-twitter",
  //   color: "#00AAEC",
  // },
];
