import { Outlet } from "react-router-dom";

import ResultsHomePage from "src/pages/results/resultsHome";
import ResultsQuestionPage from "src/pages/results/results-question";
import ResultsDataPage from "src/pages/results/results-data";
import ResultsIdeasPage from "src/pages/results/results-ideas";
import ResultsUserResponsesPage from "src/pages/results/results-user-responses";
import ResultsFeedbackPage from "src/pages/results/results-feedback";
import ResultsQuestionSummaryPage from "src/pages/results/results-question-summary";
import ResultsQuestionChatPage from "src/pages/results/results-question-chat";
import ResultsQuestionIdeasPage from "src/pages/results/results-question-ideas";
import ResultsIdeaPage from "src/pages/results/results-idea";
import ResultsLayout from "src/layouts/results";
import ResultsUserResponsesMessagesPage from "src/pages/results/results-user-responses-messages";
import ResultsQuestionCategoriesPage from "src/pages/results/results-question-categories";
import ResultsQuestionCategoryPage from "src/pages/results/results-question-category";

export const resultRoutes = [
  {
    path: "/results/:results",
    element: (
      <ResultsLayout>
        <Outlet />
      </ResultsLayout>
    ),
    children: [
      { element: <ResultsHomePage />, index: true },
      // { path: ":question", element: <ResultsQuestionPage /> },
      { path: "summary/:question", element: <ResultsQuestionSummaryPage /> },
      { path: "chat/:question", element: <ResultsQuestionChatPage /> },
      {
        path: "ideas/:question",
        element: <ResultsQuestionIdeasPage />,
      },
      { path: "ideas/:question/:idea", element: <ResultsIdeaPage /> },
      { path: "data", element: <ResultsDataPage /> },
      { path: "ideas", element: <ResultsIdeasPage /> },
      {
        path: "user-responses/:question",
        element: <ResultsUserResponsesPage />,
      },
      {
        path: "categories/:question",
        element: <ResultsQuestionCategoriesPage />,
      },
      {
        path: "categories/:question/category/:category",
        element: <ResultsQuestionCategoryPage />,
      },
      {
        path: "user-responses/:question/user/:user",
        element: <ResultsUserResponsesMessagesPage />,
      },
      { path: "feedback", element: <ResultsFeedbackPage /> },
    ],
  },
];
