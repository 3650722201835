import ResultsFeedbackView from "src/sections/_results/view/results-feedback-view";

const ResultsFeedbackPage = () => {
  return (
    <>
      <ResultsFeedbackView />
    </>
  );
};

export default ResultsFeedbackPage;
