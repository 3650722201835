import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Iconify from "src/components/iconify";
import { fDate } from "src/utils/format-time";
import { fCurrency } from "src/utils/format-number";
import {
  getConversationStats,
  getAllQuestionStats,
} from "src/actions/resultActions";

// ----------------------------------------------------------------------

export default function ResultsDataBasic() {
  const dispatch = useDispatch();
  const { results: resultQuestionsId } = useParams();

  const { isLoading: isLoadingStats, stats } = useSelector(
    (state) => state.conversationStatsData
  );

  const { isLoading: isLoadingQuestionStats, questionStats } = useSelector(
    (state) => state.questionStatsData
  );

  const sortedQuestions = questionStats?.sort((a, b) => a.order - b.order);

  const labels = ["Zero", "Minimal", "Balanced", "Thorough", "Comprehensive"];

  useEffect(() => {
    if (!stats && !isLoadingStats) {
      dispatch(getConversationStats(resultQuestionsId));
    }
    if (!questionStats && !isLoadingQuestionStats) {
      dispatch(getAllQuestionStats(resultQuestionsId));
    }
  }, []);

  let date = new Date(stats?.created_at);
  let localDate = date.toLocaleString();

  return (
    <Container>
      <Stack
        spacing={3}
        sx={{
          p: 5,
          borderRadius: 2,
          border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h5">Conversation Details</Typography>

        <LineItem
          icon="carbon:calendar"
          label="Creation Date"
          value={localDate}
        />
        <LineItem
          icon="carbon:cube"
          label="Number of Questions"
          value={
            stats
              ? stats?.num_questions.toString() + " questions"
              : "loading questions"
          }
        />

        <LineItem
          icon="carbon:events"
          label="Number Particpants"
          value={
            stats
              ? stats?.user_count.toString() + " participants"
              : "loading participants"
          }
        />
        <LineItem
          icon="carbon:time"
          label="Total Time Spent"
          value={
            stats
              ? Math.round(stats?.num_seconds / 60).toString() + " minutes"
              : "loading minutes"
          }
        />

        <LineItem
          icon="carbon:timer"
          label="Average Time Per Message"
          value={
            stats
              ? Math.round(
                  stats?.num_seconds / stats?.num_messages
                ).toString() + " seconds"
              : "loading seconds"
          }
        />

        <LineItem
          icon="carbon:increase-level"
          label="Total Particpant Messages Sent"
          value={
            stats
              ? stats?.num_messages.toString() + " messages"
              : "loading messages"
          }
        />

        <LineItem
          icon="carbon:document-word-processor"
          label="Average Message Length"
          value={
            stats
              ? Math.round(stats?.num_words / stats?.num_messages).toString() +
                " words"
              : "loading words"
          }
        />

        <Divider sx={{ borderStyle: "dashed" }} />

        <Typography variant="h5">Question Details</Typography>

        {isLoadingQuestionStats
          ? "Loading Question Stats..."
          : sortedQuestions?.map((question, index) => (
              <React.Fragment key={question.question_stats_id}>
                <Typography variant="h6">{`Question ${index + 1}: ${
                  question?.text
                }`}</Typography>
                <LineItem
                  icon="carbon:events"
                  label="Number Particpants"
                  value={
                    question
                      ? question?.user_count.toString() + " participants"
                      : "loading participants"
                  }
                />
                <LineItem
                  icon="carbon:flow"
                  label="Depth Level"
                  value={
                    question
                      ? labels[question?.depth - 1] + " depth"
                      : "loading depth"
                  }
                />
                <LineItem
                  icon="carbon:time"
                  label="Total Time Spent"
                  value={
                    question
                      ? Math.round(question?.num_seconds / 60).toString() +
                        " minutes"
                      : "loading minutes"
                  }
                />
                <LineItem
                  icon="carbon:timer"
                  label="Average Time For Question"
                  value={
                    question
                      ? Math.round(
                          question?.num_seconds / question?.user_count / 60
                        ).toString() + " minutes"
                      : "loading minutes"
                  }
                />
                <LineItem
                  icon="carbon:timer"
                  label="Average Time Per Message"
                  value={
                    question
                      ? Math.round(
                          question?.num_seconds / question?.num_messages
                        ).toString() + " seconds"
                      : "loading seconds"
                  }
                />
                <LineItem
                  icon="carbon:increase-level"
                  label="Number of Messages Sent"
                  value={
                    question
                      ? question?.num_messages.toString() + " messages"
                      : "loading messages"
                  }
                />
                <LineItem
                  icon="carbon:document-word-processor"
                  label="Average Message Length"
                  value={
                    question
                      ? Math.round(
                          question?.num_words / question?.num_messages
                        ).toString() + " words"
                      : "loading words"
                  }
                />
              </React.Fragment>
            ))}
      </Stack>
    </Container>
  );
}

// ----------------------------------------------------------------------

function LineItem({ icon, label, value }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ typography: "body2", color: "text.secondary" }}
    >
      <Iconify icon={icon} width={24} sx={{ mr: 1 }} /> {label}
      <Typography
        variant="subtitle2"
        sx={{ color: "text.primary", flexGrow: 1, textAlign: "right" }}
      >
        {value}
      </Typography>
    </Stack>
  );
}

LineItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  value: PropTypes.string,
};
