import {
  START_LOADING,
  END_LOADING,
  FETCH_CONVERSATION,
  CREATE_CONVERSATION,
  FETCH_CONVERSATION_RESULTS,
  START_LOADING_CREATE_CONVERSATION,
  END_LOADING_CREATE_CONVERSATION,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  conversation: null,
  results: null,
};

const conversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case START_LOADING_CREATE_CONVERSATION:
      return { ...state, isLoading: true };
    case END_LOADING_CREATE_CONVERSATION:
      return { ...state, isLoading: false };
    case FETCH_CONVERSATION:
      return { ...state, conversation: action.payload };
    case CREATE_CONVERSATION:
      return { ...state, conversation: action.payload };
    case FETCH_CONVERSATION_RESULTS:
      return { ...state, results: action.payload };
    default:
      return state;
  }
};

export default conversationReducer;
