import { combineReducers } from "redux";
import conversationReducer from "./conversationReducer";
import questionReducer from "./questionReducer";
import userReducer from "./userReducer";
import messageReducer from "./messageReducer";
import resultReducer from "./resultReducer";
import askQuestionReducer from "./askQuestionReducer";
import fetchAskQuestionReducer from "./fetchAskQuestionReducer";
import tableReducer from "./tableReducer";
import conversationStatsReducer from "./fetchConversationStatsReducer";
import questionStatsReducer from "./questionStatsReducer";
import resultsChatReducer from "./resultsChatReducer";
import conversationIdeasReducer from "./conversationIdeasReducer";
import questionsIdeasReducer from "./questionsIdeasReducer";
import ideaReducer from "./ideaReducer";
import userResponseReducer from "./resultsUserResponsesReducer";
import ideasTableReducer from "./ideasTableReducer";
import userResponsesTableReducer from "./userResponsesTableReducer";
import authReducer from "./authReducer";
import accountReducer from "./accountReducer";
import questionsCategoriesReducer from "./questionsCategoriesReducer";
import categoriesTableReducer from "./categoriesTableReducer";
import categoryReducer from "./categoryReducer";

const rootReducer = combineReducers({
  conversationData: conversationReducer,
  questionData: questionReducer,
  userData: userReducer,
  messageData: messageReducer,
  resultData: resultReducer,
  tableData: tableReducer,
  askData: askQuestionReducer,
  askQuestionData: fetchAskQuestionReducer,
  conversationStatsData: conversationStatsReducer,
  questionStatsData: questionStatsReducer,
  resultsChatData: resultsChatReducer,
  conversationIdeasData: conversationIdeasReducer,
  questionIdeasData: questionsIdeasReducer,
  ideaData: ideaReducer,
  userResponseData: userResponseReducer,
  ideasTableData: ideasTableReducer,
  userResponseTableData: userResponsesTableReducer,
  categoriesTableData: categoriesTableReducer,
  authData: authReducer,
  accountData: accountReducer,
  questionsCategoriesData: questionsCategoriesReducer,
  categoryData: categoryReducer,
});

export default rootReducer;
