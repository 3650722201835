import ConversationFinishedView from "src/sections/_conversation-finished/view/conversation-finished-view";

// ----------------------------------------------------------------------

export default function ConversationFinishedPage() {
  return (
    <>
      <ConversationFinishedView />
    </>
  );
}
