import ResultsQuestionIdeasView from "src/sections/_results/view/results-questions-ideas-view";

const ResultsQuestionIdeasPage = () => {
  return (
    <>
      <ResultsQuestionIdeasView />
    </>
  );
};

export default ResultsQuestionIdeasPage;
