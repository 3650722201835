import ResultsIdeasAbout from "../ideas/results-ideas-about";
import ResultsComingSoon from "../results-coming-soon";
import ResultsIdeasDashboard from "../ideas/results-ideas-dashboard";

export default function ResultsIdeasView() {
  return (
    <>
      <ResultsIdeasAbout />
      <ResultsIdeasDashboard />
    </>
  );
}
