import ResultsQuestionAbout from "../question/results-question-about";
import ResultsQuestionSummary from "../question/results-question-summary";
import ResultsQuestionChat from "../question/results-question-chat";
export default function ResultsQuestionSummaryView() {
  return (
    <>
      {/* <ResultsQuestionAbout />
      <ResultsQuestionSummary /> */}
      <ResultsQuestionChat />
    </>
  );
}
