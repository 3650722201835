import {
  FETCH_USER_RESPONSE_DATA,
  START_LOADING_USER_RESPONSE_DATA,
  END_LOADING_USER_RESPONSE_DATA,
  GET_USER_RESPONSE,
  START_LOADING_USER_RESPONSE,
  END_LOADING_USER_RESPONSE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  userResponseStats: {},
  userResponse: [],
  loadingUserResponse: false,
};

const userResponseReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_USER_RESPONSE_DATA:
      return { ...state, isLoading: true };
    case END_LOADING_USER_RESPONSE_DATA:
      return { ...state, isLoading: false };
    case FETCH_USER_RESPONSE_DATA:
      return {
        ...state,
        userResponseStats: {
          ...state.userResponseStats,
          [action.payload.questionId]: {
            stats: action.payload.stats,
          },
        },
      };
    case GET_USER_RESPONSE:
      return {
        ...state,
        userResponse: action.payload,
      };
    case START_LOADING_USER_RESPONSE:
      return {
        ...state,
        loadingUserResponse: true,
      };
    case END_LOADING_USER_RESPONSE:
      return {
        ...state,
        loadingUserResponse: false,
      };
    default:
      return state;
  }
};

export default userResponseReducer;
