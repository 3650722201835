import { useState, useCallback, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TablePagination from "@mui/material/TablePagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Snackbar } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

// import { ideaStats[questionId]?.stats.ideas } from "src/_mock";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";

import { stableSort, getComparator } from "./utils";
import ResultsQuestionIdeasTableRow from "./results-question-ideas-table-row";
import ResultsQuestionIdeasTableHead from "./results-question-ideas-table-head";
import { getQuestionIdeaStats } from "src/actions/resultsIdeasActions";
import { table } from "src/theme/overrides/components/table";
import ResultsQuestionIdeasHelp from "./results-question-ideas-help";
import { ta } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

const TABS = [
  "All Ideas",
  "Top Unique Ideas",
  "Top Common Ideas",
  // "Controversial Ideas",
  // "Most Text Feedback",
];

export default function ResultsQuestionIdeasTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { results: conversationResultsId, question: questionId } = useParams();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("category");

  const { isLoading: isLoadingQuestion, questions } = useSelector(
    (state) => state.questionData
  );

  const { isLoading: isLoadingConversation, conversation } = useSelector(
    (state) => state.conversationData
  );

  const { isLoading: isLoadingIdeaStats, ideaStats } = useSelector(
    (state) => state.questionIdeasData
  );
  const tableState = useSelector((state) => state.ideasTableData);

  const [tab, setTab] = useState(tableState.tab);
  const [order, setOrder] = useState(tableState.order);

  const [orderBy, setOrderBy] = useState(tableState.orderBy);

  const [searchTerm, setSearchTerm] = useState(tableState.searchTerm);
  const [filteredIdeas, setFilteredIdeas] = useState(
    ideaStats[questionId]?.stats.ideas || []
  );

  const [page, setPage] = useState(tableState.page);

  const [dense, setDense] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(tableState.rowsPerPage);

  const questionIsPhase2 = questions?.some(
    (question) => question.question_id === questionId && question.phase_2
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState(
    categoryId ? [categoryId] : tableState.categories
  );

  useEffect(() => {
    searchParams.delete("category");
    navigate(
      {
        pathname: `/results/${conversationResultsId}/ideas/${questionId}`,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }, [selectedCategories]);

  useEffect(() => {
    // Function to check if an idea matches the search term and selected categories
    const filterIdeas = (idea) => {
      const matchesSearchTerm = idea.idea
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const hasMatchingCategories =
        selectedCategories.length === 0 ||
        selectedCategories.includes(idea.category_id);

      return matchesSearchTerm && hasMatchingCategories;
    };

    if (ideaStats[questionId]?.stats.ideas) {
      setFilteredIdeas(ideaStats[questionId].stats.ideas.filter(filterIdeas));
    }
  }, [searchTerm, selectedCategories, ideaStats, questionId]);

  useEffect(() => {
    if (questions) {
      setSnackbarOpen(!questionIsPhase2);
    }
  }, [questionId, questionIsPhase2]);

  const handleChangeTab = useCallback((event, newValue) => {
    setOrder("asc");
    setPage(0);
    setTab(newValue);
  }, []);

  const handleSort = useCallback(
    (id) => {
      const isAsc = orderBy === id && order === "asc";
      if (id !== "") {
        if (tab != "All Ideas") {
          setOrderBy("custom");
        } else {
          setOrder(isAsc ? "desc" : "asc");
          setOrderBy(id);
        }
      }
    },
    [order, orderBy]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredIdeas.length) : 0;

  const handleCategoryChange = (event) => {
    setSelectedCategories(event.target.value);
  };

  const TABLE_HEAD = [
    { id: "idea", label: "Idea", width: 500, sortKey: "idea" },
    ...(ideaStats[questionId]?.stats.categories.length > 0
      ? [
          {
            id: "category_title",
            label: "Category",
            width: 200,
            sortKey: "category_title",
          },
        ]
      : []),
    { id: "rating_score", label: "Score", width: 100, sortKey: "rating" },
    { id: "rating_value", label: "Rating", width: 100, sortKey: "rating" },

    // { id: "related", label: "Related", width: 100 },
    {
      id: "proposal_count",
      label: "Occurence",
      width: 100,
      sortKey: "proposal_count",
    },
    // { id: "stddev", label: "Controversial", width: 100 },
    // { id: "expose_count", label: "Exposures", width: 100 },
    { id: "" },
  ];

  return (
    <>
      {ideaStats[questionId]?.stats && (
        <Stack
          sx={{
            paddingLeft: { xs: 0, md: 8 },
            paddingRight: { xs: 0, md: 8 },
          }}
        >
          {/* <Typography variant="h5" sx={{ mb: 3 }}>
            All Ideas
          </Typography> */}

          <Tabs
            value={tab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleChangeTab}
          >
            {TABS.map((category) => (
              <Tab
                key={category}
                value={category}
                label={category}
                disabled={!questionIsPhase2 && category !== "All Ideas"}
              />
            ))}
          </Tabs>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ mt: 1 }}
          >
            {ideaStats[questionId]?.stats.categories.length > 0 && (
              <>
                <FormControl sx={{ width: 300 }}>
                  {selectedCategories.length === 0 && (
                    <InputLabel
                      htmlFor="trait-select-label"
                      style={{ color: "rgba(0, 0, 0, 0.87)" }}
                    >
                      All Categories
                    </InputLabel>
                  )}
                  <Select
                    labelId="trait-select-label"
                    multiple
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        // When no traits are selected, show "All Up"
                        return;
                      } else {
                        // When traits are selected, show the chips
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                              mt: -1.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={
                                  ideaStats[questionId]?.stats?.categories.find(
                                    (category) => category.category_id === value
                                  ).title
                                }
                              />
                            ))}
                          </Box>
                        );
                      }
                    }}
                  >
                    {ideaStats[questionId]?.stats.categories.length > 0 &&
                      ideaStats[questionId]?.stats.categories.map(
                        (category) => (
                          <MenuItem
                            key={category.category_id}
                            value={category.category_id}
                          >
                            {category.title}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </>
            )}
            <TextField
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setPage(0);
              }}
              fullWidth
              hiddenLabel
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="carbon:search"
                      width={24}
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {/* <Stack
              spacing={2}
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
            >
              <DatePicker label="Start Date" sx={{ width: 1, minWidth: 180 }} />
              <DatePicker label="End Date" sx={{ width: 1, minWidth: 180 }} />
            </Stack> */}
          </Stack>

          <TableContainer
            sx={{
              overflow: "unset",

              [`& .${tableCellClasses.head}`]: {
                color: "text.primary",
              },
              [`& .${tableCellClasses.root}`]: {
                bgcolor: "background.default",
                borderBottomColor: (theme) => theme.palette.divider,
              },
            }}
          >
            <Scrollbar>
              <Table
                sx={{
                  minWidth: 720,
                }}
                size={dense ? "small" : "medium"}
              >
                <ResultsQuestionIdeasTableHead
                  order={order}
                  orderBy={orderBy}
                  onSort={handleSort}
                  headCells={TABLE_HEAD}
                  rowCount={ideaStats[questionId]?.stats.ideas.length}
                  activeTab={tab}
                />

                <TableBody>
                  {stableSort(filteredIdeas, getComparator(order, orderBy, tab))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <ResultsQuestionIdeasTableRow
                        key={row.idea_id}
                        row={row}
                        searchTerm={searchTerm}
                        tab={tab}
                        order={order}
                        orderBy={orderBy}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        selectedCategories={selectedCategories}
                        showCategory={
                          ideaStats[questionId]?.stats.categories.length > 0
                        }
                      />
                    ))}

                  {emptyRows > 0 && (
                    <TableRow
                      sx={{
                        height: (dense ? 36 : 57) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              page={page}
              component="div"
              count={filteredIdeas.length}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          <ResultsQuestionIdeasHelp />
        </Stack>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message="Collective Validation not enabled for this question"
      />
    </>
  );
}
