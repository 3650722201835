import { lazy, Suspense } from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";

import MainLayout from "../../layouts/main";

import { appRoutes } from "./all";
import { resultRoutes } from "./results";
import { accountRoutes } from "./account";
import { errorRoutes } from "./error";
import { authRoutes } from "./auth";
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: (
        <MainLayout>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [...appRoutes, ...resultRoutes, ...accountRoutes],
    },
    ...authRoutes,
    ...errorRoutes,

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
