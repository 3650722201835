import React, { useState, useRef, useEffect } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { sendMessage } from "../../../actions/messageActions";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import AlertDialog from "./alert";
import { useForm, Controller } from "react-hook-form";
import Stack from "@mui/material/Stack";

import Avatar from "@mui/material/Avatar";
import { useResponsive } from "src/hooks/use-responsive";

import Iconify from "src/components/iconify";
import { fDate } from "src/utils/format-time";
import Rating from "@mui/material/Rating";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import ProgressBar from "./progressBar";
import { useTheme } from "@mui/material/styles";
import SkipAlert from "src/components/questions/chatbox/skip-alert";
import Divider from "@mui/material/Divider";
import Slider from "@mui/material/Slider";
import StarIcon from "@mui/icons-material/Star";
import { palette } from "src/theme/palette";
import { id, pl } from "date-fns/locale";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";

import InstructionsAlert from "src/components/questions/chatbox/instructions-alert";
import IdeasRanker from "./ideaRanker";

const Phase2ChatBox = ({
  questionId,
  question,
  setNextQuestion,
  currentQuestionIndex,
  numQuestions,
  results,
  phase2,
  order,
  username,
  categories,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stop, setStop] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [phase, setPhase] = useState(2);
  const [score, setScore] = useState(-1); // Score for exposed ideas
  const [relevancy, setRelevancy] = useState(-1); // Score for relevancy
  const [typingMessage, setTypingMessage] = useState(null);
  const [index, setIndex] = useState(0);
  const [conversationLength, setConversationLength] = useState(1);
  const [categoriesCopy, setCategoriesCopy] = useState(categories);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [currentHighestCategories, setCurrentHighestCategories] = useState([]);

  const [tie, setTie] = useState(false);
  const [ideaBatch, setIdeaBatch] = useState([]);
  const [ideaPhase, setIdeaPhase] = useState(false);
  const [topCategory, setTopCategory] = useState(null);

  const [instructionsOpen, setInstructionsOpen] = useState(
    currentQuestionIndex == 0
  );

  const messagesEndRef = useRef(null);
  const mdUp = useResponsive("up", "md");

  const { user } = useSelector((state) => state.userData);
  const { isLoading: isLoadingMessages, messages } = useSelector(
    (state) => state.messageData
  );

  const {
    isLoading: isLoadingBaseConversation,
    conversation: baseConversation,
  } = useSelector((state) => state.conversationData);

  const [conversation, setConversation] = useState([]);
  const [typingStarted, setTypingStarted] = useState(false);
  const [stopWriting, setStopWriting] = useState(false);

  const ratingQuestion3 = "The conversation felt natural and organic.";

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.returnValue = "Are you sure you want to leave?";
      // In some older browsers, returning a string also works.
      return "Are you sure you want to leave?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // This is for the first question
  useEffect(
    () => {
      setStopWriting(message.length > 1000);
      if (!typingStarted && conversation.length === 0) {
        setTypingStarted(true);
        firstAnimation();
      }
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    },
    [conversation, relevancy],
    message
  );

  useEffect(() => {
    setConversationLength(conversation.length);
  }, [conversation]);

  const firstAnimation = async () => {
    // Start a new typing animation
    setTypingMessage({
      id: conversation.length + 1,
      from: "Tacit AI",
    });

    const words = categoriesCopy[0].description.split(" ");
    let messageText = "";

    const typingInterval = setInterval(() => {
      messageText += (messageText ? " " : "") + words.shift();
      setTypingMessage((prevMessage) => ({
        ...prevMessage,
        text: messageText,
      }));

      // If all words have been added, add the message to the conversation and stop the interval
      if (words.length === 0) {
        clearInterval(typingInterval);
        setConversation([
          {
            id: 1,
            from: "chatGPT",
            text: categoriesCopy[categoryIndex].title,
            description: categoriesCopy[categoryIndex].description,
            phase: phase,
            idea: false,
          },
        ]);
        setTypingStarted(false);
        setTypingMessage("");
      }
    }, 50);
  };

  const rateCategory = async () => {
    setConversationLength(conversation.length + 1);
    const sentMessage = message;
    setIndex(index + 1);
    setMessage("");

    if (tie) {
      const response = await dispatch(
        sendMessage({
          message_type: "top_category",
          question: questionId,
          category: topCategory.id,
          sender_type: "U",
          sender: user.user_id,
        })
      );
      setIdeaPhase(true);
      setTie(false);
      setScore(0);
      setStop(response?.stop);
      setProgress(response?.progress);
      setMessage("No text feedback collected");
      if (response?.ideas) {
        setIdeaBatch(response?.ideas);
      }

      // setTypingMessage({
      //   id: conversation.length + 2, // This should match the ID of the message that will eventually be added
      //   from: "chatGPT",
      //   text: "",
      // });

      // const words = response?.text.split(" ");
      // let wordIndex = 0;

      // const typingInterval = setInterval(() => {
      //   wordIndex++;
      //   setTypingMessage((prevMessage) => ({
      //     ...prevMessage,
      //     text:
      //       prevMessage.text +
      //       (prevMessage.text ? " " : "") +
      //       words[wordIndex - 1],
      //   }));

      //   // If all words have been added, add the message to the conversation and stop the interval
      //   if (wordIndex >= words?.length) {
      //     clearInterval(typingInterval);
      //     setConversation((prevConversation) => [
      //       ...prevConversation,
      //       {
      //         id: prevConversation.length + 1,
      //         from: "chatGPT",
      //         text: response?.text,
      //         phase: response?.phase,
      //         description: "",
      //         idea: true,
      //       },
      //     ]);
      //     setTypingMessage(null);
      //   }
      // }, 50);
    } else {
      const response = await dispatch(
        sendMessage({
          message_type: "category",
          text: sentMessage,
          question: questionId,
          category: categoriesCopy[categoryIndex].category_id,
          sender_type: "U",
          sender: user.user_id,
          rating: score,
          related: relevancy,
          last_category: categoryIndex === categoriesCopy.length - 1,
        })
      );

      setScore(-1);
      setRelevancy(-1);
      setProgress(50);

      // Start a new typing animation for the next category if it exists
      if (categoryIndex < categoriesCopy.length - 1) {
        setProgress(((categoryIndex + 1) / categoriesCopy.length) * 50);
        setTypingMessage({
          id: conversation.length + 1,
          from: "Tacit AI",
        });

        const words = categoriesCopy[categoryIndex + 1].description.split(" ");
        let messageText = "";

        const typingInterval = setInterval(() => {
          messageText += (messageText ? " " : "") + words.shift();
          setTypingMessage((prevMessage) => ({
            ...prevMessage,
            text: messageText,
          }));

          // If all words have been added, add the message to the conversation and stop the interval
          if (words.length === 0) {
            clearInterval(typingInterval);
            setConversation([
              ...conversation,
              {
                id: conversation.length + 1,
                from: "chatGPT",
                text: categoriesCopy[categoryIndex + 1].title,
                description: categoriesCopy[categoryIndex + 1].description,
                phase: phase,
                idea: false,
              },
            ]);
            setTypingStarted(false);
            setTypingMessage("");
          }
        }, 50);
        setCategoryIndex(categoryIndex + 1);
      } else {
        if (response?.categories) {
          setCurrentHighestCategories(response?.categories);
          setTie(true);
          setMessage("No text feedback collected");
        } else {
          setProgress(75);
          setIdeaPhase(true);
          setScore(0);
          setTie(false);

          // The response was an idea so we can start idea phase
          setStop(response?.stop);
          setProgress(response?.progress);
          setMessage("No text feedback collected");
          if (response?.ideas) {
            setIdeaBatch(response?.ideas);
          }

          // setTypingMessage({
          //   id: conversation.length + 2, // This should match the ID of the message that will eventually be added
          //   from: "chatGPT",
          //   text: "",
          // });

          // const words = response?.text?.split(" ");
          // let wordIndex = 0;

          // const typingInterval = setInterval(() => {
          //   wordIndex++;
          //   setTypingMessage((prevMessage) => ({
          //     ...prevMessage,
          //     text:
          //       prevMessage?.text +
          //       (prevMessage?.text ? " " : "") +
          //       words[wordIndex - 1],
          //   }));

          //   // If all words have been added, add the message to the conversation and stop the interval
          //   if (wordIndex >= words?.length) {
          //     clearInterval(typingInterval);
          //     setConversation((prevConversation) => [
          //       ...prevConversation,
          //       {
          //         id: prevConversation.length + 1,
          //         from: "chatGPT",
          //         text: response?.text,
          //         phase: response?.phase,
          //         description: "",
          //         idea: true,
          //       },
          //     ]);
          //     setTypingMessage(null);
          //   }
          // }, 50);
        }
      }
    }
  };

  const rateIdea = async () => {
    if (ideaPhase) {
      const response = await dispatch(
        sendMessage({
          message_type: "idea_batch",
          question: questionId,
          sender_type: "U",
          sender: user.user_id,
          idea_batch: ideaBatch,
        })
      );
      setIdeaPhase(true);
      setTie(false);
      setScore(0);
      setStop(response?.stop);
      setProgress(response?.progress);
    }
    // setConversationLength(conversation.length + 1);
    // const sentMessage = message;
    // setIndex(index + 1);
    // setMessage("");

    // const response = await dispatch(
    //   sendMessage({
    //     message_type: "idea",
    //     text: sentMessage,
    //     question: questionId,
    //     sender_type: "U",
    //     sender: user.user_id,
    //     rating: score,
    //     related: relevancy,
    //   })
    // );

    // setScore(-1);
    // setRelevancy(-1);

    // setStop(response?.stop);
    // setProgress(response?.progress);

    // setTypingMessage({
    //   id: conversation.length + 2, // This should match the ID of the message that will eventually be added
    //   from: "chatGPT",
    //   text: "",
    // });

    // const words = response?.text.split(" ");
    // let wordIndex = 0;

    // const typingInterval = setInterval(() => {
    //   wordIndex++;
    //   setTypingMessage((prevMessage) => ({
    //     ...prevMessage,
    //     text:
    //       prevMessage.text +
    //       (prevMessage.text ? " " : "") +
    //       words[wordIndex - 1],
    //   }));

    //   // If all words have been added, add the message to the conversation and stop the interval
    //   if (wordIndex >= words?.length) {
    //     clearInterval(typingInterval);
    //     setConversation((prevConversation) => [
    //       ...prevConversation,
    //       {
    //         id: prevConversation.length + 1,
    //         from: "chatGPT",
    //         text: response?.text,
    //         phase: response?.phase,
    //         description: "",
    //         idea: true,
    //       },
    //     ]);
    //     setTypingMessage(null);
    //   }
    // }, 50);
  };

  // This is to send a message where the response is displayed
  const handleSendMessage = async () => {
    if (!ideaPhase) {
      rateCategory();
    } else {
      rateIdea();
    }
  };

  const endQuestion = () => {
    if (currentQuestionIndex < numQuestions - 1) {
      setNextQuestion();
      setStop(false);
    } else {
      console.log("Results: ", results);
      navigate("/finished", { state: { results: results } });
    }
  };

  let UpperLevel;
  if (mdUp) {
    UpperLevel = Container;
  } else {
    UpperLevel = Stack;
  }

  useEffect(() => {
    console.log(ideaBatch);
  }, [ideaBatch]);

  return (
    <UpperLevel mb={!mdUp ? 7 : null}>
      <Container
        sx={{ position: "sticky", top: 60, zIndex: 1000, bgcolor: "white" }}
      >
        <InstructionsAlert
          instructionsOpen={instructionsOpen}
          setInstructionsOpen={setInstructionsOpen}
        />
        <Stack direction="column" pb={mdUp ? 2 : 1}>
          {/* <Typography variant="h5">
            Topic {order}: {question}
          </Typography> */}

          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <ProgressBar
              title={"Rating Progress"}
              currentProgress={progress}
              color={"primary"}
            />

            {!stop ? (
              <AlertDialog
                openAlert={stop}
                title={
                  currentQuestionIndex < numQuestions - 1
                    ? "Go to next question?"
                    : "End conversation"
                }
                description="Rating question will end automatically. Confirm to move on."
                buttonText={mdUp ? "End Question" : "End"}
                onConfirm={endQuestion}
                questionId={questionId}
                userId={user.user_id}
                // ratingQuestion1={ratingQuestion1}
                // ratingQuestion2={ratingQuestion2}
                ratingQuestion3={ratingQuestion3}
                // ratingQuestion4={ratingQuestion4}
                // ratingQuestion5={ratingQuestion5}
                skipped={true}
              />
            ) : null}
          </Stack>
        </Stack>
      </Container>
      {mdUp && (
        <Stack pb={1}>
          <Divider color={theme.palette.grey[600]} />
        </Stack>
      )}

      <Box pb={mdUp ? 10 : 0}>
        {conversation.map(({ id, from, text, description, phase, idea }) => (
          <Box key={id} mb={1}>
            <ReviewItem
              name={from === "user" ? "You" : "Tacit AI"}
              question={question}
              message={text}
              description={description}
              username={username}
              phase={phase}
              score={score}
              setScore={setScore}
              setRelevancy={setRelevancy}
              last={id === conversationLength}
              stop={stop}
              idea={idea}
            />
          </Box>
        ))}

        {typingMessage && (
          <Box key={typingMessage.id}>
            {!ideaPhase ? (
              <ReviewItem
                name={typingMessage.from === "user" ? "You" : "Tacit AI"}
                question={question}
                last={true}
                message={categoriesCopy[categoryIndex].title}
                description={typingMessage.text}
                username={username}
                phase={typingMessage.phase}
                score={score}
                setScore={setScore}
                setRelevancy={setRelevancy}
                idea={false}
              />
            ) : (
              <ReviewItem
                name={typingMessage.from === "user" ? "You" : "Tacit AI"}
                question={question}
                last={true}
                message={typingMessage.text}
                username={username}
                score={score}
                setScore={setScore}
                setRelevancy={setRelevancy}
                idea={true}
              />
            )}
          </Box>
        )}

        {tie && (
          <TieBreaker
            name={"Tacit AI"}
            question={question}
            categories={currentHighestCategories}
            username={username}
            topCategory={topCategory}
            setTopCategory={setTopCategory}
          />
        )}
        {/* {ideaPhase &&
          ideaBatch?.map((idea) => (
            <Typography variant="h4" sx={{ mt: 2 }}>
              {idea.idea}
            </Typography>
          ))} */}
        {ideaPhase && <IdeasRanker items={ideaBatch} setItems={setIdeaBatch} />}
        {/* {ideaPhase && (
          <IdeaBoxRanker
            ideaBatch={ideaBatch}
            setIdeaBatch={setIdeaBatch}
            name={"Tacit AI"}
            question={question}
            username={username}
          />
        )} */}
      </Box>
      {isLoadingMessages && <CircularProgress />}
      <div ref={messagesEndRef} />

      {stop ? null : (
        <Stack
          direction="row"
          pt={1}
          pb={mdUp ? 4 : 1}
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          bgcolor="white"
        >
          <Container>
            <Stack direction="row" alignItems="center">
              <TextField
                variant="outlined"
                size="small"
                multiline
                maxRows={3}
                value={message.slice(0, 750)}
                disabled={stop || tie || ideaPhase}
                placeholder={
                  phase == 2 ? "Additional thoughts? (optional)" : null
                }
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey && !stop) {
                    e.preventDefault(); // prevents the addition of a new line when enter is pressed
                    handleSendMessage();
                  }
                }}
                fullWidth
                inputProps={{
                  style: { fontSize: 16 },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendMessage}
                style={{
                  marginLeft: "10px",
                  height: "40px",
                }}
                disabled={
                  stop ||
                  isLoadingMessages ||
                  (message.length === 0 && phase === 1) ||
                  (score == 1 && relevancy == -1) ||
                  (score == -1 && !tie) ||
                  (tie && !topCategory)
                }
              >
                {/* <ArrowUpwardIcon /> */}
                {ideaPhase ? "Submit" : "Reply"}
              </Button>
            </Stack>
          </Container>
        </Stack>
      )}

      {stop ? (
        <AlertDialog
          openAlert={stop}
          title={
            currentQuestionIndex < numQuestions - 1
              ? "Go to next question?"
              : "End conversation"
          }
          // description="Please rate the question before moving on."
          description="Thank you for finishing the question. Confirm to move on."
          buttonText={mdUp ? "Next Question" : "Next"}
          onConfirm={endQuestion}
          questionId={questionId}
          userId={user.user_id}
          skipped={false}
        />
      ) : null}
    </UpperLevel>
  );
};

export default Phase2ChatBox;
// ----------------------------------------------------------------------

function IdeaBoxRanker({
  name,
  question,
  message,
  description,
  username,
  ideaBatch,
  setIdeaBatch,
}) {
  const theme = useTheme();
  const mdUp = useResponsive("up", "md");
  const { control, reset } = useForm();

  return (
    <Stack
      direction={mdUp ? "row" : "column"}
      spacing={2}
      alignItems={mdUp ? "center" : null}
      sx={{
        borderRadius: 2,
        py: 4,
        p: 3,
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        bgcolor: name === "Tacit AI" ? "warning.light" : "#CAF0F8",
      }}
    >
      {mdUp ? (
        <Avatar
          alt={username}
          src={
            name === "Tacit AI" ? "/assets/images/avatar/group.png" : "username"
          }
          sx={{
            width: 64,
            height: 64,
            mr: 2.5,
            bgcolor: name != "Tacit AI" ? theme.palette.primary.darker : null,
            color: "#ffffff",
            border: name === "Tacit AI" ? "2px solid #000000" : null,
          }}
        />
      ) : null}
      <Stack direction="column">
        <Stack>
          {!mdUp ? <Typography variant="h6">{name}</Typography> : null}

          <Typography variant="h5" color="darkgray">
            Question
          </Typography>
          <Typography variant="h4" color="gray">
            {question}
          </Typography>

          <Typography variant="h4" sx={{ mt: 2 }}>
            Rank the following ideas. 1 = Best
          </Typography>
          <IdeasRanker items={ideaBatch} setItems={setIdeaBatch} />
        </Stack>
      </Stack>
    </Stack>
  );
}

function TieBreaker({
  name,
  question,
  message,
  description,
  username,
  categories,
  topCategory,
  setTopCategory,
}) {
  const theme = useTheme();
  const mdUp = useResponsive("up", "md");
  const { control, reset } = useForm();

  const handleCategorySelect = (category) => {
    setTopCategory(category);
  };

  return (
    <Stack
      direction={mdUp ? "row" : "column"}
      spacing={2}
      alignItems={mdUp ? "center" : null}
      sx={{
        borderRadius: 2,
        py: 4,
        p: 3,
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        bgcolor: "grey.200",
      }}
    >
      {mdUp ? (
        <Avatar
          alt={username}
          src={
            name === "Tacit AI" ? "/assets/images/avatar/group.png" : "username"
          }
          sx={{
            width: 64,
            height: 64,
            mr: 2.5,
            bgcolor: name != "Tacit AI" ? theme.palette.primary.darker : null,
            color: "#ffffff",
            border: name === "Tacit AI" ? "2px solid #000000" : null,
          }}
        />
      ) : null}
      <Stack direction="column">
        <Stack>
          {!mdUp ? <Typography variant="h6">{name}</Typography> : null}

          <Typography variant="h5" color="darkgray">
            Question
          </Typography>
          <Typography variant="h4" color="gray">
            {question}
          </Typography>

          <Typography variant="h4" sx={{ mt: 2 }}>
            Pick your top answer
          </Typography>
          {categories.map((category) => (
            <Button
              key={category.id}
              variant={topCategory === category ? "contained" : "outlined"}
              color={"primary"}
              onClick={() => handleCategorySelect(category)}
              sx={{ mt: 1 }}
            >
              <Typography variant="h5">{category.title}</Typography>
            </Button>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

function ValueLabelComponent(value) {
  const labels = [
    "Strongly Disagree",
    "Disagree",
    "Neutral",
    "Agree",
    "Strongly Agree",
  ];

  const labels2 = [
    "Very Low Quality",
    "Low Quality",
    "Moderate Quality",
    "High Quality",
    "Very High Quality",
  ];

  return (
    <Stack alignItems="center">
      <div>{labels[value - 1]}</div>
      <div>{labels2[value - 1]}</div>
    </Stack>
  );
}

function ReviewItem({
  name,
  question,
  message,
  description,
  username,
  phase,
  score,
  setScore,
  setRelevancy,
  last,
  stop,
  idea,
}) {
  const theme = useTheme();
  const mdUp = useResponsive("up", "md");
  const { control, reset } = useForm();
  const [yesClicked, setYesClicked] = useState(false);
  const [noClicked, setNoClicked] = useState(false);

  const labels = [
    "Strongly Disagree /\n Very Low Quality",
    "Disagree / Low Quality ",
    "Neutral / Moderate Quality",
    "Agree / High Quality",
    "Strongly Agree / Very High Quality",
  ];

  const valueLabelFormat = (value) => {
    return labels[value - 1];
  };

  const marks = labels.map((label, index) => ({
    value: index,
    label: label,
  }));

  return (
    <Stack
      direction={mdUp ? "row" : "column"}
      spacing={2}
      alignItems={mdUp ? "center" : null}
      sx={{
        borderRadius: 2,
        py: 4,
        p: 3,
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        bgcolor: "grey.200",
      }}
    >
      {mdUp ? (
        <Avatar
          alt={username}
          src={
            name === "Tacit AI" ? "/assets/images/avatar/group.png" : "username"
          }
          sx={{
            width: 64,
            height: 64,
            mr: 2.5,
            bgcolor: name != "Tacit AI" ? theme.palette.primary.darker : null,
            color: "#ffffff",
            border: name === "Tacit AI" ? "2px solid #000000" : null,
          }}
        />
      ) : null}
      <Stack direction="column">
        <Stack>
          {!mdUp ? <Typography variant="h6">{name}</Typography> : null}

          {last && (
            <>
              {" "}
              <Typography variant="h5" color="darkgray">
                Question
              </Typography>
              <Typography variant="h4" color="gray">
                {question}
              </Typography>
            </>
          )}
          {!idea ? (
            <>
              {" "}
              <Typography
                variant="h4"
                sx={{ mt: last ? 2 : 0 }}
                color="primary"
              >
                {message}
              </Typography>
              <Typography sx={{}}>{description}</Typography>
            </>
          ) : (
            <Typography sx={{ mt: last ? 2 : 0 }}>{message}</Typography>
          )}
        </Stack>

        {name === "Tacit AI" && !stop && last && (
          <Stack
            spacing={1}
            direction="column"
            alignItems="right"
            sx={{ pt: 2 }}
          >
            {!idea ? (
              <Typography variant="subtitle2" gutterBottom>
                Rate the quality of this solution:
              </Typography>
            ) : (
              <Typography variant="subtitle2" gutterBottom>
                Your evaluation of this idea:
              </Typography>
            )}

            <Slider
              track={false}
              value={score}
              step={1}
              min={1}
              max={5}
              marks
              valueLabelDisplay="auto"
              valueLabelFormat={(value, index) => ValueLabelComponent(value)}
              onChange={(event, newValue) => {
                setScore(newValue);
                setYesClicked(false);
                setNoClicked(false);
                setRelevancy(-1);
              }}
              sx={{
                width: "200px",
                color: "black",
                ml: { xs: 5, md: 0 },
              }}
            />

            {/* Relevancy Question Section */}
            {score === 1 && (
              <Stack direction="column" spacing={1}>
                {description ? (
                  <Typography variant="subtitle2">
                    Is the solution relevant to the question?
                  </Typography>
                ) : (
                  <Typography variant="subtitle2">
                    Is this idea relevant to the question?
                  </Typography>
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Button
                    size="small"
                    color="inherit"
                    onClick={() => {
                      setYesClicked(true);
                      setNoClicked(false);
                      setRelevancy(1);
                    }}
                    startIcon={
                      <Iconify
                        icon={
                          yesClicked
                            ? "carbon:thumbs-up-filled"
                            : "carbon:thumbs-up"
                        }
                      />
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    size="small"
                    color="inherit"
                    onClick={() => {
                      setNoClicked(true);
                      setYesClicked(false);
                      setRelevancy(0);
                      reset();
                    }}
                    startIcon={
                      <Iconify
                        icon={
                          noClicked
                            ? "carbon:thumbs-down-filled"
                            : "carbon:thumbs-down"
                        }
                      />
                    }
                  >
                    No
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
