import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Iconify from "src/components/iconify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Icon, Snackbar } from "@mui/material";
import { useState } from "react";
import { ButtonBase } from "@mui/material";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  changeConversationStatus,
  changeConversationRejoin,
  getCreatedConversation,
  getCreatedConversations,
  deleteAccountConversation,
} from "src/actions/accountActions";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import { useTheme } from "@mui/material/styles";
import {
  addAcountToConversation,
  removeAcountFromConversation,
  removeSelfFromConversation,
} from "src/actions/accountActions";

import { tacitAnalysis, removeUserTask } from "src/actions/conversationActions";

import { add } from "date-fns";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { CircularProgress } from "@mui/material";
import ConversationTimeline from "./account-conversation-timeline";
import { useResponsive } from "src/hooks/use-responsive";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoTooltip from "src/components/dashboard/info-tooltip";
import { set } from "nprogress";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const AccountConversation = () => {
  const mdUp = useResponsive("up", "md");
  const now = dayjs();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { conversation } = useSelector((state) => state.accountData);
  const { conversationId } = useParams();
  const [openDelete, setOpenDelete] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [addEmail, setAddEmail] = useState("");
  const [removeEmail, setRemoveEmail] = useState("");
  const [justRanDataAnalysis, setJustRanDataAnalysis] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openAnalysisAlert, setOpenAnalysisAlert] = useState(false);

  // Warnings for Tacit Analysis

  const handleCopyToClipboard = async () => {
    const link = `${process.env.REACT_APP_URL}/questions/${createdConversation?.conversation?.conversation_unique_id}`;
    try {
      await navigator.clipboard.writeText(link);
      setSnackbarMessage("Link copied to clipboard!");
      setOpenSnackbar(true);
    } catch (err) {
      setSnackbarMessage("Failed to copy link.");
      setOpenSnackbar(true);
    }
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpenComplete = () => {
    setOpenComplete(true);
  };

  const handleCloseComplete = () => {
    setOpenComplete(false);
  };

  const handleConfirmDelete = async () => {
    setOpenDelete(false);
    await dispatch(deleteAccountConversation(conversationId));
    dispatch(getCreatedConversations());
    navigate(-1);
  };

  const handleConfirmRemoveSelfFromConversation = async () => {
    await dispatch(removeSelfFromConversation(conversationId));
    dispatch(getCreatedConversations());
    navigate(-1);
  };

  const {
    isLoadingCreatedConversations,
    isLoadingConversation,
    isLoadingAddAccount,
    isLoadingRemoveAccount,
    createdConversations,
    createdConversation,
  } = useSelector((state) => state.accountData);

  const [currentStatus, setCurrentStatus] = useState(
    createdConversation?.conversation?.status || ""
  );
  const [currentRejoin, setCurrentRejoin] = useState(
    createdConversation?.conversation?.rejoin || ""
  );
  const [hasStatusChanged, setHasStatusChanged] = useState(false);
  const [hasRejoinChanged, setHasRejoinChanged] = useState(false);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    setCurrentStatus(createdConversation?.conversation?.status || "");
    setCurrentRejoin(createdConversation?.conversation?.allow_rejoin);

    setEmails(createdConversation?.emails || []);
  }, [
    createdConversation?.conversation?.status,
    createdConversation?.emails,
    createdConversation?.conversation?.allow_rejoin,
  ]);

  const handleStatusChange = (newStatus) => {
    setCurrentStatus(newStatus);
    setHasStatusChanged(
      createdConversation?.conversation?.status !== newStatus
    );
  };

  const handleRejoinChange = (newRejoin) => {
    setCurrentRejoin(newRejoin);
    setHasRejoinChanged(
      createdConversation?.conversation?.allow_rejoin !== newRejoin
    );
  };

  const handleConfirmChange = async () => {
    if (hasStatusChanged) {
      await dispatch(changeConversationStatus(conversationId, currentStatus));
    }
    if (hasRejoinChanged) {
      await dispatch(changeConversationRejoin(conversationId, currentRejoin));
    }

    await dispatch(getCreatedConversations());
    dispatch(getCreatedConversation(conversationId));
    setHasStatusChanged(false);
    setHasRejoinChanged(false);
    if (openComplete) {
      setOpenComplete(false);
    }
  };

  useEffect(() => {
    dispatch(getCreatedConversation(conversationId));
  }, [conversationId]);

  const labels = ["Zero", "Minimal", "Balanced", "Thorough", "Comprehensive"];

  const date = new Date(createdConversation?.last_activity);

  const analysisEndEvent = createdConversation?.events?.find(
    (event) => event.event === "Analysis End"
  );
  const analysisEndDate = new Date(analysisEndEvent?.time);

  const localDate = date.toLocaleString();
  const localAnalysisEndDate = analysisEndDate.toLocaleString();

  const analysisStartEvent = createdConversation?.events?.find(
    (event) => event.event === "Analysis Start"
  );

  const analysisStartDate = new Date(analysisStartEvent?.time);
  const localAnalysisStartDate = analysisStartDate.toLocaleString();

  const conversationCompleteEvent = createdConversation?.events?.find(
    (event) => event.event === "Completed"
  );
  const conversationCompleteDate = new Date(conversationCompleteEvent?.time);

  const localConversationCompleteDate =
    conversationCompleteDate.toLocaleString();

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddAccount = async () => {
    const data = await dispatch(
      addAcountToConversation({
        email: addEmail,
        conversation_id: conversationId,
      })
    );
    if (data && data["message"]) {
      setEmails([...emails, addEmail]);
      setAddEmail("");
    }
  };

  const handleRemoveAccount = async () => {
    const data = await dispatch(
      removeAcountFromConversation({
        email: removeEmail,
        conversation_id: conversationId,
      })
    );
    if (data && data["message"]) {
      setEmails(emails.filter((email) => email !== removeEmail));
      setRemoveEmail("");
    }
  };

  const EmailList = ({ emails }) => {
    return (
      <List>
        {emails.map((email, index) => (
          <div key={index}>
            <ListItem>
              <ListItemText primary={email} />
            </ListItem>
            {index !== emails.length - 1 && <Divider />}
          </div>
        ))}
      </List>
    );
  };

  const navigateToUrl = () => {
    const url = `${process.env.REACT_APP_URL}/results/${createdConversation?.conversationResultsId}`;
    window.open(url, "_blank");
  };

  const copyToClipboard = () => {
    const link = `${process.env.REACT_APP_URL}/questions/${createdConversation?.conversation?.conversation_unique_id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        // Handle success, maybe show a toast notification
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        // Handle error
        console.error("Failed to copy: ", err);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleAnalysis = async () => {
    setCurrentStatus("analysis");
    setJustRanDataAnalysis(true);
    // await dispatch(changeConversationStatus(conversationId, "analysis"));
    dispatch(tacitAnalysis(conversationId));
    // await dispatch(getCreatedConversations());
    // dispatch(getCreatedConversation(conversationId));
    setOpenAnalysisAlert(false);
  };

  const removeTimeTask = async (name) => {
    const conversationEvent = createdConversation?.events?.find(
      (event) => event.event === name
    );
    const conversationEventId = conversationEvent?.event_id;
    console.log(conversationEventId);
    dispatch(removeUserTask(conversationEventId));
    dispatch(getCreatedConversation(conversationId));
  };

  return (
    <Container>
      <Stack>
        <Button
          onClick={handleBack}
          sx={{
            width: "40px", // or any specific width you want
            height: "40px", // or any specific height you want
          }}
        >
          <ArrowBackIcon />
        </Button>
      </Stack>
      <Stack
        spacing={3}
        sx={{
          p: 5,
          borderRadius: 2,
          border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h4">
          {createdConversation?.conversation?.title}
        </Typography>

        <Stack direction="column" spacing={1} alignItems="flex-start">
          <Button
            onClick={navigateToUrl}
            variant="outlined"
            startIcon={<Iconify icon="carbon:result" />}
            sx={{ width: 290, justifyContent: "flex-start" }} // Added justifyContent here
          >
            <Typography variant="h6">Explore Results Dashboard</Typography>
          </Button>
          <Stack direction="row">
            <Button
              variant="outlined"
              onClick={handleCopyToClipboard}
              startIcon={<Iconify icon="carbon:share" />}
              sx={{ width: 290, justifyContent: "flex-start" }} // Added justifyContent here
            >
              <Typography variant="h6" sx={{ mr: 4 }}>
                Participant Invite Link
              </Typography>
              <InfoTooltip
                info={
                  "Click to copy your conversation's unique invite link, then just paste into whatever communication channel you are using to reach your Participants (email, slack, text, etc.)"
                }
              />
            </Button>
          </Stack>
        </Stack>

        {/* <LineItem
          icon="carbon:share"
          label="Share Link"
          value={
            process.env.REACT_APP_URL +
            "/questions/" +
            createdConversation?.conversation?.conversation_unique_id
          }
          copyable
        />
        <LineItem
          icon="carbon:result"
          label="Results Link"
          value={
            process.env.REACT_APP_URL +
            "/results/" +
            createdConversation?.conversationResultsId
          }
          copyable
        />
        <LineItem
          icon="carbon:calendar"
          label="Creation Date"
          value={localDate}
        /> */}
        <LineItem
          icon="carbon:cube"
          label="Number of Questions"
          value={
            createdConversation?.questions?.length?.toString() + " questions"
          }
        />
        <LineItem
          icon="carbon:cics-transaction-server-zos"
          label="Status"
          value={
            justRanDataAnalysis
              ? "Analysis"
              : capitalizeFirstLetter(
                  createdConversation?.conversation?.status
                ).toString()
          }
        />

        <LineItem
          icon="carbon:events"
          label="Number of Participants"
          value={createdConversation?.user_count?.toString() + " participants"}
        />
        <LineItem
          icon="carbon:calendar"
          label="Most Recent Activity"
          value={localDate}
        />
        {/* You can continue with other LineItems for other details from the data */}

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">Description and Questions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Typography variant="h5" sx={{ mb: 2, mt: 1 }}>
              {createdConversation?.conversation?.description}
            </Typography>
            {createdConversation?.questions?.map((question, index) => (
              <React.Fragment key={question.question_id}>
                <Typography variant="h7" sx={{ pb: 2 }}>
                  <span style={{ fontWeight: "bold" }}>
                    Question {index + 1}:
                  </span>{" "}
                  {question?.question}
                </Typography>
                <br />
                <br />
              </React.Fragment>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">Edit Conversation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">Conversation Status</Typography>
              {localConversationCompleteDate &&
                conversationCompleteDate > now &&
                !justRanDataAnalysis &&
                createdConversation.conversation.status != "complete" && (
                  <Stack direction={"column"} spacing={1} sx={{ mb: 2 }}>
                    <Typography variant="h7">
                      Conversation will be automically set to complete at{" "}
                      {localConversationCompleteDate}
                    </Typography>
                    <Button
                      color="error"
                      sx={{ maxWidth: 80 }}
                      variant="contained"
                      onClick={() => removeTimeTask("Completed")}
                    >
                      Cancel
                    </Button>
                  </Stack>
                )}

              <Typography variant="h7">Change Conversation Status</Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  variant={
                    currentStatus === "active" ? "contained" : "outlined"
                  }
                  color="success"
                  onClick={() => handleStatusChange("active")}
                  disabled={
                    createdConversation?.conversation?.status === "analysis" ||
                    justRanDataAnalysis
                  }
                  sx={{ width: 80 }}
                >
                  Active
                </Button>

                <Button
                  variant={currentStatus === "pause" ? "contained" : "outlined"}
                  color="warning"
                  onClick={() => handleStatusChange("pause")}
                  disabled={
                    createdConversation?.conversation?.status === "analysis" ||
                    justRanDataAnalysis
                  }
                  sx={{ width: 80 }}
                >
                  Pause
                </Button>
                <Button
                  variant={
                    currentStatus === "complete" ? "contained" : "outlined"
                  }
                  color="primary"
                  onClick={() => handleStatusChange("complete")}
                  disabled={
                    createdConversation?.conversation?.status === "analysis" ||
                    justRanDataAnalysis
                  }
                  sx={{ width: 80 }}
                >
                  Complete
                </Button>
                <Button
                  variant={
                    currentStatus === "analysis" ? "contained" : "outlined"
                  }
                  color="inherit"
                  sx={{
                    pointerEvents: "none", // Prevents clicking
                    "&:hover": {
                      // Your normal state styles go here to remove hover effect
                      // For example, if it's an outlined button:
                      bgcolor: "transparent", // for contained buttons, use your normal background color
                      borderColor: "currentColor", // if your outlined button has a border color
                      color: "inherit", // adjust text color if needed
                      width: 80,
                    },
                  }}
                >
                  Analysis
                </Button>
              </Stack>
              {createdConversation?.conversation?.phase_2 &&
                createdConversation?.conversation?.data_analysis_complete && (
                  <>
                    <Typography variant="h6">Change Rejoin</Typography>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Button
                        variant={currentRejoin ? "contained" : "outlined"}
                        onClick={() => handleRejoinChange(true)}
                        color="success"
                        sx={{ width: 80 }}
                        disabled={
                          createdConversation?.conversation?.status ===
                            "analysis" || justRanDataAnalysis
                        }
                      >
                        On
                      </Button>
                      <Button
                        variant={!currentRejoin ? "contained" : "outlined"}
                        onClick={() => handleRejoinChange(false)}
                        color="primary"
                        sx={{ width: 80 }}
                        disabled={
                          createdConversation?.conversation?.status ===
                            "analysis" || justRanDataAnalysis
                        }
                      >
                        Off
                      </Button>
                    </Stack>
                  </>
                )}
              <Button
                variant="contained"
                color="secondary"
                onClick={
                  currentStatus === "complete"
                    ? handleClickOpenComplete
                    : handleConfirmChange
                }
                disabled={!hasStatusChanged && !hasRejoinChanged}
                sx={{ maxWidth: 200 }}
              >
                Confirm Changes
              </Button>
              <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
                <Typography variant="h6">Tacit Analysis</Typography>
                {localAnalysisEndDate &&
                  createdConversation?.conversation?.status != "analysis" &&
                  createdConversation?.conversation?.data_analysis_complete && (
                    <Typography variant="h7">
                      Tacit Analysis last completed {localAnalysisEndDate}
                    </Typography>
                  )}
                {localAnalysisStartDate &&
                  analysisStartDate > now &&
                  createdConversation?.conversation?.status != "analysis" &&
                  !justRanDataAnalysis && (
                    <Stack direction={"column"} spacing={1} sx={{ mb: 2 }}>
                      <Typography variant="h7">
                        Tacit Analysis will automically run:{" "}
                        {localAnalysisStartDate}
                      </Typography>
                      <Button
                        color="error"
                        sx={{ maxWidth: 80 }}
                        variant="contained"
                        onClick={() => removeTimeTask("Analysis Start")}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  )}
                <Typography variant="h7">
                  Manually Run Tacit Analysis
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setOpenAnalysisAlert(true)}
                  sx={{ maxWidth: 200 }}
                  disabled={
                    createdConversation?.conversation?.status === "analysis" ||
                    justRanDataAnalysis
                  }
                >
                  Run Tacit Analysis
                  {createdConversation?.conversation?.phase_2 ? (
                    <InfoTooltip
                      info={
                        "Tacit Analysis will generate categories from the participant conversations and classify ideas."
                      }
                    />
                  ) : (
                    <InfoTooltip
                      info={
                        "Tacit Analysis will generate categories from the participant conversations and classify ideas. Once complete Collective Validation will begin."
                      }
                    />
                  )}
                </Button>
              </Stack>
              <Stack direction="column" spacing={1} sx={{ mt: 3 }}>
                <Typography variant="h6">Add Moderator Account</Typography>
                <InputBase
                  fullWidth
                  placeholder="Enter Email"
                  onChange={(event) => setAddEmail(event.target.value)}
                  value={addEmail}
                  // disabled={errorMessage !== ""}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        type="submit"
                        onClick={handleAddAccount}
                        sx={{ width: 80 }}
                        // disabled={errorMessage !== ""}
                      >
                        Add
                      </Button>
                    </InputAdornment>
                  }
                  inputProps={{ style: { fontSize: 16 } }} // font size of input text
                  sx={{
                    pr: 0.5,
                    pl: 1.5,
                    height: 56,
                    maxWidth: 560,
                    borderRadius: 1,

                    bgcolor: theme.palette.grey[200],
                    transition: (theme) =>
                      theme.transitions.create(["box-shadow"]),
                    [`&.${inputBaseClasses.focused}`]: {
                      boxShadow: (theme) => theme.customShadows.z4,
                    },
                  }}
                />
                {isLoadingAddAccount && <CircularProgress />}
                <EmailList emails={emails} />

                {createdConversation?.owner ? (
                  <>
                    <Typography variant="h6">
                      Remove Moderator Account
                    </Typography>
                    <InputBase
                      fullWidth
                      placeholder="Enter Email"
                      onChange={(event) => setRemoveEmail(event.target.value)}
                      value={removeEmail}
                      // disabled={errorMessage !== ""}
                      endAdornment={
                        <InputAdornment position="end">
                          <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            type="submit"
                            onClick={handleRemoveAccount}
                            sx={{ width: 80 }}
                            // disabled={errorMessage !== ""}
                          >
                            Remove
                          </Button>
                        </InputAdornment>
                      }
                      inputProps={{ style: { fontSize: 16 } }} // font size of input text
                      sx={{
                        pr: 0.5,
                        pl: 1.5,
                        height: 56,
                        maxWidth: 560,
                        borderRadius: 1,

                        bgcolor: theme.palette.grey[200],
                        transition: (theme) =>
                          theme.transitions.create(["box-shadow"]),
                        [`&.${inputBaseClasses.focused}`]: {
                          boxShadow: (theme) => theme.customShadows.z4,
                        },
                      }}
                    />
                    {isLoadingRemoveAccount && <CircularProgress />}
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleClickOpenDelete}
                      sx={{ marginTop: 3, maxWidth: 200 }}
                    >
                      Delete Conversation
                    </Button>

                    <Dialog
                      open={openDelete}
                      onClose={handleCloseDelete}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        Are you sure you want to delete the conversation?
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Confirming will permanently remove this conversation
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDelete} autoFocus>
                          Cancel
                        </Button>
                        <Button onClick={handleConfirmDelete}>Confirm</Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <>
                    <Typography variant="h6">
                      You are not the owner of this conversation. Remove
                      Yourself From Conversation
                    </Typography>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleConfirmRemoveSelfFromConversation}
                      sx={{ maxWidth: 200 }}
                    >
                      Remove Self
                    </Button>
                  </>
                )}
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
      <Dialog
        open={openComplete}
        onClose={handleCloseComplete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to set the conversation to complete?
        </DialogTitle>
        <DialogContent>
          {!createdConversation?.conversation?.data_analysis_complete && (
            <DialogContentText id="alert-dialog-description">
              Data analysis has not occured yet. Setting the conversation to
              complete will prevent data analysis from occuring.
            </DialogContentText>
          )}
          <DialogContentText id="alert-dialog-description">
            Confirming will set the conversation to complete. If you want to
            pause the conversation, please set the status to pause.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseComplete} autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirmChange}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAnalysisAlert}
        onClose={() => setOpenAnalysisAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to run Tacit Analysis?
        </DialogTitle>
        <DialogContent>
          {!createdConversation?.conversation?.data_analysis_complete &&
            !createdConversation?.conversation?.phase_2 && (
              <DialogContentText id="alert-dialog-description">
                Running Tacit Analysis will generate participant responses into
                categories and set the conversation to complete. Afterwards you
                will have the ability to set the conversation to active.
              </DialogContentText>
            )}
          {createdConversation?.conversation?.data_analysis_complete &&
            !createdConversation?.conversation?.phase_2 && (
              <DialogContentText id="alert-dialog-description">
                Tacit Analysis has already run. All existing categories and
                their idea matchings will be deleted and then recalculated. This
                is only recommended if you have collected more participants
                responses after Tacit Analysis. This will set the conversation
                to complete and afterwards you will have the ability to set the
                conversation to active.
              </DialogContentText>
            )}
          {!createdConversation?.conversation?.data_analysis_complete &&
            createdConversation?.conversation?.phase_2 && (
              <DialogContentText id="alert-dialog-description">
                Running Tacit Analysis will generate categories from participant
                categories, begin the Collective Validation step of the
                conversation, and email partipants to rejoin. This is only
                recommended if you have collected all of your desired
                participant responses.
              </DialogContentText>
            )}
          {createdConversation?.conversation?.data_analysis_complete &&
            createdConversation?.conversation?.phase_2 && (
              <DialogContentText id="alert-dialog-description">
                Running Tacit Analysis will delete all existing categories and
                all ratings for the categories. This is not recommended and only
                should be done in extreme circumstances.
              </DialogContentText>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAnalysisAlert(false)} autoFocus>
            Cancel
          </Button>
          <Button onClick={handleAnalysis}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

function LineItem({ icon, label, value, copyable }) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setSnackbarMessage("Link copied to clipboard!");
      setOpenSnackbar(true);
    } catch (err) {
      setSnackbarMessage("Failed to copy link.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ typography: "body2", color: "text.secondary" }}
    >
      <Stack direction="row" alignItems="center">
        <Iconify icon={icon} width={24} sx={{ mr: 1 }} />
        {label}
      </Stack>
      {copyable ? (
        <ButtonBase onClick={handleCopyToClipboard}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "text.primary",
              cursor: "pointer",
            }}
          >
            {value}
          </Typography>
        </ButtonBase>
      ) : (
        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
          {value}
        </Typography>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Stack>
  );
}

LineItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  value: PropTypes.string,
  copyable: PropTypes.bool,
};

LineItem.defaultProps = {
  copyable: false,
};

export default AccountConversation;
