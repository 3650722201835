import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import Button from "@mui/material/Button";

import Label from "src/components/label";
import Iconify from "src/components/iconify";
//  utils
import { fDate } from "src/utils/format-time";
import { fCurrency } from "src/utils/format-number";

// ----------------------------------------------------------------------

export default function ResultsIdeaTableRow({ row }) {
  const [open, setOpen] = useState(null);

  let date = new Date(row.created_at);
  let localDate = date.toLocaleString();

  const inputStyles = {
    pl: 1,
    [`&.${inputBaseClasses.focused}`]: {
      bgcolor: "action.selected",
    },
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.feedback == "" ? "N/A" : row.feedback}
          </Typography>
        </TableCell>

        <TableCell sx={{ px: 1 }}>
          <Typography variant="body2" sx={inputStyles}>
            {row.rating == null ? "N/A" : row.rating}
          </Typography>
        </TableCell>

        <TableCell>
          <Label
            color={
              (row.related === 1 && "success") ||
              (row.related === 0 && "error") ||
              "default"
            }
          >
            {row.related == 1 ? "Yes" : "No"}
          </Label>
        </TableCell>

        <TableCell>{localDate}</TableCell>
      </TableRow>
    </>
  );
}

ResultsIdeaTableRow.propTypes = {
  row: PropTypes.object,
};
