import InfoTooltip from "src/components/dashboard/info-tooltip";

import Iconify from "src/components/iconify";

import { Avatar, Card, Box, Stack, useTheme, Typography } from "@mui/material";

export const TotalRatingsCard = ({
  title,
  value,
  color,
  icon,
  info,
  percent,
}) => {
  const theme = useTheme();
  return (
    <Stack
      spacing={3}
      sx={{ p: 3, borderRadius: 2, bgcolor: "background.neutral" }}
    >
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="space-between"
        spacing={3}
      >
        <Box sx={{ height: "30px", display: "flex", alignItems: "center" }}>
          <Typography color="text.secondary" variant="overline">
            {title}
          </Typography>
        </Box>

        <InfoTooltip info={info} />
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={5}
      >
        {!value ? (
          <Typography variant="h2">{percent ? `0%` : 0}</Typography>
        ) : (
          <Typography variant="h2">{percent ? `${value}%` : value}</Typography>
        )}

        <Avatar
          sx={{
            backgroundColor: color,
            height: 56,
            width: 56,
          }}
        >
          <Iconify icon={icon} width={25} />
        </Avatar>
      </Stack>
    </Stack>
  );
};
