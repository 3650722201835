import {
  START_LOADING,
  END_LOADING,
  FETCH_QUESTIONS,
  FETCH_QUESTION,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  questions: null,
  question: {},
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case FETCH_QUESTION:
      const { pk, data } = action.payload;
      return {
        ...state,
        question: {
          ...state.question,
          [pk]: data,
        },
      };
    case FETCH_QUESTIONS:
      return { ...state, questions: action.payload };
    default:
      return state;
  }
};

export default questionReducer;
