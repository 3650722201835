import { useEffect, useState } from "react";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import Image from "src/components/image";
import { paths } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { RouterLink } from "src/routes/components";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { verify } from "src/actions/authActions";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { set } from "nprogress";
// ----------------------------------------------------------------------

export default function ActivateView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { uid, token } = useParams();

  const [verified, setVerified] = useState(false);

  useEffect(() => {
    if (verified) {
      navigate("/");
    }
  }, [verified]);

  const onSubmit = async () => {
    try {
      dispatch(verify(uid, token));
      setVerified(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Image
        alt="reset password"
        src="/assets/icons/ic_lock_password.svg"
        sx={{ mb: 5, width: 96, height: 96, mx: "auto" }}
      />

      <Typography variant="h3" paragraph>
        Activate your account
      </Typography>

      <FormProvider onSubmit={onSubmit}>
        <LoadingButton
          fullWidth
          size="large"
          color="inherit"
          type="submit"
          variant="contained"
          sx={{ mt: 2.5 }}
        >
          Confirm
        </LoadingButton>
      </FormProvider>
    </>
  );
}
