import { useRef, useEffect } from "react";
import StartLanding from "../start-landing";
import StartHowItWork from "../start-how-it-works";
import Footer2 from "src/layouts/main/footer2";
// ----------------------------------------------------------------------

export default function StartView({ rejoinPhase1, setRejoinPhase1 }) {
  const targetRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <StartLanding
        targetRef={targetRef}
        rejoinPhase1={rejoinPhase1}
        setRejoinPhase1={setRejoinPhase1}
      />
      <StartHowItWork ref={targetRef} />
      <Footer2 />
    </>
  );
}
