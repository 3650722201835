import {
  FETCH_QUESTION_CATEGORIES,
  START_LOADING_QUESTION_CATEGORIES,
  END_LOADING_QUESTION_CATEGORIES,
  CREATE_TRAITS_TOP_ANSWER,
  START_LOADING_TRAITS_TOP_ANSWER,
  END_LOADING_TRAITS_TOP_ANSWER,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  categories: {},
  isLoadingAnswer: false,
  topTraitAnswer: "",
};

const questionsCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_QUESTION_CATEGORIES:
      return { ...state, isLoading: true };
    case END_LOADING_QUESTION_CATEGORIES:
      return { ...state, isLoading: false };
    case START_LOADING_TRAITS_TOP_ANSWER:
      return { ...state, isLoadingAnswer: true };
    case END_LOADING_TRAITS_TOP_ANSWER:
      return { ...state, isLoadingAnswer: false };
    case FETCH_QUESTION_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.questionId]: {
            data: action.payload.data,
          },
        },
      };
    case CREATE_TRAITS_TOP_ANSWER:
      return {
        ...state,
        topTraitAnswer: action.payload.answer,
      };
    default:
      return state;
  }
};

export default questionsCategoriesReducer;
