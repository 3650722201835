import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { useState } from "react";

const InfoTooltip = ({ info }) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
      <Tooltip title={info} open={openTooltip}>
        <InfoIcon
          color="action"
          fontSize="small"
          onClick={() => setOpenTooltip(!openTooltip)}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        />
      </Tooltip>
    </ClickAwayListener>
  );
};

export default InfoTooltip;
