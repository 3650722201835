import {
  FETCH_SUMMARY,
  START_LOADING_SUMMARY,
  END_LOADING_SUMMARY,
  CREATE_TRAITS_SUMMARY,
  START_LOADING_TRAITS_SUMMARY,
  END_LOADING_TRAITS_SUMMARY,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  summaries: {},
};

const resultReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_SUMMARY:
      return { ...state, isLoading: true };
    case END_LOADING_SUMMARY:
      return { ...state, isLoading: false };
    case START_LOADING_TRAITS_SUMMARY:
      return { ...state, isLoading: true };
    case END_LOADING_TRAITS_SUMMARY:
      return { ...state, isLoading: false };
    case FETCH_SUMMARY:
      return {
        ...state,
        summaries: {
          ...state.summaries,
          [action.payload.questionId]: {
            summary: action.payload.summary,
            time: action.payload.time,
          },
        },
      };
    case CREATE_TRAITS_SUMMARY:
      return {
        ...state,
        summaries: {
          ...state.summaries,
          [action.payload.questionId]: {
            summary: action.payload.summary,
          },
        },
      };
    default:
      return state;
  }
};

export default resultReducer;
