import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreatedConversations } from "src/actions/accountActions";
import { useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import AccountConversationTable from "../conversations/account-conversations-table";

const AccountCreatedConversationsView = () => {
  return (
    <>
      <AccountConversationTable />
    </>
  );
};

export default AccountCreatedConversationsView;
