import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Scrollbar from "src/components/scrollbar";
export default function ResultsQuestionIdeasHelp() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Table Help
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Information about the table tabs and columns
        </DialogTitle>
        <DialogContent>
          <Typography variant="h5">Tabs</Typography>
          <Typography variant="h6">All Ideas</Typography>
          <Typography variant="body1">
            Explore a comprehensive list of all ideas generated by participants
            during their conversations.
          </Typography>
          <Typography variant="h6">Top Common Ideas</Typography>
          <Typography variant="body1">
            Discover top-rated ideas that resonated across the conversation,
            with multiple participants independently proposing them.
          </Typography>
          <Typography variant="h6">Top Unique Ideas</Typography>
          <Typography variant="body1">
            Explore the most favorably rated ideas that emerged uniquely from
            participants' insights, offering distinct perspectives.
          </Typography>
          <Typography variant="h6">Controversial Ideas</Typography>
          <Typography variant="body1">
            Engage with ideas that sparked diverse viewpoints, providing a rich
            perspective on polarizing concepts.
          </Typography>
          <Typography variant="h6">Most Text Feedback</Typography>
          <Typography variant="body1">
            Uncover ideas that ignited extensive optional qualitative input
            beyond ratings.
          </Typography>
          <Divider sx={{ m: 2 }} />
          <Typography variant="h5">Columns</Typography>
          <Typography variant="h6">Idea</Typography>
          <Typography variant="body1">
            The distinct concepts or proposals articulated by one or more
            participants in their responses.
          </Typography>
          <Typography variant="h6">Rating</Typography>
          <Typography variant="body1">
            Indicates if an idea aligns with the original question topic. During
            Crowd Validation, participants filter out unrelated ideas (marked
            "no") and relevant ideas (marked "yes").
          </Typography>
          <Typography variant="h6">Occurrence</Typography>
          <Typography variant="body1">
            Measures The frequency of an idea emerging across participants'
            insights. A score of 1 signifies a unique idea, while a higher
            number indicates multiple participants independently proposing the
            same concept.
          </Typography>
          <Typography variant="h6">Controversy Level</Typography>
          <Typography variant="body1">
            Gauges the degree of divergence in ratings for an idea, highlighting
            varying viewpoints. Ranks on a scale from High to Low, revealing how
            polarizing the idea's perceived value is.
          </Typography>
          <Typography variant="h6">Exposures</Typography>
          <Typography variant="body1">
            Quantifies how frequently a specific idea was presented to distinct
            participants during the Crowd Validation Phase for feedback and
            ratings.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
