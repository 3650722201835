import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function ResultsQuestionIdeas() {
  return (
    <Stack
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          py: 5,

          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography variant="h2">Ideas Generated by your Question</Typography>
      </Stack>
    </Stack>
  );
}
