import AccountConversation from "../conversation/account-conversation";
import AccountConversationEdit from "../conversation/account-conversation-edit";

const AccountConversationView = () => {
  return (
    <>
      <AccountConversation />
      {/* <AccountConversationEdit /> */}
    </>
  );
};

export default AccountConversationView;
