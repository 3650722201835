import Container from "@mui/material/Container";
import { Stack, Typography } from "@mui/material";
import Chatbox from "src/components/results/chatbox";

export default function ResultsQuestionChat() {
  return (
    <Stack
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Chatbox />
    </Stack>
  );
}
