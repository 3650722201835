import ResultsQuestionAbout from "../question/results-question-about";
import ResultsQuestionChat from "../question/results-question-chat";

export default function ResultsQuestionChatView() {
  return (
    <>
      <ResultsQuestionAbout />
      <ResultsQuestionChat />
    </>
  );
}
