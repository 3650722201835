import { useState, useCallback } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import Label from "src/components/label";
import Image from "src/components/image";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton, { toggleButtonClasses } from "@mui/material/ToggleButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// ----------------------------------------------------------------------

export default function Pricing01Card({ plan, onRequestPricing }) {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState("monthly");
  const basicLicense = plan.license === "Trial";

  const starterLicense = plan.license === "Subscription";

  const premiumLicense = plan.license === "Premium";

  const { access, refresh, isAuthenticated, user, googleLoading } = useSelector(
    (state) => state.authData
  );

  const handleChangeSubscription = useCallback((event, newValue) => {
    if (newValue !== null) {
      setSubscription(newValue);
    }
  }, []);

  const handleStartFreeTrial = () => {
    navigate("/signup");
  };

  return (
    <Card
      sx={{
        p: 5,
        textAlign: "center",

        // boxShadow: (theme) => theme.customShadows.z8,
        // ...(starterLicense && {
        //   py: 8,
        //   boxShadow: (theme) => theme.customShadows.z24,
        // }),
      }}
    >
      <Stack spacing={5} alignItems="center">
        <Typography
          variant="overline"
          component="div"
          sx={{ color: "text.secondary" }}
        >
          {plan.license}
        </Typography>

        <Image alt={plan.icon} src={plan.icon} sx={{ width: 80, height: 80 }} />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
        >
          <Typography variant="h3" component="span">
            {plan.price}
          </Typography>
        </Stack>
        <Button
          fullWidth
          size="large"
          variant={basicLicense ? "outlined" : "contained"}
          color={premiumLicense ? "primary" : "inherit"}
          disabled={basicLicense && isAuthenticated}
          onClick={
            starterLicense
              ? onRequestPricing
              : basicLicense
              ? handleStartFreeTrial
              : null
          }
        >
          {basicLicense && "Create Account"}
          {starterLicense && "Request Pricing"}
        </Button>
      </Stack>
      {/* <Stack spacing={1} sx={{ textAlign: "left", pt: 3 }}>
        {plan.options.map((option) => (
          <Typography
            key={option.title}
            variant={option.disabled ? "body2" : "subtitle2"}
            sx={{
              position: "relative",
              paddingLeft: "1.5em", // Adjust this value for spacing
              "&::before": {
                content: '"•"',
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "1em",
                color: "currentColor", // Use current text color
              },
              ...(option.disabled && {
                color: "text.disabled",
                textDecoration: "line-through",
              }),
            }}
          >
            {option.title}
          </Typography>
        ))}
      </Stack> */}
    </Card>
  );
}

Pricing01Card.propTypes = {
  plan: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    license: PropTypes.string,
    options: PropTypes.array,
    price: PropTypes.string,
  }),
};
