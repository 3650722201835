import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Iconify from "src/components/iconify";
import SvgColor from "src/components/svg-color";

// ----------------------------------------------------------------------

const STEPS = [
  {
    title: "Create a Conversation",
    description:
      "Use the inputs to establish the topic/question you wish to explore with your crowd",
    icon: "/assets/icons/create.svg",
  },
  {
    title: "Share your Conversation",
    description:
      'Copy and paste the "share" link into your preferred communication channel to reach your crowd (text, email, Slack, Teams, etc.)',
    icon: "/assets/icons/send.svg",
  },
  {
    title: "Explore the Results",
    description:
      'After your participants have responded (or during) use the "Results" link above to analyze the conversations results',
    icon: "/assets/icons/magnifying.svg",
  },
];

// ----------------------------------------------------------------------

export default function LinkStep() {
  return (
    <Box
      sx={{
        textAlign: "center",
        pt: { xs: 10, md: 15 },
        pb: { xs: 5, md: 10 },
        bgcolor: (theme) =>
          theme.palette.mode === "light" ? "grey.200" : "grey.800",
      }}
    >
      <Container>
        <Typography variant="h2" sx={{ my: 3 }}>
          How to Run a Conversation
        </Typography>

        <Typography sx={{ color: "text.secondary", maxWidth: 480, mx: "auto" }}>
          Use the following steps to create, share, and view the results of your
          Tacit conversation.
        </Typography>

        <Box
          sx={{
            display: "grid",
            my: { xs: 8, md: 10 },
            gap: { xs: 8, md: 5 },
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
            },
          }}
        >
          {STEPS.map((value, index) => (
            <div key={value.title}>
              <SvgColor
                src={value.icon}
                sx={{
                  width: 120,
                  height: 120,
                  mx: "auto",
                  color: "primary.main",
                }}
              />
              <Typography
                variant="overline"
                sx={{ mt: 4, display: "block", color: "text.disabled" }}
              >
                {index === 0 ? "Step 1  Complete" : <>Step {index + 1}</>}
              </Typography>

              <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
                {value.title}
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {value.description}
              </Typography>
            </div>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
