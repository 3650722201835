import * as Yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { createContactForm } from "src/actions/contactFormActions";
import { useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import Image from "src/components/image";
import { useResponsive } from "src/hooks/use-responsive";
import FormProvider, { RHFTextField } from "src/components/hook-form";

// ----------------------------------------------------------------------

export default function HomeContactForm({ text }) {
  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const [companySize, setCompanySize] = useState("");

  const ContactFormSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("That is not an email"),
    industry: Yup.string().required("Industry is required"),
    use_case: Yup.string().required("Use Case is required"),
    size: Yup.number().min(0).max(10000000),
  });

  const handleSizeChange = (event) => {
    const value = event.target.value;
    setCompanySize(value === "0" || value === 0 ? "" : value);
    methods.setValue("size", value);
  };

  const defaultValues = {
    size: 0,
    full_name: "",
    industry: "",
    email: "",
    use_case: "",
  };

  const methods = useForm({
    resolver: yupResolver(ContactFormSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log("DATA", data);
      dispatch(createContactForm(data));
      reset();
      setCompanySize("");
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Container
      sx={{
        py: { xs: 10, md: 15 },
      }}
    >
      <Grid container spacing={3} justifyContent="space-between">
        {mdUp && (
          <Grid
            container
            xs={12}
            md={6}
            lg={6}
            justifyContent="center" // Centers the content horizontally
            alignItems="center" // Centers the content vertically
          >
            <Image
              alt="travel-contact"
              src="/assets/illustrations/tacit_logo_blue.svg"
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid>
        )}

        <Grid xs={12} md={6} lg={6}>
          <Stack
            spacing={2}
            sx={{
              mb: 5,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography variant="h3" color="primary">
              {text}
            </Typography>

            <Typography sx={{ color: "text.secondary" }}>
              Please send us a request with the following information if you
              would like to set up your own Tacit Moderator account.
            </Typography>
          </Stack>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2} alignItems="flex-start">
              <RHFTextField
                name="full_name"
                label="Full name"
                inputProps={{
                  maxLength: 200,
                  style: { fontSize: 16 },
                }}
              />

              <RHFTextField
                name="email"
                label="Email"
                inputProps={{
                  maxLength: 200,
                  style: { fontSize: 16 },
                }}
              />

              <RHFTextField
                name="industry"
                label="Industry"
                inputProps={{
                  maxLength: 200,
                  style: { fontSize: 16 },
                }}
              />

              <RHFTextField
                name="size"
                label="Company Size (optional)"
                value={companySize}
                onChange={handleSizeChange}
                inputProps={{
                  type: "number",
                  style: { fontSize: 16 },
                }}
              />
              <RHFTextField
                name="use_case"
                multiline
                rows={4}
                label="I would like to use Tacit for..."
                sx={{ pb: 2.5 }}
                inputProps={{
                  maxLength: 300,
                  style: { fontSize: 16 },
                }}
              />

              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                color="inherit"
                loading={isSubmitting}
                sx={{
                  alignSelf: { xs: "center", md: "unset" },
                }}
              >
                Send Request
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
