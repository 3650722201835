import { useState, useCallback, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TablePagination from "@mui/material/TablePagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Snackbar } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";

import {
  stableSort,
  getComparator,
} from "src/sections/_results/question/utils";
import ResultsQuestionCategoriesTableHead from "./results-question-categories-table-head";
import ResultsQuestionCategoriesTableRow from "./results-question-categories-table-row";
import { getQuestionCategories } from "src/actions/resultsCategoriesActions";
import { useNavigate } from "react-router-dom";

const ResultsQuestionCategoriesTable = ({
  selectedDemographics,
  setSelectedDemographics,
  filteredCategories,
  setFilteredCategories,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { results: conversationResultsId, question: questionId } = useParams();

  const { isLoading: isLoadingConversation, conversation } = useSelector(
    (state) => state.conversationData
  );
  const { isLoading: isLoadingQuestion, questions } = useSelector(
    (state) => state.questionData
  );
  const { isLoading: isLoadingCategories, categories } = useSelector(
    (state) => state.questionsCategoriesData
  );

  const tableState = useSelector((state) => state.categoriesTableData);

  const [tab, setTab] = useState(tableState.tab);
  const [order, setOrder] = useState(tableState.order);

  const [orderBy, setOrderBy] = useState(tableState.orderBy);

  const [searchTerm, setSearchTerm] = useState(tableState.searchTerm);

  const [page, setPage] = useState(tableState.page);

  const [dense, setDense] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(tableState.rowsPerPage);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const questionIsPhase2 = questions?.some(
    (question) => question.question_id === questionId && question.phase_2
  );

  useEffect(() => {
    if (questions) {
      setSnackbarOpen(!questionIsPhase2);
    }
  }, [questionId, questionIsPhase2]);

  useEffect(() => {
    // Function to convert trait IDs to trait texts
    const getTraitTextsFromIds = (traitIds) => {
      return traitIds
        .map(
          (id) =>
            conversation?.traits.find((trait) => trait.trait_id === id)?.text
        )
        .filter(Boolean); // Filter out any undefined values
    };

    const selectedDemographicsTexts =
      getTraitTextsFromIds(selectedDemographics);

    const filterResponses = (category) => {
      const matchesSearchTermTitle = category.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      const hasMatchingTraits =
        selectedDemographicsTexts.length === 0 ||
        (Object.keys(category?.traits).length > 0 &&
          selectedDemographicsTexts.every((traitText) =>
            Object.keys(category?.traits).includes(traitText)
          ));

      return matchesSearchTermTitle && hasMatchingTraits;
    };

    if (categories[questionId]?.data.categories) {
      setFilteredCategories(
        categories[questionId]?.data.categories.filter(filterResponses)
      );
    }
  }, [
    dispatch,
    questionId,
    searchTerm,
    selectedDemographics,
    isLoadingCategories,
    conversation?.traits,
  ]);

  const handleSort = useCallback(
    (id) => {
      const isAsc = orderBy === id && order === "asc";
      if (id !== "") {
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(id);
      }
    },
    [order, orderBy]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            categories[questionId]?.data.categories.length
        )
      : 0;

  const handleDemographicChange = (event) => {
    setSelectedDemographics(event.target.value);
  };

  const TABLE_HEAD = [
    { id: "title", label: "Response Category", width: 400, sortKey: "title" },

    { id: "score", label: "Score", width: 100, sortKey: "rating" },

    { id: "rating", label: "Rating", width: 100, sortKey: "rating" },

    { id: "stddev", label: "Agreement", width: 100, sortKey: "stddev" },
    {
      id: "num_users",
      label: "Contributors",
      width: 100,
      sortKey: "num_users",
    },
    { id: "num_ideas", label: "Ideas", width: 100, sortKey: "num_ideas" },

    { id: "" },
  ];

  return (
    <>
      {categories[questionId]?.data && (
        <Stack
          sx={{
            paddingLeft: { xs: 0, md: 8 },
            paddingRight: { xs: 0, md: 8 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ mt: 1 }}
          >
            {conversation?.traits?.length > 0 && (
              <>
                <FormControl sx={{ width: 300 }}>
                  {selectedDemographics?.length === 0 && (
                    <InputLabel
                      htmlFor="trait-select-label"
                      style={{ color: "rgba(0, 0, 0, 0.87)" }}
                    >
                      All Demographics
                    </InputLabel>
                  )}
                  <Select
                    labelId="trait-select-label"
                    multiple
                    value={selectedDemographics}
                    onChange={handleDemographicChange}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        // When no traits are selected, show "All Up"
                        return;
                      } else {
                        // When traits are selected, show the chips
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                              mt: -1.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={
                                  conversation?.traits?.find(
                                    (trait) => trait.trait_id === value
                                  ).text
                                }
                              />
                            ))}
                          </Box>
                        );
                      }
                    }}
                  >
                    {conversation?.traits?.length > 0 &&
                      conversation?.traits?.map((trait) => (
                        <MenuItem key={trait.trait_id} value={trait.trait_id}>
                          {trait.text}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            )}
            <TextField
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setPage(0);
              }}
              fullWidth
              hiddenLabel
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="carbon:search"
                      width={24}
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <TableContainer
            sx={{
              overflow: "unset",

              [`& .${tableCellClasses.head}`]: {
                color: "text.primary",
              },
              [`& .${tableCellClasses.root}`]: {
                bgcolor: "background.default",
                borderBottomColor: (theme) => theme.palette.divider,
              },
            }}
          >
            <Scrollbar>
              <Table
                sx={{
                  minWidth: 720,
                }}
                size={dense ? "small" : "medium"}
              >
                <ResultsQuestionCategoriesTableHead
                  order={order}
                  orderBy={orderBy}
                  onSort={handleSort}
                  headCells={TABLE_HEAD}
                  rowCount={categories[questionId]?.data?.categories?.length}
                />

                <TableBody>
                  {stableSort(filteredCategories, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <ResultsQuestionCategoriesTableRow
                        key={row.category_id}
                        row={row}
                        searchTerm={searchTerm}
                        tab={tab}
                        order={order}
                        orderBy={orderBy}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        selectedDemographics={selectedDemographics}
                        showDemographics={conversation?.traits?.length > 0}
                      />
                    ))}

                  {emptyRows > 0 && (
                    <TableRow
                      sx={{
                        height: (dense ? 36 : 57) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              page={page}
              component="div"
              count={filteredCategories?.length}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Stack>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message="Collective Validation not enabled for this question"
      />
    </>
  );
};

export default ResultsQuestionCategoriesTable;
