import AccountPersonalView from "src/sections/_account/view/account-personal-view";

// ----------------------------------------------------------------------

export default function AccountPersonalPage() {
  return (
    <>
      <AccountPersonalView />
    </>
  );
}
