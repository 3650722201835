import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserResponse } from "src/actions/resultsUserResponsesActions";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";

export default function ResultsUserResponsesMessages() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    results: conversationResultId,
    question: questionId,
    user: userStatsId,
  } = useParams();

  const {
    isLoading: isLoadingUserResponses,
    userResponseStats,
    userResponse,
    loadingUserResponse,
  } = useSelector((state) => state.userResponseData);

  // Sample JSON data
  const [messages, setMessages] = useState(userResponse);

  useEffect(() => {
    dispatch(getUserResponse(questionId, userStatsId));
  }, []);

  useEffect(() => {
    setMessages(userResponse);
  }, [userResponse]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loadingUserResponse) {
    return <CircularProgress />;
  }

  console.log(loadingUserResponse);

  return (
    <Stack
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          py: 5,
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Stack>
          <Button
            onClick={handleBack}
            sx={{
              width: "40px",
              height: "40px",
            }}
          >
            <ArrowBackIcon />
          </Button>
        </Stack>
        <Typography variant="h2">Participant Transcript</Typography>
        {loadingUserResponse ? (
          <CircularProgress sx={{ pl: 5 }} />
        ) : (
          <Stack spacing={2}>
            {messages.map((message, index) => (
              <div key={index}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {message.sender}:
                </Typography>
                <Typography variant="body1">{message.message}</Typography>
              </div>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
