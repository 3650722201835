import * as api from "../api/index.js";

import {
  SEND_MESSAGE,
  START_LOADING_GPTRESPONSE,
  END_LOADING_GPTRESPONSE,
} from "./actionTypes.js";

export const sendMessage = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_GPTRESPONSE });
    const { data } = await api.sendMessage(formData);
    console.log(data);
    dispatch({ type: SEND_MESSAGE, payload: data });
    dispatch({ type: END_LOADING_GPTRESPONSE });
    return data;
  } catch (error) {
    dispatch({ type: END_LOADING_GPTRESPONSE });
    throw error;
  }
};
