import { useState, useCallback } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton, { toggleButtonClasses } from "@mui/material/ToggleButton";
import { Button } from "@mui/material";

import PlanCard from "./trial-card";
import { useDispatch, useSelector } from "react-redux";
import { purchaseProduct } from "src/actions/paymentActions";
import Iconify from "src/components/iconify";
// ----------------------------------------------------------------------
export const _pricing01 = [
  {
    license: "Trial",
    price: "Complimentary Trial",
    icon: "/assets/illustrations/tacit_logo_blue_empty.svg",
    // options: [
    //   { title: "3 prototypes", disabled: false },
    //   { title: "3 boards", disabled: false },
    //   { title: "Up to 5 team members", disabled: true },
    //   { title: "Advanced security", disabled: true },
    //   { title: "Permissions & workflows", disabled: true },
    // ],
  },
  {
    license: "Subscription",
    price: "Premium Plan",
    icon: "/assets/illustrations/tacit_logo_blue.svg",
    // options: [
    //   { title: "3 prototypes", disabled: false },
    //   { title: "3 boards", disabled: false },
    //   { title: "Up to 5 team members", disabled: false },
    //   { title: "Advanced security", disabled: true },
    //   { title: "Permissions & workflows", disabled: true },
    // ],
  },
];

export default function TrialPlans({ targetRef, pricingRef }) {
  const dispatch = useDispatch();

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const goToPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        pt: 10,
      }}
    >
      <Typography variant="h2" align="center" paragraph>
        Choose a Plan that Works for You
      </Typography>

      <Box
        sx={{
          pt: 5,
          gap: 4,
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          },
        }}
      >
        {_pricing01.map((plan) => (
          <PlanCard
            key={plan.license}
            plan={plan}
            onRequestPricing={goToPricing}
          />
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 5,
        }}
      >
        <Button
          onClick={handleScroll}
          size="small"
          color="inherit"
          endIcon={<Iconify icon="carbon:chevron-right" />}
          sx={{ width: "auto" }} // Adjust the width as needed
        >
          Learn more
        </Button>
      </Box>
    </Container>
  );
}
