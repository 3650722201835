import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSummaryQuestion } from "../../actions/resultActions";
import {
  Button,
  Container,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";

const Summarize = () => {
  console.log("Component render");
  const { results: resultQuestionsId, question: questionId } = useParams();
  const dispatch = useDispatch();
  const { isLoading: isLoadingSummary, summary } = useSelector(
    (state) => state.resultData
  );

  useEffect(() => {
    console.log("Dispatching");
    dispatch(
      getSummaryQuestion(resultQuestionsId, questionId, { reload: false })
    );
  }, [resultQuestionsId, questionId]);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Summary
      </Typography>
      {isLoadingSummary ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Typography variant="body1" gutterBottom>
          {summary?.answer}
        </Typography>
      )}
      {!isLoadingSummary && (
        <Button
          variant="outlined"
          onClick={() =>
            dispatch(
              getSummaryQuestion(resultQuestionsId, questionId, {
                reload: true,
              })
            )
          }
        >
          Regenerate Summary
        </Button>
      )}
    </div>
  );
};

export default Summarize;
