import ResultsFeedbackAbout from "../feedback/results-feedback-about";
import ResultsFeedbackForm from "../feedback/results-feedback-form";

export default function ResultsFeedbackView() {
  return (
    <>
      <ResultsFeedbackAbout />
      <ResultsFeedbackForm />
    </>
  );
}
