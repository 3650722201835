import React from "react";

import ResultsUserResponsesView from "src/sections/_results/view/results-user-responses-view";

const ResultsUserResponsesPage = () => {
  return (
    <>
      <ResultsUserResponsesView />
    </>
  );
};

export default ResultsUserResponsesPage;
