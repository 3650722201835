import * as api from "../api/index.js";

import {
  FETCH_SINGLE_CATEGORY,
  START_LOADING_SINGLE_CATEGORY,
  END_LOADING_SINGLE_CATEGORY,
} from "./actionTypes.js";

export const getSingleCategory = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_SINGLE_CATEGORY });
    const { data } = await api.getCategory(categoryId);
    console.log(data);
    dispatch({ type: FETCH_SINGLE_CATEGORY, payload: data });
    dispatch({ type: END_LOADING_SINGLE_CATEGORY });
  } catch (error) {
    dispatch({ type: END_LOADING_SINGLE_CATEGORY });
    console.log(error.message);
  }
};
