import ResultsCategoriesQuestionView from "src/sections/_results/view/results-question-categories-view";

const ResultsQuestionCategoriesPage = () => {
  return (
    <>
      <ResultsCategoriesQuestionView />
    </>
  );
};

export default ResultsQuestionCategoriesPage;
