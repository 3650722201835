import {
  START_LOADING_CONVERSATION_IDEAS_STATS,
  END_LOADING_CONVERSATION_IDEAS_STATS,
  FETCH_CONVERSATION_IDEAS_STATS,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  ideaStats: null,
};

const conversationIdeasReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_CONVERSATION_IDEAS_STATS:
      return { ...state, isLoading: true };
    case END_LOADING_CONVERSATION_IDEAS_STATS:
      return { ...state, isLoading: false };
    case FETCH_CONVERSATION_IDEAS_STATS:
      return { ...state, ideaStats: action.payload };
    default:
      return state;
  }
};

export default conversationIdeasReducer;
