import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCategory } from "src/actions/categoryActions";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";
import ResultsQuestionCategoryBox from "./results-question-category-box";
import { Box } from "@mui/material";
import { ResultsQuestionCategoryTraitsPieChart } from "./results-question-category-traits-pie-chart";
import { getQuestion } from "src/actions/questionActions";
import { useResponsive } from "src/hooks/use-responsive";
import { LightbulbOutlined } from "@mui/icons-material";
import { Groups } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import Label from "src/components/label";

export default function ResultsQuestionCategory() {
  const mdUp = useResponsive("up", "md");
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    results: conversationResultsId,
    question: questionId,
    category: categoryId,
  } = useParams();

  const { isLoading, category } = useSelector((state) => state.categoryData);

  const { isLoading: isLoadingQuestion, question } = useSelector(
    (state) => state.questionData
  );

  useEffect(() => {
    if (!question[questionId] && !isLoadingQuestion) {
      dispatch(getQuestion(questionId));
    }
  }, []);

  useEffect(() => {
    dispatch(getSingleCategory(categoryId));
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handleIdeaCategoryView = () => {
    window.open(
      `/results/${conversationResultsId}/ideas/${questionId}?category=${categoryId}`,
      "_blank"
    );
  };

  const handleUserResponseCategoryView = () => {
    window.open(
      `/results/${conversationResultsId}/user-responses/${questionId}?category=${categoryId}`,
      "_blank"
    );
  };

  if (isLoading) {
    return <CircularProgress />;
  }
  const getRatingDetails = (rating) => {
    if (rating >= 4.5 && rating < 5.1)
      return { color: theme.palette.primary.main, name: "Excellent" };
    if (rating >= 3.5 && rating < 4.5)
      return { color: theme.palette.success.main, name: "Good" };
    if (rating >= 2.5 && rating < 3.5)
      return { color: theme.palette.warning.main, name: "Neutral" };
    if (rating >= 1.75 && rating < 2.5)
      return { color: theme.palette.error.main, name: "Fair" };
    if (rating > 0 && rating < 1.75)
      return { color: theme.palette.info.dark, name: "Poor" };
    return { color: theme.palette.grey[500], name: "N/A" }; // default values if rating does not match any category
  };

  const getStddevDetails = (stddev) => {
    if (stddev >= 1) return { color: theme.palette.error.main, name: "Low" };
    if (stddev >= 0.5 && stddev < 1)
      return { color: theme.palette.warning.main, name: "Medium" };
    return { color: theme.palette.primary.main, name: "High" }; // default values if rating does not match any category
  };

  return (
    <>
      {!isLoading && category ? (
        <Stack
          sx={{
            paddingLeft: { xs: 0, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          <Stack
            spacing={3}
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Stack>
              <Button
                onClick={handleBack}
                sx={{
                  width: "40px",
                  height: "40px",
                }}
              >
                <ArrowBackIcon />
              </Button>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography variant="h4">Question</Typography>
              <Typography variant="body1">
                {" "}
                {question[questionId]?.question}
              </Typography>
              <Typography variant="h4" sx={{ mt: 2 }}>
                Category: {category?.title}
              </Typography>
              <Typography variant="body1">{category?.description}</Typography>

              {question[questionId]?.phase_2 && category?.rating != 0 && (
                <>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ mt: 2 }}
                    alignContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h4">Group Rating:</Typography>
                    <Typography variant="h4">
                      {category?.rating?.toFixed(1)}
                    </Typography>
                    {(() => {
                      const { color, name } = getRatingDetails(
                        category?.rating
                      );
                      return (
                        <Label sx={{ backgroundColor: color, color: "white" }}>
                          {name}
                        </Label>
                      );
                    })()}
                    {category?.highest_rated && (
                      <Label
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        Top Collective Answer
                      </Label>
                    )}
                    {category?.lowest_rated && (
                      <Label
                        sx={{
                          backgroundColor: theme.palette.info.dark,
                          color: "white",
                        }}
                      >
                        Lowest Collective Answer
                      </Label>
                    )}
                  </Stack>
                </>
              )}
              {question[questionId]?.phase_2 && (
                <>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ mt: 2 }}
                    alignContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h4">Agreement Level:</Typography>
                    <Typography variant="h4">
                      {category?.stddev?.toFixed(2)}
                    </Typography>
                    {(() => {
                      const { color, name } = getStddevDetails(
                        category?.stddev
                      );
                      return (
                        <Label sx={{ backgroundColor: color, color: "white" }}>
                          {name}
                        </Label>
                      );
                    })()}
                    {category?.most_controversial && (
                      <Label
                        sx={{
                          backgroundColor: theme.palette.error.main,
                          color: "white",
                        }}
                      >
                        Most Controversial
                      </Label>
                    )}
                    {category?.least_controversial && (
                      <Label
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        Highest Agreement
                      </Label>
                    )}
                  </Stack>
                </>
              )}

              <Stack
                direction={mdUp ? "row" : "column"}
                spacing={3}
                sx={{ mt: 2 }}
              >
                <Stack direction="column">
                  <Typography variant="h4">Contributing Ideas</Typography>
                  <Button onClick={handleIdeaCategoryView} variant="outlined">
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{
                        justifyContent: "center", // Center items horizontally
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" color="primary">
                        {category?.num_ideas}{" "}
                      </Typography>
                      <LightbulbOutlined color="primary" />
                    </Stack>
                  </Button>
                </Stack>
                <Stack>
                  <Typography variant="h4">Contributing Users</Typography>
                  <Button
                    onClick={handleUserResponseCategoryView}
                    variant="outlined"
                  >
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{
                        justifyContent: "center", // Center items horizontally
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" color="primary">
                        {category?.num_users}{" "}
                      </Typography>
                      <Groups color="primary" />
                    </Stack>
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            {Object.keys(category?.traits || {}).length !== 0 && (
              <Stack mt={-2}>
                <ResultsQuestionCategoryTraitsPieChart
                  title="Contributing Demographics"
                  chartSeries={Object.values(category?.traits)}
                  labels={Object.keys(category?.traits || {})}
                />
              </Stack>
            )}
            {/* <ResultsQuestionCategoryBox
              categoryId={category.category_id}
              title={category.title}
              description={category.description}
              num_ideas={category.num_ideas}
              num_users={category.num_users}
              conversationResultsId={conversationResultsId}
              questionId={questionId}
              traits={category.traits}
              category={category}
              top_ready={true}
            /> */}

            {question[questionId]?.phase_2 && category?.feedback_summary && (
              <>
                <Stack direction="column">
                  <Typography variant="h4">Feedback</Typography>
                  <Typography variant="body1">
                    {category?.feedback_summary}
                  </Typography>
                </Stack>
              </>
            )}
            {question[questionId]?.phase_2 && category?.ideas?.length > 0 && (
              <>
                <Stack direction="column">
                  <Typography variant="h4">
                    Top Ideas within Category
                  </Typography>

                  {category?.ideas?.map((idea, index) => (
                    <Typography key={index} vairant="body1" sx={{ pl: 2 }}>
                      {index + 1}: {idea.idea}
                    </Typography>
                  ))}
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
