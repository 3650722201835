import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";

import Image from "src/components/image";
import { paths } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import { RouterLink } from "src/routes/components";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { reset_password_confirm } from "src/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

export default function ResetPasswordView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwordShow = useBoolean();
  const confirmPasswordShow = useBoolean();

  const { uid, token } = useParams();

  const [requestSent, setRequestSent] = useState(false);

  useEffect(() => {
    if (requestSent) {
      navigate("/");
    }
  }, [requestSent]);

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password should be of minimum 6 characters length"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const defaultValues = {
    password: "",
    confirmPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      dispatch(
        reset_password_confirm(uid, token, data.password, data.confirmPassword)
      );
      reset();
      setRequestSent(true);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <>
      <Image
        alt="reset password"
        src="/assets/icons/ic_lock_password.svg"
        sx={{ mb: 5, width: 96, height: 96, mx: "auto" }}
      />

      <Typography variant="h3" paragraph align="center">
        New Password
      </Typography>

      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        Please type your new password
      </Typography>

      <FormProvider methods={methods} onSubmit={onSubmit}>
        <RHFTextField
          name="password"
          label="Password"
          type={passwordShow.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordShow.onToggle} edge="end">
                  <Iconify
                    icon={
                      passwordShow.value ? "carbon:view" : "carbon:view-off"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ mb: 3 }}
        />

        <RHFTextField
          name="confirmPassword"
          label="Confirm Password"
          type={confirmPasswordShow.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={confirmPasswordShow.onToggle} edge="end">
                  <Iconify
                    icon={
                      confirmPasswordShow.value
                        ? "carbon:view"
                        : "carbon:view-off"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          color="inherit"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 2.5 }}
        >
          Reset Password
        </LoadingButton>
      </FormProvider>

      <Link
        component={RouterLink}
        rel="noopener"
        to="/login"
        color="inherit"
        variant="subtitle2"
        sx={{
          mt: 3,
          mx: "auto",
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <Iconify icon="carbon:chevron-left" width={16} sx={{ mr: 1 }} />
        Return to sign in
      </Link>
    </>
  );
}
