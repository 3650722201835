import LinksView from "src/sections/_links/view/links-view";

// ----------------------------------------------------------------------

export default function LinksPage() {
  return (
    <>
      <LinksView />
    </>
  );
}
