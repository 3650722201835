import * as api from "../api/index.js";

import {
  START_LOADING,
  END_LOADING,
  START_LOADING_SUMMARY,
  END_LOADING_SUMMARY,
  FETCH_QUESTIONS,
  FETCH_CONVERSATION,
  FETCH_SUMMARY,
  START_LOADING_ASK,
  END_LOADING_ASK,
  ASK_QUESTION,
  START_LOADING_QUESTION_ANSWER,
  END_LOADING_QUESTION_ANSWER,
  FETCH_QUESTION_ANSWER,
  START_LOADING_TABLE,
  END_LOADING_TABLE,
  FETCH_TABLE,
  FETCH_CONVERSATION_STATS,
  START_LOADING_CONVERSATION_STATS,
  END_LOADING_CONVERSATION_STATS,
  FETCH_ALL_QUESTION_STATS,
  END_LOADING_ALL_QUESTION_STATS,
  START_LOADING_ALL_QUESTION_STATS,
  CREATE_TRAITS_SUMMARY,
  START_LOADING_TRAITS_SUMMARY,
  END_LOADING_TRAITS_SUMMARY,
} from "./actionTypes.js";

export const getResultQuestions =
  (resultConversationId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchQuestionsResults(resultConversationId);
      console.log(data);
      dispatch({ type: FETCH_QUESTIONS, payload: data });
      dispatch({ type: END_LOADING });
    } catch (error) {
      dispatch({ type: END_LOADING });
      console.log(error.message);
    }
  };

export const getResultConversation =
  (resultConversationId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchConversationResults(resultConversationId);
      console.log(data);
      dispatch({ type: FETCH_CONVERSATION, payload: data });
      dispatch({ type: END_LOADING });
    } catch (error) {
      dispatch({ type: END_LOADING });
      console.log(error.message);
    }
  };

// export const sendQuestion = (formData) => async (dispatch) => {
// try {
//     dispatch({ type: START_LOADING_GPTRESPONSE });
//     const { data } = await api.sendMessage(formData);
//     console.log(data);
//     dispatch({ type: SEND_MESSAGE, payload: data });
//     dispatch({ type: END_LOADING_GPTRESPONSE });
//     return data;
// } catch (error) {
//     console.log(error.message);
// }
// };

export const askResultQuestion =
  (resultConversationId, questionId, query) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_ASK });
      const { data } = await api.askQuestion(
        resultConversationId,
        questionId,
        query
      );
      console.log(data);
      dispatch({ type: ASK_QUESTION, payload: data });
      dispatch({ type: END_LOADING_ASK });
      return data;
    } catch (error) {
      dispatch({ type: END_LOADING_ASK });
      console.log(error.message);
      throw error;
    }
  };

export const getSummaryQuestion =
  (resultConversationId, questionId, reload) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_SUMMARY });
      const { data } = await api.summarizeQuestion(
        resultConversationId,
        questionId,
        reload
      );
      console.log(data);
      dispatch({
        type: FETCH_SUMMARY,
        payload: { questionId, summary: data.answer, time: data.time },
      });
      dispatch({ type: END_LOADING_SUMMARY });
      return data;
    } catch (error) {
      dispatch({ type: END_LOADING_SUMMARY });
      console.log(error.message);
    }
  };

export const getTableQuestion =
  (resultConversationId, questionId, reload) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_TABLE });
      const { data } = await api.tableQuestion(
        resultConversationId,
        questionId,
        reload
      );
      console.log(data);
      dispatch({ type: FETCH_TABLE, payload: data });
      dispatch({ type: END_LOADING_TABLE });
      return data;
    } catch (error) {
      console.log(error.message);
    }
  };

export const getQuestionsAnswers =
  (resultConversationId, questionId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_QUESTION_ANSWER });
      const { data } = await api.fetchQuestionAnswer(
        resultConversationId,
        questionId
      );
      console.log(data);
      dispatch({ type: FETCH_QUESTION_ANSWER, payload: data });
      dispatch({ type: END_LOADING_QUESTION_ANSWER });
      return data;
    } catch (error) {
      dispatch({ type: END_LOADING_QUESTION_ANSWER });
      console.log(error.message);
    }
  };

export const getConversationStats =
  (resultConversationId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_CONVERSATION_STATS });
      const { data } = await api.fetchConversationStats(resultConversationId);
      console.log(data);
      dispatch({ type: FETCH_CONVERSATION_STATS, payload: data });
      dispatch({ type: END_LOADING_CONVERSATION_STATS });
      return data;
    } catch (error) {
      dispatch({ type: END_LOADING_CONVERSATION_STATS });
      console.log(error.message);
    }
  };

export const getAllQuestionStats =
  (resultConversationId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_ALL_QUESTION_STATS });
      const { data } = await api.fetchAllQuestionStats(resultConversationId);
      console.log(data);
      dispatch({ type: FETCH_ALL_QUESTION_STATS, payload: data });
      dispatch({ type: END_LOADING_ALL_QUESTION_STATS });
      return data;
    } catch (error) {
      dispatch({ type: END_LOADING_ALL_QUESTION_STATS });
      console.log(error.message);
    }
  };

export const createTraitsSummary =
  (questionId, catsAndTraits) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_TRAITS_SUMMARY });
      const { data } = await api.createTraitsSummary(questionId, catsAndTraits);
      console.log(data);
      dispatch({ type: CREATE_TRAITS_SUMMARY, payload: data });
      dispatch({ type: END_LOADING_TRAITS_SUMMARY });
      return data;
    } catch (error) {
      dispatch({ type: END_LOADING_TRAITS_SUMMARY });
      console.log(error.message);
    }
  };
