import { SET_USER_RESPONSES_TABLE_STATE } from "src/actions/actionTypes";

const initialState = {
  order: "asc",
  orderBy: "orderId",
  searchTerm: "",
  page: 0,
  rowsPerPage: 5,
  categories: [],
};

const userResponsesTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_RESPONSES_TABLE_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default userResponsesTableReducer;
