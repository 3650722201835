import * as api from "../api/index.js";

import { PURCHASE_PRODUCT } from "./actionTypes.js";

export const purchaseProduct = (formData) => async (dispatch) => {
  try {
    dispatch({ type: PURCHASE_PRODUCT });
    const { data } = await api.purchaseProduct(formData);
    console.log(data);
    return data;
  } catch (error) {
    throw error;
  }
};
