import React from "react";
import Questions from "../components/questions/questions";

const QuestionsPage = () => {
  return (
    <div>
      <Questions />
    </div>
  );
};

export default QuestionsPage;
