import React from "react";

import ResultsHomeView from "src/sections/_results/view/results-home-view";

const ResultsHomePage = () => {
  return (
    <>
      <ResultsHomeView />
    </>
  );
};

export default ResultsHomePage;
