import React from "react";

import ResultsDataView from "src/sections/_results/view/results-data-view";

const ResultsDataPage = () => {
  return (
    <>
      <ResultsDataView />
    </>
  );
};

export default ResultsDataPage;
