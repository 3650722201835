import React, { useState } from "react";
import { TextField, IconButton, Grid, Box } from "@mui/material/";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InfoTooltip from "src/components/dashboard/info-tooltip";
import Stack from "@mui/material/Stack";
import { useResponsive } from "src/hooks/use-responsive";
import { useTheme } from "@mui/material/styles";

const Question = ({
  index,
  question,
  handleQuestionChange,
  handleSwitchChange,
  moveUp,
  moveDown,
  deleteQuestion,
  addQuestion,
  numQuestions,
  isLoadingCreate,
  numParticipants,
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const maxQuestions = 5;
  const theme = useTheme();

  const labels = ["Zero", "Minimal", "Balanced", "Thorough", "Comprehensive"];

  const label_desccriptions = [
    "The AI refrains from asking further questions.",
    "The AI poses one or two additional questions based on the initial response.",
    "The AI engages with a moderate number of follow-up questions",
    "The AI thoroughly explores the topic through multiple follow-up questions.",
    "The AI engages in extensive discussion with the participant, delving deep with a wide range of follow-up questions.",
  ];

  const mdUp = useResponsive("up", "md");

  const valueLabelFormat = (value) => {
    return labels[value - 1];
  };

  return (
    <Grid container alignItems="center" spacing={0}>
      <Grid item xs={mdUp ? 8 : 12}>
        <TextField
          label={"Question"}
          variant="outlined"
          multiline
          maxRows={4}
          fullWidth
          value={question.question.slice(0, 254)}
          onChange={(event) => handleQuestionChange(index, event, "question")}
          style={{ marginBottom: "10px" }}
          InputProps={{
            style: { fontSize: 16 },
            // backgroundColor: theme.palette.grey[100],
            placeholder:
              "What emerging technologies are you most excited about, and how can they be integrated into our current products/services?",
          }}
          disabled={isLoadingCreate}
        />

        {/* <TextField
          label={"Prompt"}
          variant="outlined"
          multiline
          maxRows={4}
          fullWidth
          value={question.prompt.slice(0, 10000)}
          onChange={(event) => handleQuestionChange(index, event, "prompt")}
        /> */}
        <Box display="flex" alignItems="center">
          <Typography
            variant="body"
            sx={{ fontWeight: "fontWeightBold", pr: 1 }}
          >
            Depth Level: {labels[question.depth - 1]}
          </Typography>
          <InfoTooltip
            info={
              "Adjust the 'Depth Level' slider to fine-tune how extensively the AI conversational agent engages with each participant on the question topic."
            }
          />
        </Box>
        <Typography variant="secondary">
          {label_desccriptions[question.depth - 1]}
        </Typography>
        <Stack direction="row" p={{ xs: 1, md: 3 }}>
          <Slider
            min={1}
            max={5}
            step={1}
            marks
            value={question.depth}
            onChange={(event) => handleQuestionChange(index, event, "depth")}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="auto"
            color="primary"
            aria-label="Rating slider"
            sx={{
              width: "75%", // horizontal slider width
            }}
            disabled={isLoadingCreate}
          />
          {/* <InfoTooltip
            info={
              "Adjust the 'Depth Level' slider to fine-tune how extensively the AI conversational agent engages with each participant on the question topic."
            }
          /> */}
        </Stack>
        <Box mb={2}>
          <Typography>Enable Crowd Validation</Typography>
          <Box display="flex" alignItems="center">
            <Switch
              checked={question.phase2}
              onChange={(event) => {
                handleSwitchChange(index, event, "phase2");
                handleSwitchChange(index, false, "exposeAll");
              }}
              name="phase2"
              disabled={isLoadingCreate}
            />
            <InfoTooltip
              info={
                "Turn on Crowd Validation to spark an interactive idea-sharing space where participants provide feedback and ratings on each other's contributions, collectively validating the top insights for a more robust outcome."
              }
            />
          </Box>
        </Box>
        {/* {question.phase2 && (
          <Box mb={2}>
            <Typography>Full Idea Exposure</Typography>
            <Box display="flex" alignItems="center">
              <Switch
                checked={question.exposeAll}
                onChange={(event) =>
                  handleSwitchChange(index, event, "exposeAll")
                }
                name="exposeAll"
                disabled={!question.phase2 || isLoadingCreate}
              />
              <InfoTooltip
                info={
                  "Activate 'Full Idea Exposure' to grant participants access to all conversation-generated ideas during Crowd Validation, fostering comprehensive feedback.\nNote: Recommended for smaller group settings, as larger settings may lead to idea overload. Toggle off for a shorter, focused experience, showing a maximum of 10 ideas per participant for feedback"
                }
              />
            </Box>
            {numParticipants > 5 && question.exposeAll && (
              <Typography variant="caption" color={theme.palette.error.main}>
                Risk of information overload with a group this large. We
                recommend disabling this toggle before submitting.
              </Typography>
            )}
          </Box>
        )} */}
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => addQuestion(index)}
          disabled={numQuestions === maxQuestions || isLoadingCreate}
        >
          <AddIcon />
        </IconButton>

        <IconButton
          onClick={() => moveUp(index)}
          disabled={index === 0 || isLoadingCreate}
        >
          <ArrowUpwardIcon />
        </IconButton>

        <IconButton
          onClick={() => moveDown(index)}
          disabled={index === numQuestions - 1 || isLoadingCreate}
        >
          <ArrowDownwardIcon />
        </IconButton>

        <IconButton
          onClick={() => deleteQuestion(index)}
          disabled={(index === 0 && numQuestions === 1) || isLoadingCreate}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Question;
