import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  rateQuestion,
  rateQuestionText,
  addMessagesToPinecone,
  summarizeAndIdeas,
} from "../../../actions/questionActions";
import { useDispatch } from "react-redux";
import { RatingButtonGroup } from "./buttonRating";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const AlertDialog = ({
  openAlert,
  title,
  description,
  buttonText,
  onConfirm,
  questionId,
  userId,
  ratingQuestion1,
  ratingQuestion2,
  ratingQuestion3,
  ratingQuestion4,
  ratingQuestion5,
  skipped,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(openAlert);

  const [textRating, setTextRating] = useState("");

  const [rating1, setRating1] = useState(3);
  const [rating2, setRating2] = useState(3);
  const [rating3, setRating3] = useState(3);
  const [rating4, setRating4] = useState(3);
  const [rating5, setRating5] = useState(3);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRating1(3);
    setRating2(3);
    setRating3(3);
    setRating4(3);
    setRating5(3);
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
    setRating1(3);
    setRating2(3);
    setRating3(3);
    setRating4(3);
    setRating5(3);
  };

  const handleSubmitConfirm = () => {
    // dispatch(
    //   rateQuestion({
    //     question: questionId,
    //     user: userId,
    //     question_text: ratingQuestion1,
    //     rating: rating1,
    //     skipped: skipped,
    //   })
    // );
    // dispatch(
    //   rateQuestion({
    //     question: questionId,
    //     user: userId,
    //     question_text: ratingQuestion2,
    //     rating: rating2,
    //     skipped: skipped,
    //   })
    // );

    // dispatch(
    //   rateQuestion({
    //     question: questionId,
    //     user: userId,
    //     question_text: ratingQuestion3,
    //     rating: rating3,
    //     skipped: skipped,
    //   })
    // );
    // dispatch(
    //   rateQuestion({
    //     question: questionId,
    //     user: userId,
    //     question_text: ratingQuestion4,
    //     rating: rating4,
    //     skipped: skipped,
    //   })
    // );
    // dispatch(
    //   rateQuestion({
    //     question: questionId,
    //     user: userId,
    //     question_text: ratingQuestion5,
    //     rating: rating5,
    //     skipped: skipped,
    //   })
    // );
    // dispatch(
    //   rateQuestionText({
    //     question: questionId,
    //     user: userId,
    //     text: textRating,
    //   })
    // );
    // dispatch(
    //   addMessagesToPinecone({
    //     question: questionId,
    //     user: userId,
    //   })
    // );
    // dispatch(
    //   summarizeAndIdeas({
    //     question: questionId,
    //     user: userId,
    //   })
    // );
    handleConfirm();
  };

  const handleTextRatingChange = (e) => {
    setTextRating(e.target.value);
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          marginLeft: "10px",
          height: "40px",
          backgroundColor: theme.palette.error.dark,
          color: "white",
        }}
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>

          {/* <br />
          <RatingButtonGroup
            rating={rating1}
            setRating={setRating1}
            ratingQuestion={ratingQuestion1}
          />
          <br />
          <RatingButtonGroup
            rating={rating2}
            setRating={setRating2}
            ratingQuestion={ratingQuestion2}
          /> */}

          {/* <RatingButtonGroup
            rating={rating3}
            setRating={setRating3}
            ratingQuestion={ratingQuestion3}
          /> */}

          {/* <RatingButtonGroup
            rating={rating4}
            setRating={setRating4}
            ratingQuestion={ratingQuestion4}
          />
          <br /> */}
          {/* <RatingButtonGroup
            rating={rating5}
            setRating={setRating5}
            ratingQuestion={ratingQuestion5}
          />
          <br /> */}
          {/* <Typography variant="body1" gutterBottom>
            Do you have any additional feedback? (optional)
          </Typography>

          <TextField
            label="Feeback"
            variant="outlined"
            value={textRating.slice(0, 2000)}
            onChange={handleTextRatingChange}
            fullWidth
            multiline
            rows={3}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmitConfirm}
            autoFocus
            disabled={
              rating1 == null ||
              rating2 == null ||
              rating3 == null ||
              rating4 == null ||
              rating5 == null
            }
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
