import { Helmet } from "react-helmet-async";

import LoginView from "src/sections/_auth/view/login-view";

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <LoginView />
    </>
  );
}
