import React from "react";

import Box from "@mui/material/Box";
import Timeline from "@mui/lab/Timeline";
import TimelineDot from "@mui/lab/TimelineDot";
import Container from "@mui/material/Container";
import TimelineItem from "@mui/lab/TimelineItem";
import Typography from "@mui/material/Typography";
import TimelineContent from "@mui/lab/TimelineContent";
import { alpha, useTheme } from "@mui/material/styles";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Button } from "@mui/material";
import Iconify from "src/components/iconify";
import { bgGradient } from "src/theme/css";
import { useResponsive } from "src/hooks/use-responsive";

// ----------------------------------------------------------------------

const TIMELINES = [
  {
    step: "STEP 1",
    title: "Define Your Inquiry",
    description:
      "Initiate by simply pinpointing your core inquiry. Setting up Tacit is straightforward: no complex survey design, just a clear focus on the topic you're delving into.",
  },
  {
    step: "STEP 2",
    title: "Effortless Engagement",
    description:
      "Distribute your unique Tacit link to participants. Then, relax as our AI conducts in-depth interviews, probing your topic without any manual effort on your part.",
  },
  {
    step: "STEP 3",
    title: "Collective Insights",
    description:
      "Tacit’s AI doesn’t just gather responses; it engages your entire group, harnessing their collective intelligence to bring the most valuable insights to the forefront.",
  },
  {
    step: "STEP 4",
    title: "Comprehensive Synthesis",
    description:
      "Receive a dynamic dashboard summarizing the conversation's entire breadth, with key insights and debated topics clearly highlighted for strategic decision-making.",
  },
];

const COLORS = [
  "primary.lighter",
  "primary.light",
  "primary.main",
  "primary.dark",
];

// ----------------------------------------------------------------------

const HowItWorks = React.forwardRef((props, ref) => {
  const { targetRef } = props;
  const theme = useTheme();

  const mdUp = useResponsive("up", "md");
  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div ref={ref}></div>
      <Box
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.grey[900], 0.8),
            imgUrl: "/assets/background/overlay_2.jpg",
          }),
          color: "common.white",
          py: { xs: 10, md: 15 },
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            How Tacit Works
          </Typography>

          <Timeline position={mdUp ? "alternate" : "right"}>
            {TIMELINES.map((value, index) => (
              <TimelineItem
                key={value.title}
                sx={{
                  "&:before": {
                    ...(!mdUp && { display: "none" }),
                  },
                }}
              >
                <TimelineSeparator>
                  <TimelineDot sx={{ bgcolor: `${COLORS[index]}` }} />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent sx={{ pb: { xs: 3, md: 5 } }}>
                  <Typography
                    variant="overline"
                    sx={{ color: `${COLORS[index]}` }}
                  >
                    {value.step}
                  </Typography>

                  <Typography variant="h4" sx={{ mt: 0.5, mb: 1 }}>
                    {value.title}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      opacity: 0.72,
                      maxWidth: { md: 360 },
                      ...(index % 2 && {
                        ml: "auto",
                      }),
                    }}
                  >
                    {value.description}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <Button
            onClick={handleScroll}
            size="small"
            color="inherit"
            endIcon={<Iconify icon="carbon:chevron-right" />}
            sx={{ width: "auto" }} // Adjust the width as needed
          >
            Key Benefits
          </Button>
        </Box>
      </Box>
    </>
  );
});

export default HowItWorks;
