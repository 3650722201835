import * as api from "../api/index.js";

export const createResultsFeedbackForm =
  (formData, conversationResultsId) => async (dispatch) => {
    try {
      const { data } = await api.createResultsFeedbackForm(
        formData,
        conversationResultsId
      );
      console.log(data);
    } catch (error) {
      console.log(error.message);
    }
  };
