import { lazy } from "react";

// const HomePage = lazy(() => import("../../pages/homePage"));
// const ConversationPage = lazy(() => import("../../pages/conversationPage"));
// const LinksPage = lazy(() => import("../../pages/linksPage"));
// const QuestionsPage = lazy(() => import("../../pages/questionsPage"));
// const ResultsPage = lazy(() => import("../../pages/resultsPage"));
// const QuestionsFinishedPage = lazy(() =>
//   import("../../pages/questionsFinishedPage")
// );
// const ResultQuestionPage = lazy(() => import("../../pages/resultQuestionPage"));

import CreateConversationPage from "src/pages/create-conversation/create-conversation";
import HomePage from "src/pages/home";
import LinksPage from "src/pages/create-conversation/links";
import QuestionsPage from "src/pages/questionsPage";
import ConversationFinishedPage from "src/pages/conversation-finished/conversation-finished";
import TrialPage from "src/pages/trial/trial";

export const appRoutes = [
  { path: "/", element: <HomePage />, index: true },
  { path: "create", element: <CreateConversationPage /> },
  { path: "links/:conversation/:results", element: <LinksPage /> },
  { path: "questions/:conversation", element: <QuestionsPage /> },

  { path: "finished", element: <ConversationFinishedPage /> },
  { path: "trial", element: <TrialPage /> },
];
