import ResultsQuestionChatView from "src/sections/_results/view/results-question-chat-view";

const ResultsQuestionChatPage = () => {
  return (
    <>
      <ResultsQuestionChatView />
    </>
  );
};

export default ResultsQuestionChatPage;
