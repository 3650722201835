import AccountCreatedConversationsView from "src/sections/_account/view/account-created-conversations-view";

// ----------------------------------------------------------------------

export default function AccountCreatedConversationsPage() {
  return (
    <>
      <AccountCreatedConversationsView />
    </>
  );
}
