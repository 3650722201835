import * as api from "../api/index.js";

import {
  START_LOADING,
  END_LOADING,
  FETCH_CONVERSATION,
  CREATE_CONVERSATION,
  START_LOADING_CREATE_CONVERSATION,
  END_LOADING_CREATE_CONVERSATION,
  FETCH_CONVERSATION_RESULTS,
} from "./actionTypes.js";

export const getConversation = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchConversations(id);
    console.log(data);
    dispatch({ type: FETCH_CONVERSATION, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const createConversation = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_CREATE_CONVERSATION });
    const { data } = await api.createConversation(formData);
    console.log("HERE");
    console.log(data);
    dispatch({ type: CREATE_CONVERSATION, payload: data });
    dispatch({ type: END_LOADING_CREATE_CONVERSATION });
    return data;
  } catch (error) {
    dispatch({ type: END_LOADING_CREATE_CONVERSATION });
    console.log(error);
    throw error;
  }
};

export const getUserConversationResults = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchResultsForUser(id);
    dispatch({ type: FETCH_CONVERSATION_RESULTS, payload: data });
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const tacitAnalysis = (conversatopnId) => async (dispatch) => {
  try {
    const { data } = await api.tacitAnalysis(conversatopnId);
    console.log(data);
  } catch (error) {
    console.log(error.message);
  }
};

export const removeUserTask = (conversation_event_id) => async (dispatch) => {
  try {
    const { data } = await api.removeUserTask(conversation_event_id);
    console.log(data);
  } catch (error) {
    console.log(error.message);
  }
};
