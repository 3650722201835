import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import Iconify from "src/components/iconify";

import Image from "src/components/image";
import { bgGradient } from "src/theme/css";
import { useResponsive } from "src/hooks/use-responsive";
import { createUser } from "src/actions/userActions";

import TraitsSelector from "./start-traits";
import { set } from "nprogress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, FormControlLabel } from "@mui/material";
// ----------------------------------------------------------------------

export default function StartLanding({
  targetRef,
  rejoinPhase1,
  setRejoinPhase1,
}) {
  const theme = useTheme();

  const mdUp = useResponsive("up", "md");

  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const { conversation: conversationID } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [traitSelected, setTraitSelected] = useState(false);

  const handleChange = (event) => {
    setRejoinPhase1(event.target.checked);
  };

  const { isLoading: isLoadingConversation, conversation } = useSelector(
    (state) => state.conversationData
  );

  const { isLoading: isLoadingQuestion, questions } = useSelector(
    (state) => state.questionData
  );

  const totalDepth = questions?.reduce((sum, question) => {
    if (question.phase_2) {
      return sum + question.depth + 2;
    } else {
      return sum + question.depth;
    }
  }, 0);

  const totalTime = totalDepth;

  const validateEmail = (email) => {
    if (email === "") {
      return true;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Use useEffect so when the email is changed the error message is cleared
  useEffect(() => {
    setEmailErrorMessage("");
  }, [email]);

  useEffect(() => {
    if (conversation?.status === "analysis") {
      setErrorMessage(
        "Tacit analysis has already begun. Please wait for the next phase to begin."
      );
    }
  }, [conversation]);

  const firstSubmit = async (event) => {
    event.preventDefault();
    if (username.trim() !== "") {
      if (
        email.trim() === "" &&
        ((conversation?.phase_2 && !conversation.data_analysis_complete) ||
          rejoinPhase1)
      ) {
        setIsDialogOpen(true);
        return;
      }
      if (!validateEmail(email)) {
        setEmailErrorMessage("Please enter a valid email.");
        return;
      }
      handleSubmit();
    }
  };

  const handleSubmit = async (event) => {
    handleCloseDialog();

    if (username.trim() !== "") {
      const result = await dispatch(
        createUser({
          name: username,
          conversation: conversationID,
          email: email,
          traits: selectedTraits,
        })
      );

      if (result.error) {
        setErrorMessage(result.error);
        setUsername("");
      } else {
        setUsername("");
      }
    }
  };

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box
      sx={{
        py: 5,
        // ...bgGradient({
        //   color: alpha(theme.palette.background.default, 0.9),
        //   imgUrl: "/assets/background/overlay_1.jpg",
        // }),
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0),
          imgUrl: "/assets/background/blue_background.svg",
        }),
        overflow: "hidden",
      }}
    >
      <Container
        sx={{
          py: 15,
          display: { md: "flex" },
          alignItems: { md: "center" },
          height: { md: `100vh` },
        }}
      >
        <Grid container columnSpacing={{ xs: 0, md: 10 }}>
          <Grid
            xs={12}
            md={6}
            lg={5}
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography variant="overline" sx={{ color: "primary.darker" }}>
              Welcome To The Conversation
            </Typography>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
              {conversation?.title}
            </Typography>
            <Typography variant="h7" sx={{ color: "text.secondary" }}>
              {conversation?.description}
            </Typography>
            <Stack direction={"column"}>
              <Typography variant="h7" sx={{ mt: 1 }}>
                Conversation: {questions?.length} topic
                {questions?.length > 1 && "s"}
              </Typography>
              <Typography variant="h7" sx={{ mb: 1 }}>
                Estimated Time: {totalTime} minute{totalTime > 1 && "s"}
              </Typography>
            </Stack>
            {conversation?.traits.length > 0 && (
              <TraitsSelector
                traits={conversation?.traits}
                selectedTraits={selectedTraits}
                setSelectedTraits={setSelectedTraits}
                traitSelected={traitSelected}
                setTraitSelected={setTraitSelected}
              />
            )}

            <Stack direction="column" sx={{ mt: 2 }}>
              {((conversation?.phase_2 &&
                !conversation.data_analysis_complete) ||
                rejoinPhase1) && (
                <Stack
                  justifyContent={{ xs: "center", md: "unset" }}
                  alignItems={{ xs: "center", md: "unset" }}
                >
                  <InputBase
                    fullWidth
                    placeholder="Enter your email (optional)"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                    disabled={errorMessage !== ""}
                    inputProps={{ style: { fontSize: 16 } }} // font size of input text
                    sx={{
                      mt: 1,
                      pr: 0.5,
                      pl: 1.5,
                      height: 56,
                      maxWidth: 560,
                      borderRadius: 1,
                      bgcolor: "common.white",
                      transition: (theme) =>
                        theme.transitions.create(["box-shadow"]),
                      [`&.${inputBaseClasses.focused}`]: {
                        boxShadow: (theme) => theme.customShadows.z4,
                      },
                    }}
                  />
                  {emailErrorMessage && (
                    <Typography sx={{ color: "error.main" }}>
                      {emailErrorMessage}
                    </Typography>
                  )}
                </Stack>
              )}

              <Stack
                spacing={3}
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "center", md: "unset" }}
                justifyContent={{ xs: "center", md: "unset" }}
                sx={{ mt: 1 }}
              >
                <InputBase
                  fullWidth
                  placeholder="Enter your name"
                  onChange={(event) => setUsername(event.target.value)}
                  value={username}
                  disabled={errorMessage !== ""}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        type="submit"
                        onClick={firstSubmit}
                        disabled={
                          errorMessage !== "" ||
                          username === "" ||
                          (conversation?.traits.length > 0 && !traitSelected)
                        }
                      >
                        Join
                      </Button>
                    </InputAdornment>
                  }
                  inputProps={{ style: { fontSize: 16 } }} // font size of input text
                  sx={{
                    mt: 1,
                    pr: 0.5,
                    pl: 1.5,
                    height: 56,
                    maxWidth: 560,
                    borderRadius: 1,
                    bgcolor: "common.white",
                    transition: (theme) =>
                      theme.transitions.create(["box-shadow"]),
                    [`&.${inputBaseClasses.focused}`]: {
                      boxShadow: (theme) => theme.customShadows.z4,
                    },
                  }}
                />
              </Stack>
            </Stack>
            {conversation?.anonymous ? (
              <Stack sx={{ mt: 1 }}>
                <Typography>This conversation is anonymous</Typography>
              </Stack>
            ) : (
              <Stack sx={{ mt: 1 }}>
                <Typography>This conversation is not anonymous</Typography>
              </Stack>
            )}
            {errorMessage !== "" && (
              <div>
                <br />
                <Typography variant="h6" align="center">
                  {errorMessage}
                </Typography>
              </div>
            )}
            {conversation?.data_analysis_complete &&
              conversation?.allow_rejoin && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rejoinPhase1}
                      onChange={handleChange}
                      name="rejoinPhase1"
                      color="primary"
                    />
                  }
                  sx={{ mt: 1 }}
                  label="The conversation is currently in collective validation phase, select this box to join the ideation phase"
                />
              )}

            <Button
              onClick={handleScroll}
              size="small"
              color="inherit"
              endIcon={<Iconify icon="carbon:chevron-right" />}
              sx={{ mt: 5 }}
            >
              Learn more
            </Button>
          </Grid>

          {mdUp && (
            <Grid xs={12} md={6} lg={7}>
              <Image
                visibleByDefault
                disabledEffect
                alt="marketing market"
                src="/assets/illustrations/tacit_logo_white1.png"
              />
            </Grid>
          )}
        </Grid>
      </Container>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{"Missing Email"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To be reinvited to the{" "}
            <span
              style={{ color: theme.palette.primary.main, fontWeight: "bold" }}
            >
              Collective Validation
            </span>{" "}
            phase of this conversation, please input your email.
          </DialogContentText>
          <DialogContentText>
            This will enable you to rejoin the conversation and anonymously
            interact with other participants' ideas to help determine the best
            final solution.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant="outlined">
            Continue
          </Button>
          <Button onClick={handleCloseDialog} variant="contained">
            Input Email
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
