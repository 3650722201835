// TODO: Use the same same chatbox component for both the chat and the results
import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Container,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getQuestionsAnswers } from "../../actions/resultActions";
import { askResultQuestion } from "../../actions/resultActions";
import { addResultsMessage } from "../../actions/resultsChatActions";

const Chatbox = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState([]);
  const [typingMessage, setTypingMessage] = useState(null);

  const { results: resultConversationId, question: questionId } = useParams();
  const { isLoading: isLoadingMessages, answer } = useSelector(
    (state) => state.askData
  );
  const { isLoading: isLoadingQuestionsAnswers, messages: questionsAnswers } =
    useSelector((state) => state.askQuestionData);

  // const { messages: currentChat } = useSelector(
  //   (state) => state.resultsChatData.messages[questionId] || []
  // );

  const currentChat = useSelector((state) => state.resultsChatData.messages);

  // useSelector((state) => console.log(state));

  useEffect(() => {
    setConversation(currentChat[questionId] || []);
  }, [location, currentChat]);

  useEffect(() => {}, [conversation]);

  const getPrevConversation = async () => {
    const prevConversation = await dispatch(
      getQuestionsAnswers(resultConversationId, questionId)
    );
    const transformedData = prevConversation?.map((message, index) => {
      return {
        id: index,
        from: message.sender_type === "U" ? "user" : "Tacit AI",
        text: message.text,
      };
    });

    setConversation(transformedData);
  };

  const handleSendMessage = async () => {
    const sentMessage = message;
    dispatch(
      addResultsMessage(questionId, {
        id: conversation.length + 1,
        from: "user",
        text: sentMessage,
      })
    );
    setMessage("");
    if (message.trim() !== "") {
      // setConversation((prevConversation) => [
      //   ...prevConversation,
      //   { id: prevConversation.length + 1, from: "user", text: sentMessage },
      // ]);

      let gpt_response;
      try {
        gpt_response = await dispatch(
          askResultQuestion(resultConversationId, questionId, {
            query: sentMessage,
          })
        );
      } catch (error) {
        console.error(`Error: ${error}`);
        gpt_response = { answer: "Sorry, an error occured" };
      }
      // Start a new typing animation
      let typingIndex = 0;
      setTypingMessage({
        id: conversation.length + 2, // This should match the ID of the message that will eventually be added
        from: "chatGPT",
        text: "",
      });

      const words = gpt_response?.answer.split(" ");
      let wordIndex = 0;

      const typingInterval = setInterval(() => {
        wordIndex++;
        setTypingMessage((prevMessage) => ({
          ...prevMessage,
          text:
            prevMessage.text +
            (prevMessage.text ? " " : "") +
            words[wordIndex - 1],
        }));

        // If all words have been added, add the message to the conversation and stop the interval
        if (wordIndex >= words.length) {
          clearInterval(typingInterval);
          dispatch(
            addResultsMessage(questionId, {
              id: conversation.length + 2,
              from: "chatGPT",
              text: gpt_response?.answer,
            })
          );
          // setConversation((prevConversation) => [
          //   ...prevConversation,
          //   {
          //     id: prevConversation.length + 1,
          //     from: "chatGPT",
          //     text: gpt_response?.answer,
          //   },
          // ]);
          setTypingMessage(null);
        }
      }, 50);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Ask Tacit AI a Question
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        height="400px"
        border={1}
        borderColor="grey.500"
        borderRadius={2}
        backgroundColor="white"
        p={2}
        m={2}
      >
        <Box flex="1 1 auto" overflow="auto" mb={2}>
          {conversation?.map(({ id, from, text }, index) => (
            <Box
              key={id}
              alignSelf={from === "user" ? "flex-end" : "flex-start"}
              mb={2}
            >
              <Typography variant="subtitle2">
                {from === "user" ? "You" : "Tacit AI"}
              </Typography>

              <Box
                maxWidth="70%"
                p={1}
                border={1}
                borderRadius={2}
                borderColor="grey.500"
                bgcolor={from === "user" ? "#bbdefb" : "grey.300"}
                style={{ wordWrap: "break-word" }}
              >
                <Typography variant="body2">{text}</Typography>
              </Box>
            </Box>
          ))}
          {typingMessage && (
            <Box
              key={typingMessage.id}
              alignSelf={
                typingMessage.from === "user" ? "flex-end" : "flex-start"
              }
              mb={2}
            >
              <Typography variant="subtitle2">
                {typingMessage.from === "user" ? "You" : "Tacit AI"}
              </Typography>
              <Box
                maxWidth="70%"
                p={1}
                border={1}
                borderRadius={2}
                borderColor="grey.500"
                bgcolor={typingMessage.from === "user" ? "#CAF0F8" : "grey.300"}
                style={{ wordWrap: "break-word" }}
              >
                <Typography variant="body2">{typingMessage.text}</Typography>
              </Box>
            </Box>
          )}

          {isLoadingMessages && <CircularProgress />}
        </Box>
        <Box display="flex" alignItems="flex-end">
          <TextField
            variant="outlined"
            size="small"
            multiline
            maxRows={3}
            value={message.slice(0, 750)}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // prevents the addition of a new line when enter is pressed
                handleSendMessage();
              }
            }}
            InputProps={{
              style: {
                fontSize: 16,
                // backgroundColor: theme.palette.grey[100],
              },
            }}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            style={{
              marginLeft: "10px",
              height: "40px",
            }}
          >
            {/* <ArrowUpwardIcon /> */}
            Ask
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Chatbox;
