import {
  ASK_QUESTION,
  START_LOADING_ASK,
  END_LOADING_ASK,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  answer: null,
};

const askQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_ASK:
      return { ...state, isLoading: true };
    case END_LOADING_ASK:
      return { ...state, isLoading: false };
    case ASK_QUESTION:
      return { ...state, answer: action.payload };
    default:
      return state;
  }
};

export default askQuestionReducer;
