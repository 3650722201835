import AccountPaymentView from "src/sections/_account/view/account-payment-view";
// ----------------------------------------------------------------------

export default function AccountPaymentPage() {
  return (
    <>
      <AccountPaymentView />
    </>
  );
}
