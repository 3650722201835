import {
  FETCH_CONVERSATION_STATS,
  START_LOADING_CONVERSATION_STATS,
  END_LOADING_CONVERSATION_STATS,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  stats: null,
};

const conversationStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_CONVERSATION_STATS:
      return { ...state, isLoading: true };
    case END_LOADING_CONVERSATION_STATS:
      return { ...state, isLoading: false };
    case FETCH_CONVERSATION_STATS:
      return { ...state, stats: action.payload };
    default:
      return state;
  }
};

export default conversationStatsReducer;
