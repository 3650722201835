import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function ResultsUserResponsesAbout() {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          p: 3,
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography variant="h2">
          Participant Conversation Transcripts
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          Find and sort through the messages from your conversation
          participants.
        </Typography>
      </Stack>
    </Stack>
  );
}
