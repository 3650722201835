import * as api from "../api/index.js";

import {
  START_LOADING_CREATED_CONVERSATIONS,
  END_LOADING_CREATED_CONVERSATIONS,
  FETCH_CREATED_CONVERSATIONS,
  START_LOADING_CREATED_CONVERSATION,
  END_LOADING_CREATED_CONVERSATION,
  FETCH_CREATED_CONVERSATION,
  CHANGE_CONVERSATION_STATUS,
  CHANGE_CONVERSATION_REJOIN,
  DELETE_CONVERSATION,
  ADD_ACCOUNT,
  REMOVE_ACCOUNT,
  START_LOADING_ADD_ACCOUNT,
  END_LOADING_ADD_ACCOUNT,
  START_LOADING_REMOVE_ACCOUNT,
  END_LOADING_REMOVE_ACCOUNT,
} from "./actionTypes.js";

export const getCreatedConversations = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_CREATED_CONVERSATIONS });
    const { data } = await api.getCreatedConversations();

    dispatch({ type: FETCH_CREATED_CONVERSATIONS, payload: data });
    dispatch({ type: END_LOADING_CREATED_CONVERSATIONS });
  } catch (error) {
    console.log(error);
  }
};

export const getCreatedConversation = (conversationId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_CREATED_CONVERSATION });
    const { data } = await api.getCreatedConversation(conversationId);
    console.log(data);
    dispatch({ type: FETCH_CREATED_CONVERSATION, payload: data });
    dispatch({ type: END_LOADING_CREATED_CONVERSATION });
  } catch (error) {
    console.log(error);
  }
};

export const changeConversationStatus =
  (conversationId, status) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_CREATED_CONVERSATION });
      const { data } = await api.changeConversationStatus(
        conversationId,
        status
      );

      dispatch({ type: CHANGE_CONVERSATION_STATUS, payload: data });
      dispatch({ type: END_LOADING_CREATED_CONVERSATION });
    } catch (error) {
      console.log(error);
    }
  };

export const changeConversationRejoin =
  (conversationId, rejoin) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_CREATED_CONVERSATION });
      const { data } = await api.changeConversationRejoin(
        conversationId,
        rejoin
      );

      dispatch({ type: CHANGE_CONVERSATION_REJOIN, payload: data });
      dispatch({ type: END_LOADING_CREATED_CONVERSATION });
    } catch (error) {
      console.log(error);
    }
  };

export const deleteAccountConversation =
  (conversationId) => async (dispatch) => {
    try {
      const { data } = await api.deleteAccountConversation(conversationId);

      dispatch({ type: DELETE_CONVERSATION });
    } catch (error) {
      console.log(error);
    }
  };

export const addAcountToConversation = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_ADD_ACCOUNT });
    const { data } = await api.addAcountToConversation(formData);

    dispatch({ type: ADD_ACCOUNT, payload: data });
    dispatch({ type: END_LOADING_ADD_ACCOUNT });
    return data;
  } catch (error) {
    dispatch({ type: END_LOADING_ADD_ACCOUNT });
    console.log(error);
  }
};

export const removeAcountFromConversation = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_REMOVE_ACCOUNT });
    const { data } = await api.removeAcountFromConversation(formData);

    dispatch({ type: REMOVE_ACCOUNT, payload: data });
    dispatch({ type: END_LOADING_REMOVE_ACCOUNT });
    return data;
  } catch (error) {
    dispatch({ type: END_LOADING_REMOVE_ACCOUNT });
    console.log(error);
  }
};

export const removeSelfFromConversation =
  (conversationId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_REMOVE_ACCOUNT });
      const { data } = await api.removeSelfFromConversation(conversationId);

      dispatch({ type: REMOVE_ACCOUNT, payload: data });
      dispatch({ type: END_LOADING_REMOVE_ACCOUNT });
      return data;
    } catch (error) {
      dispatch({ type: END_LOADING_REMOVE_ACCOUNT });
      console.log(error);
    }
  };
