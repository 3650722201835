import React, { useState, useEffect } from "react";
import { TotalRatingsCard } from "src/components/dashboard/total-ratings-card";
import { BarCard } from "src/components/dashboard/bar-card";
import { Stack, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTheme from "@mui/material/styles/useTheme";
import { StackedQuestionRatings } from "src/components/dashboard/stacked-question-ratings";
import { PieIdeasRatings } from "src/components/dashboard/pie-chart-idea-ratings";
import Grid from "@mui/material/Grid";
import { RatingDistribution } from "src/components/dashboard/rating-distribution";
const ResultsIdeasPhase2 = () => {
  const theme = useTheme();

  const { isLoading: isLoadingIdeaStats, ideaStats } = useSelector(
    (state) => state.conversationIdeasData
  );
  return (
    <>
      <Container
        sx={{
          flexGrow: 1,
          textAlign: "left",
          display: "grid",
          gap: { xs: 5, md: 2 },
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          },
        }}
      >
        <BarCard
          title="Average Idea Rating"
          number={ideaStats?.average_rating}
          percent={ideaStats?.average_rating * 20}
          color={theme.palette.primary.main}
          icon="carbon:star"
          info="Average participant rating level across all ideas"
        />
        <TotalRatingsCard
          title="Agreement"
          value={ideaStats?.agreement}
          color={theme.palette.error.main}
          icon="carbon:asset"
          info="Average rating agreement across all conversation categories"
          percent={false}
        />

        <TotalRatingsCard
          title="Collective Validation Participation"
          value={ideaStats?.participation}
          color={theme.palette.warning.main}
          icon="carbon:group"
          info="Percent of participants who rejoined for Collective Validation"
          percent={true}
        />
        <TotalRatingsCard
          title="Total Categories"
          value={ideaStats?.num_categories}
          color={theme.palette.success.main}
          icon="carbon:list"
          info="Number of categories created by your conversation"
          percent={false}
        />
      </Container>
      <Grid
        container
        spacing={3}
        sx={{
          p: 3,
          textAlign: { xs: "center", md: "left" },
          display: "flex",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex", // Add this line
            flexDirection: "column",
          }}
        >
          <RatingDistribution
            chartSeries={ideaStats?.histogram} // Example data for ratings 1 to 5 with 0.5 intervals
            sx={{ height: "100%" }}
            title="Rating Distribution"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex", // Add this line
            flexDirection: "column",
          }}
        >
          <StackedQuestionRatings
            chartSeries={ideaStats?.ratings_per_question}
            sx={{ height: "100%" }}
            title="Ratings For Question"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ResultsIdeasPhase2;
