import { SplashScreen } from "src/components/loading-screen";
import LoginPage from "src/pages/auth/login";
import AuthBackgroundLayout from "src/layouts/auth/background";
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import ForgotPasswordPage from "src/pages/auth/forgot-password";
import RegisterPage from "src/pages/auth/register";
import ResetPasswordPage from "src/pages/auth/reset-password";
import ActivatePage from "src/pages/auth/activate";

export const authRoutes = [
  {
    path: "/",
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: "login",
        element: (
          <AuthBackgroundLayout>
            <LoginPage />
          </AuthBackgroundLayout>
        ),
      },
      {
        path: "signup",
        element: (
          <AuthBackgroundLayout>
            <RegisterPage />
          </AuthBackgroundLayout>
        ),
      },
      {
        path: "reset-password",
        element: (
          <AuthBackgroundLayout>
            <ForgotPasswordPage />
          </AuthBackgroundLayout>
        ),
      },
      {
        path: "password/reset/confirm/:uid/:token",
        element: (
          <AuthBackgroundLayout>
            <ResetPasswordPage />
          </AuthBackgroundLayout>
        ),
      },
      {
        path: "activate/:uid/:token",
        element: (
          <AuthBackgroundLayout>
            <ActivatePage />
          </AuthBackgroundLayout>
        ),
      },
    ],
  },
];
