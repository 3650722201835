import { Outlet } from "react-router-dom";

import AccountLayout from "src/layouts/account";
import AccountPersonalPage from "src/pages/account/personal";
import AccountCreatedConversationsPage from "src/pages/account/created-conversations";
import AccountPaymentPage from "src/pages/account/payment";
import AccountConversationPage from "src/pages/account/conversation";

export const accountRoutes = [
  {
    path: "/account",
    element: (
      <AccountLayout>
        <Outlet />
      </AccountLayout>
    ),
    children: [
      {
        path: "profile",
        element: <AccountPersonalPage />,
      },
      {
        path: "created-conversations",
        element: <AccountCreatedConversationsPage />,
      },
      {
        path: "payment",
        element: <AccountPaymentPage />,
      },
      {
        path: "conversation/:conversationId",
        element: <AccountConversationPage />,
      },

      //   { path: "summary/:question", element: <ResultsQuestionSummaryPage /> },
    ],
  },
];
