import React from "react";
import { LinearProgress } from "@mui/material";
import { Box, Typography } from "@mui/material";

const ProgressBar = ({ title, currentProgress, color }) => {
  return (
    <Box sx={{ width: "50%" }}>
      <Typography variant="h8">{title}</Typography>
      <LinearProgress
        color={color}
        variant="determinate"
        value={currentProgress}
        sx={{ height: "25px", borderRadius: "10px" }}
      />
    </Box>
  );
};

export default ProgressBar;
