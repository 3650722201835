import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Iconify from "src/components/iconify";
import { useResponsive } from "src/hooks/use-responsive";

import Nav from "./nav";

// ----------------------------------------------------------------------

export default function ResultsLayout({ children }) {
  const mdUp = useResponsive("up", "md");

  return (
    <>
      {/*Can change container to somethinf else (Box) to make it wider */}
      <Box
        sx={{
          my: 10,
          paddingLeft: { xs: 0, md: 5 },
          paddingRight: { xs: 0, md: 5 },
        }}
      >
        <Stack
          direction={{
            md: "row",
          }}
          alignItems={{
            md: "flex-start",
          }}
          sx={{
            mt: 5,
            mb: {
              xs: 8,
              md: 10,
            },
          }}
        >
          <Nav />

          <Box
            sx={{
              flexGrow: 1,

              width: { md: `calc(100% - ${280}px)` },
            }}
          >
            {children}
          </Box>
        </Stack>
      </Box>
    </>
  );
}

ResultsLayout.propTypes = {
  children: PropTypes.node,
};
