import { SET_IDEAS_TABLE_STATE } from "src/actions/actionTypes";

const initialState = {
  tab: "All Ideas",
  order: "asc",
  orderBy: "orderId",
  searchTerm: "",
  page: 0,
  rowsPerPage: 5,
  categories: [],
};

const ideasTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IDEAS_TABLE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default ideasTableReducer;
