import * as api from "../api/index.js";

import {
  START_LOADING,
  END_LOADING,
  FETCH_QUESTIONS,
  FETCH_QUESTION,
} from "./actionTypes.js";

export const getQuestions = (conversationId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchQuestions(conversationId);
    console.log(data);
    dispatch({ type: FETCH_QUESTIONS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.message);
  }
};

export const getQuestion = (pk) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchQuestion(pk);
    console.log(data);
    dispatch({ type: FETCH_QUESTION, payload: { pk, data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.message);
  }
};

export const rateQuestion = (formdata) => async (dispatch) => {
  try {
    const { data } = await api.rateQuestion(formdata);
    console.log(data);
  } catch (error) {
    console.log(error.message);
  }
};

export const rateQuestionText = (formdata) => async (dispatch) => {
  try {
    const { data } = await api.rateQuestionText(formdata);
    console.log(data);
  } catch (error) {
    console.log(error.message);
  }
};

export const addMessagesToPinecone = (formdata) => async (dispatch) => {
  try {
    const { data } = await api.addMessagesToPinecone(formdata);
    console.log(data);
  } catch (error) {
    console.log(error.message);
  }
};

export const summarizeAndIdeas = (formdata) => async (dispatch) => {
  try {
    const { data } = await api.summarizeAndIdeas(formdata);
    console.log(data);
  } catch (error) {
    console.log(error.message);
  }
};
