import axios from "axios";

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  LOGOUT,
  START_GOOGLE_AUTH,
  END_GOOGLE_AUTH,
} from "./actionTypes";
import { th } from "date-fns/locale";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://tacit-backend-8fdf693eb148.herokuapp.com/",
  headers: {
    "X-API-KEY": process.env.REACT_APP_API_KEY_TACIT,
  },
});

export const load_user = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await API.get("/auth/users/me/", config);
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

// export const googleAuthenticate = (state, code) => async (dispatch) => {
//   if (state && code && !localStorage.getItem("access")) {
//     const config = {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//       },
//     };
//     const details = {
//       state: state,
//       code: code,
//     };
//     const formBody = Object.keys(details)
//       .map(
//         (key) =>
//           encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
//       )
//       .join("&");
//     try {
//       console.log("here");
//       const res = await axios.post(
//         `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?${formBody}`,
//         config
//       );
//       dispatch({
//         type: GOOGLE_AUTH_SUCCESS,
//         payload: res.data,
//       });
//       dispatch(load_user());
//     } catch (err) {
//       dispatch({
//         type: GOOGLE_AUTH_FAIL,
//       });
//       throw err;
//     }
//   }
// };

export const googleAuthenticate = (code, navigate) => async (dispatch) => {
  if (code && !localStorage.getItem("access")) {
    try {
      dispatch({ type: START_GOOGLE_AUTH });

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/google/`,
        {
          code: code,
        }
      );

      dispatch({
        type: GOOGLE_AUTH_SUCCESS,
        payload: res.data,
      });

      navigate("/account/created-conversations");
      dispatch({ type: END_GOOGLE_AUTH });
    } catch (err) {
      dispatch({
        type: GOOGLE_AUTH_FAIL,
      });
      dispatch({ type: END_GOOGLE_AUTH });
      throw err;
    }
  } else {
    console.log("SDFSDF");
  }
};

export const checkAuthenticated = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ token: localStorage.getItem("access") });
    try {
      const res = await API.post("/auth/jwt/verify/", body, config);
      if (res.data.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  try {
    const res = await API.post("/auth/jwt/create/", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(load_user());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    throw err;
  }
};

export const signup =
  (first_name, last_name, email, password, re_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      password,
      re_password,
    });
    try {
      const res = await API.post("/auth/users/", body, config);
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: res.data,
      });
      return "Success";
    } catch (err) {
      // Extract the error message from the response
      let errorMessage = "An error occurred during signup.";
      if (err.response && err.response.data) {
        // Example to extract specific error message
        errorMessage = err.response.data.email
          ? err.response.data.email[0]
          : errorMessage;
      }
      dispatch({
        type: SIGNUP_FAIL,
      });
      return errorMessage;
    }
  };

export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ uid, token });
  try {
    await API.post("/auth/users/activation/", body, config);
    dispatch({
      type: ACTIVATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email });
  try {
    await API.post("/auth/users/reset_password/", body, config);
    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
    });
  }
};

export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });
    try {
      await API.post("/auth/users/reset_password_confirm/", body, config);
      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAIL,
      });
    }
  };

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
