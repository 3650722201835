import {
  FETCH_ALL_QUESTION_STATS,
  START_LOADING_ALL_QUESTION_STATS,
  END_LOADING_ALL_QUESTION_STATS,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  questionStats: null,
};

const questionStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_ALL_QUESTION_STATS:
      return { ...state, isLoading: true };
    case END_LOADING_ALL_QUESTION_STATS:
      return { ...state, isLoading: false };
    case FETCH_ALL_QUESTION_STATS:
      return { ...state, questionStats: action.payload };
    default:
      return state;
  }
};

export default questionStatsReducer;
