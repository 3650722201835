import { Helmet } from "react-helmet-async";

import ForgotPasswordView from "src/sections/_auth/view/forgot-password-view";

// ----------------------------------------------------------------------

export default function ForgotPasswordPage() {
  return (
    <>
      <ForgotPasswordView />
    </>
  );
}
