import React from "react";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import Image from "src/components/image";
import { bgGradient } from "src/theme/css";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import Iconify from "src/components/iconify";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useResponsive } from "src/hooks/use-responsive";

const HomeSimple = () => {
  const theme = useTheme();
  const mdUp = useResponsive("up", "md");

  const { access, refresh, isAuthenticated, user, googleLoading } = useSelector(
    (state) => state.authData
  );

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0),
          imgUrl: "/assets/background/blue_background.svg",
        }),

        minHeight: "100vh",
        maxHeight: "100vh",
      }}
    >
      {googleLoading && (
        <Stack sx={{ pt: 10 }}>
          <CircularProgress />
        </Stack>
      )}
      <Stack direction="column" spacing={2} alignItems="center">
        <Image
          alt="tacit logo"
          src="/assets/logo/logo_words2.svg"
          sx={{ maxWidth: "600px" }}
        />
        <Button
          color="inherit"
          size="large"
          variant="contained"
          endIcon={<Iconify icon="carbon:launch" />}
          rel="noopener"
          component={RouterLink}
          to={isAuthenticated ? "/create" : "/login"}
          sx={{
            mt: {
              md: -8,
              xs: 0,
            },
          }}
        >
          Create Conversation
        </Button>
      </Stack>
    </Stack>
  );
};

export default HomeSimple;
