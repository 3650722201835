import * as api from "../api/index.js";

import {
  START_LOADING_CONVERSATION_IDEAS_STATS,
  END_LOADING_CONVERSATION_IDEAS_STATS,
  FETCH_CONVERSATION_IDEAS_STATS,
  FETCH_QUESTION_IDEAS_STATS,
  START_LOADING_QUESTION_IDEAS_STATS,
  END_LOADING_QUESTION_IDEAS_STATS,
  FETCH_IDEA_FEEDBACK,
  START_LOADING_IDEA_FEEDBACK,
  END_LOADING_IDEA_FEEDBACK,
} from "./actionTypes.js";

export const getConversationIdeaStats =
  (resultConversationId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_CONVERSATION_IDEAS_STATS });
      const { data } = await api.getConversationIdeaStats(resultConversationId);
      console.log(data);
      dispatch({ type: FETCH_CONVERSATION_IDEAS_STATS, payload: data });
      dispatch({ type: END_LOADING_CONVERSATION_IDEAS_STATS });
    } catch (error) {
      dispatch({ type: END_LOADING_CONVERSATION_IDEAS_STATS });
      console.log(error.message);
    }
  };

export const getQuestionIdeaStats =
  (resultConversationId, questionId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_QUESTION_IDEAS_STATS });
      const { data } = await api.getQuestionIdeaStats(
        resultConversationId,
        questionId
      );
      console.log(data);
      dispatch({
        type: FETCH_QUESTION_IDEAS_STATS,
        payload: { questionId, stats: data },
      });
      dispatch({ type: END_LOADING_QUESTION_IDEAS_STATS });
    } catch (error) {
      dispatch({ type: END_LOADING_QUESTION_IDEAS_STATS });
      console.log(error.message);
    }
  };

export const getIdeaFeedback = (questionId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_IDEA_FEEDBACK });
    const { data } = await api.getIdeaFeedback(questionId);
    console.log(data);
    dispatch({ type: FETCH_IDEA_FEEDBACK, payload: data });
    dispatch({ type: END_LOADING_IDEA_FEEDBACK });
  } catch (error) {
    dispatch({ type: END_LOADING_IDEA_FEEDBACK });
    console.log(error.message);
  }
};
