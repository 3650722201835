import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

export default function ResultsFeedbackAbout() {
  return (
    <Stack
      sx={{
        paddingLeft: { xs: 0, md: 5 },
        paddingRight: { xs: 0, md: 5 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          py: 5,

          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography variant="h2">What feedback do you have for us?</Typography>
        <Typography sx={{ color: "text.secondary" }}>
          We are always looking to improve our product. Please let us know about
          your experience with Tacit.
        </Typography>
      </Stack>
    </Stack>
  );
}
