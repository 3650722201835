import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TablePagination from "@mui/material/TablePagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import Scrollbar from "src/components/scrollbar";

import {
  stableSort,
  getComparator,
} from "src/sections/_results/question/utils";
import ResultsIdeaTableRow from "./results-idea-table-row";
import ResultsIdeaTableHead from "./results-idea-table-head";
import { getIdeaFeedback } from "src/actions/resultsIdeasActions";

// ----------------------------------------------------------------------

export const TABLE_HEAD = [
  { id: "feedback", label: "Feedback", width: 600 },
  { id: "rating", label: "Rating", width: 100 },
  { id: "related", label: "Related", width: 100 },
  { id: "created_at", label: "Created At", width: 160 },
];

export const SAMPLE_FEEDBACK = [
  {
    feedback: "This idea is really cool",
    rating: 5,
    related: 1,
    created_at: "2023-08-06T04:14:47.702722Z",
  },
  {
    feedback: "N/A",
    rating: 3,
    related: 1,
    created_at: "2023-08-06T04:28:58.797205Z",
  },
  {
    feedback: "N/A",
    rating: "N/A",
    related: 0,
    created_at: "2023-08-06T04:28:58.797205Z",
  },
];
export default function ResultsQuestionIdeasTable() {
  const dispatch = useDispatch();

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("orderId");

  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(0);

  const [dense, setDense] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    results: conversationResultsId,
    question: questionId,
    idea: ideaId,
  } = useParams();
  const { isLoading: isLoadingIdeaStats, ideaFeedback } = useSelector(
    (state) => state.ideaData
  );

  useEffect(() => {
    dispatch(getIdeaFeedback(ideaId));
  }, []);

  const handleSort = useCallback(
    (id) => {
      const isAsc = orderBy === id && order === "asc";
      if (id !== "") {
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(id);
      }
    },
    [order, orderBy]
  );

  const handleSelectRow = useCallback(
    (id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    },
    [selected]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - ideaFeedback?.feedback.length)
      : 0;

  return (
    <>
      {ideaFeedback && (
        <Stack
          sx={{
            paddingLeft: { xs: 0, md: 8 },
            paddingRight: { xs: 0, md: 8 },
          }}
        >
          <Typography variant="h5" sx={{ mb: 3 }}>
            Idea Reviews
          </Typography>

          <TableContainer
            sx={{
              overflow: "unset",

              [`& .${tableCellClasses.head}`]: {
                color: "text.primary",
              },
              [`& .${tableCellClasses.root}`]: {
                bgcolor: "background.default",
                borderBottomColor: (theme) => theme.palette.divider,
              },
            }}
          >
            <Scrollbar>
              <Table
                sx={{
                  minWidth: 720,
                }}
                size={dense ? "small" : "medium"}
              >
                <ResultsIdeaTableHead
                  order={order}
                  orderBy={orderBy}
                  onSort={handleSort}
                  headCells={TABLE_HEAD}
                  rowCount={ideaFeedback?.feedback.length}
                />

                <TableBody>
                  {stableSort(
                    ideaFeedback?.feedback,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <ResultsIdeaTableRow key={row.feedback_id} row={row} />
                    ))}

                  {emptyRows > 0 && (
                    <TableRow
                      sx={{
                        height: (dense ? 36 : 57) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              page={page}
              component="div"
              count={ideaFeedback?.feedback.length}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Stack>
      )}
    </>
  );
}
