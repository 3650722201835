import NotFoundView from "src/sections/error/not-found-view";

// ----------------------------------------------------------------------

export default function NotFoundPage() {
  return (
    <>
      <NotFoundView />
    </>
  );
}
