import { m } from "framer-motion";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import Iconify from "src/components/iconify";
import { RouterLink } from "src/routes/components";
import { varBounce, MotionContainer } from "src/components/animate";
import { varFade, MotionViewport } from "src/components/animate";

import LinksBox from "./links-box";
// ----------------------------------------------------------------------

export default function LinksComplete() {
  return (
    <Box
      sx={{
        py: 5,
        // ...bgGradient({
        //   color: alpha(theme.palette.background.default, 0.9),
        //   imgUrl: "/assets/background/overlay_1.jpg",
        // }),

        overflow: "hidden",
        textAlign: "center",
      }}
    >
      <Box sx={{ fontSize: 128 }}>🎉</Box>

      <Stack spacing={1} sx={{ my: 5 }}>
        <Typography variant="h3">Conversation Successfully Created</Typography>

        <Typography sx={{ color: "text.secondary" }}>
          Your Conversation links are now stored in your Moderator Dashboard
        </Typography>
      </Stack>
      <LinksBox />
    </Box>
  );
}
