import { useScroll } from "framer-motion";
import { useEffect } from "react";
import ScrollProgress from "src/components/scroll-progress";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";

import HomeHero from "../home-hero";
import HomeAbout from "../home-about";
import HomeServices from "../home-services";
import HomeContactForm from "../home-contact";
import HomeSimple from "../home-simple";

// ----------------------------------------------------------------------

export default function HomeView() {
  const { scrollYProgress } = useScroll();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { access, refresh, isAuthenticated, user, googleLoading } = useSelector(
    (state) => state.authData
  );

  return (
    <>
      <ScrollProgress scrollYProgress={scrollYProgress} />
      {googleLoading ? (
        <Stack
          alignItems="center"
          sx={{ pt: 10, minHeight: "100vh", maxHeight: "100vh" }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <HomeSimple />
          <HomeContactForm text={"Get Access to Tacit"} />
        </>
      )}
    </>
  );
}
