import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import ConversationFinishedResults from "./conversation-finished-results";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

export default function ConversationFinishedLanding() {
  const navigate = useNavigate();
  const location = useLocation();
  const results = location.state?.results;

  return (
    <Box
      sx={{
        py: 5,
        // ...bgGradient({
        //   color: alpha(theme.palette.background.default, 0.9),
        //   imgUrl: "/assets/background/overlay_1.jpg",
        // }),

        overflow: "hidden",
        textAlign: "center",
      }}
    >
      <Box sx={{ fontSize: 128 }}>🎉</Box>

      <Stack spacing={1} sx={{ my: 5 }}>
        {/* <Typography variant="h3">Congratulations</Typography>

        <Typography sx={{ color: "text.secondary" }}>
          All questions are complete. Thank you for contributing to the
          conversation!
        </Typography> */}

        <Container
          sx={{
            position: "relative",
            py: { xs: 5, md: 10 },
          }}
        >
          <Box
            sx={{
              borderWidth: 2,
              borderRadius: 3,
              textAlign: "center",
              borderStyle: "dashed",
              borderColor: (theme) => alpha(theme.palette.grey[500], 0.32),
              px: { xs: 3, md: 8 },
              py: { xs: 6, md: 8 },
            }}
          >
            <Typography variant="h3">
              Thanks for participating in this conversation
            </Typography>

            <Typography sx={{ mt: 3, mb: 5, color: "text.secondary" }}>
              Trying to talk to a group of your own? 
            </Typography>

            <Button
              size="large"
              color="inherit"
              variant="contained"
              onClick={() => navigate("/trial")}
            >
              Launch a Tacit
            </Button>
          </Box>
        </Container>
        {results?.results.conversation_result_id ? (
          <ConversationFinishedResults
            results={results?.results.conversation_result_id}
          />
        ) : null}
      </Stack>
    </Box>
  );
}
