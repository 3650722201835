import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getQuestions } from "../../actions/questionActions";
import {
  getConversation,
  getUserConversationResults,
} from "../../actions/conversationActions";
import { CircularProgress, Box, Typography, Button } from "@mui/material";
import Question from "./question/question";
import StartView from "src/sections/_start/view/start-view";

import ChatHome from "src/sections/_chat/chat-home";
import { useTransform } from "framer-motion";

const Questions = () => {
  const { conversation: conversationID } = useParams();
  const dispatch = useDispatch();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [rejoinPhase1, setRejoinPhase1] = useState(false);

  const { isLoading: isLoadingQuestion, questions } = useSelector(
    (state) => state.questionData
  );
  const {
    isLoading: isLoadingConversation,
    conversation,
    results,
  } = useSelector((state) => state.conversationData);
  const { user } = useSelector((state) => state.userData); // get the user data from state

  useEffect(() => {
    dispatch(getConversation(conversationID));
    dispatch(getQuestions(conversationID));
    dispatch(getUserConversationResults(conversationID));
  }, [dispatch]);

  if (isLoadingQuestion && isLoadingConversation) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  // If no user has been created yet
  if (!user && !isLoadingQuestion && !isLoadingConversation) {
    return (
      <StartView
        rejoinPhase1={rejoinPhase1}
        setRejoinPhase1={setRejoinPhase1}
      />
    );
  }

  const setNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  // Sort questions based on the `order` field
  let sortedQuestions = questions?.sort((a, b) => a.order - b.order);

  if (
    conversation?.data_analysis_complete &&
    conversation?.phase_2 &&
    !rejoinPhase1
  ) {
    sortedQuestions = sortedQuestions.filter(
      (question) => question.phase_2 === true
    );
  }

  return (
    <>
      <ChatHome title={conversation?.title} />
      <Box p={1}>
        <br />
        {sortedQuestions && sortedQuestions.length > 0 && (
          <Question
            key={sortedQuestions[currentQuestionIndex].question_id}
            question={sortedQuestions[currentQuestionIndex].question}
            questionId={sortedQuestions[currentQuestionIndex].question_id}
            order={sortedQuestions[currentQuestionIndex].order}
            phase2={sortedQuestions[currentQuestionIndex].phase_2}
            setNextQuestion={setNextQuestion}
            currentQuestionIndex={currentQuestionIndex}
            numQuestions={sortedQuestions.length}
            results={results}
            username={user?.name}
            analysisComplete={conversation?.data_analysis_complete}
            categories={sortedQuestions[currentQuestionIndex]?.categories}
            rejoinPhase1={rejoinPhase1}
            conversationPhase2={conversation?.phase_2}
          />
        )}
      </Box>
    </>
  );
};

export default Questions;
