import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import CircularProgress from "@mui/material/CircularProgress";

import { PieIdeasRatings } from "src/components/dashboard/pie-chart-idea-ratings";
import { RatingDistribution } from "src/components/dashboard/rating-distribution";
import { StackedQuestionRatings } from "src/components/dashboard/stacked-question-ratings";
import { TotalRatingsCard } from "src/components/dashboard/total-ratings-card";
import { BarCard } from "src/components/dashboard/bar-card";
import { getQuestionIdeaStats } from "src/actions/resultsIdeasActions";

const ResultsQuestionIdeasDashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { results: conversationResultsId, question: questionId } = useParams();
  const { isLoading: isLoadingIdeaStats, ideaStats } = useSelector(
    (state) => state.questionIdeasData
  );

  useEffect(() => {
    if (!ideaStats[questionId] && !isLoadingIdeaStats) {
      dispatch(getQuestionIdeaStats(conversationResultsId, questionId));
    }
  }, [questionId]);

  if (isLoadingIdeaStats) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {ideaStats[questionId]?.stats && (
        <Stack
          direction={{ xs: "column", md: "column" }}
          justifyContent="space-between"
          sx={{
            paddingLeft: { xs: 0, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 3,
            }}
          >
            <Container maxWidth="xl">
              {" "}
              <Container
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                  display: "grid",
                  gap: { xs: 5, md: 2 },
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                  },
                }}
              >
                <TotalRatingsCard
                  title="Total Ideas"
                  value={ideaStats[questionId]?.stats.num_ideas}
                  color={theme.palette.success.main}
                  icon="carbon:list"
                  info="Real-time count of all ideas generated throughout your question."
                />
                <TotalRatingsCard
                  title="IDEAS PER PARTICIPANT"
                  value={
                    ideaStats[questionId]?.stats.average_ideas_per_participant
                  }
                  color={theme.palette.warning.main}
                  icon="carbon:idea"
                  info="Average number of ideas generated per participant in the question."
                />
                <TotalRatingsCard
                  title="IDEA EXPOSURE COUNT"
                  value={ideaStats[questionId]?.stats.total_exposure_count}
                  color={theme.palette.error.main}
                  icon="carbon:asset"
                  info="Total count of participant ratings collected across all ideas in the question"
                />
                <BarCard
                  title="Average Idea Rating"
                  number={ideaStats[questionId]?.stats.average_rating}
                  percent={ideaStats[questionId]?.stats.average_rating * 20}
                  color={theme.palette.primary.main}
                  icon="carbon:star"
                  info="Average participant rating level across all ideas in the question"
                />
              </Container>
              {/* <Grid
                container
                spacing={3}
                sx={{
                  p: 3,
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Grid item xs={12} md={6}>
                  <PieIdeasRatings
                    title="Idea Rating Overview"
                    chartSeries={ideaStats[questionId]?.stats.donut_ratings}
                    labels={["Super", "Great", "Good", "Okay", "Poor"]}
                    sx={{ height: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RatingDistribution
                    chartSeries={[
                      {
                        name: "Ratings",
                        data: ideaStats[questionId]?.stats.histogram, // Example data for ratings 1 to 5 with 0.5 intervals
                      },
                    ]}
                    sx={{ height: "100%" }}
                    title="Rating Distribution"
                  />
                </Grid>
              </Grid> */}
            </Container>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default ResultsQuestionIdeasDashboard;
