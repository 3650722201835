import React, { useState, useEffect } from "react";
import { Paper, Typography, Box, IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const DraggableItem = ({ idea, index, moveItem, totalItems }) => {
  return (
    <Paper
      sx={{
        padding: "10px",
        margin: "5px 0",
        backgroundColor: "grey.200",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h6" style={{ marginRight: "10px" }}>
            {index + 1}.
          </Typography>
          <Typography>{idea.idea}</Typography>
        </Box>
        <Box sx={{ minWidth: 80 }}>
          <IconButton
            onClick={() => moveItem(index, index - 1)}
            disabled={index === 0}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            onClick={() => moveItem(index, index + 1)}
            disabled={index === totalItems - 1}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};

const IdeasRanker = ({ items, setItems }) => {
  const theme = useTheme();
  const moveItem = (currentIndex, newIndex) => {
    if (newIndex < 0 || newIndex >= items.length) return;

    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      const item = updatedItems.splice(currentIndex, 1)[0];
      updatedItems.splice(newIndex, 0, item);
      return updatedItems;
    });
  };

  return (
    <Stack>
      {items?.length > 0 && (
        <Typography variant="h5">
          Please rank order the below ideas from your top category:{" "}
          <span style={{ color: theme.palette.primary.main }}>
            {items[0]?.category_title}
          </span>{" "}
          from best (1) to worst ({items?.length}).
        </Typography>
      )}

      <Box sx={{ ml: 3 }}>
        {items?.map((item, index) => (
          <DraggableItem
            key={item.idea_id}
            idea={item}
            index={index}
            moveItem={moveItem}
            totalItems={items.length}
          />
        ))}
      </Box>
    </Stack>
  );
};

export default IdeasRanker;
