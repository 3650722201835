import ResultsQuestionSummaryView from "src/sections/_results/view/results-question-summary-view";

const ResultsQuestionSummaryPage = () => {
  return (
    <>
      <ResultsQuestionSummaryView />
    </>
  );
};

export default ResultsQuestionSummaryPage;
