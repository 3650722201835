import { useEffect } from "react";
import LinksComplete from "../links-complete";
import LinkStep from "../link-step";

export default function LinksView() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <LinksComplete />
      <LinkStep />
    </>
  );
}
