import ResultsQuestionAbout from "../question/results-question-about";
import ResultsQuestionIdeas from "../question/results-question-ideas";
import ResultsComingSoon from "../results-coming-soon";
import ResultsQuestionIdeasDashboard from "../question/results-question-ideas-dashboard";
import ResultsQuestionIdeasTable from "../question/results-question-ideas-table";
export default function ResultsQuestionIdeasView() {
  return (
    <>
      {/* <ResultsQuestionAbout /> */}
      <ResultsQuestionIdeas />
      <ResultsQuestionIdeasDashboard />
      <ResultsQuestionIdeasTable />
    </>
  );
}
