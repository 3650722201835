import PropTypes from "prop-types";
import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Iconify from "src/components/iconify";

import { NavSectionVertical } from "src/components/nav-section";
import {
  getResultQuestions,
  getResultConversation,
} from "src/actions/resultActions";

const defaultConfig = {
  itemGap: 4,
  iconSize: 24,
  currentRole: "admin",
  itemRootHeight: 44,
  itemSubHeight: 36,
  itemPadding: "4px 8px 4px 12px",
  itemRadius: 8,
  hiddenLabel: false,
};

export default function Nav() {
  const [config, setConfig] = useState(defaultConfig);
  const dispatch = useDispatch();
  const { results: getResultQuestionsId } = useParams();

  const { isLoading: isLoadingQuestion, questions } = useSelector(
    (state) => state.questionData
  );

  const { isLoading: isLoadingConversation, conversation } = useSelector(
    (state) => state.conversationData
  );

  useEffect(() => {
    if (!questions && !isLoadingQuestion) {
      dispatch(getResultQuestions(getResultQuestionsId));
    }

    // This is needed for other components
    if (!conversation && !isLoadingConversation) {
      dispatch(getResultConversation(getResultQuestionsId));
    }
  }, []);

  const sortedQuestions = questions?.sort((a, b) => a.order - b.order);

  const questionHasPhase2 = questions?.some(
    (question) => question.phase_2 === true
  );

  const questionList =
    sortedQuestions?.map((q, index) => {
      const children = [
        {
          title: "Summary",
          path: `${`/results/${getResultQuestionsId}/categories/${q.question_id}`}`,
        },
      ];

      children.push({
        title: "Ideas",
        path: `${`/results/${getResultQuestionsId}/ideas/${q.question_id}`}`,
      });

      children.push({
        title: "User Responses",
        path: `${`/results/${getResultQuestionsId}/user-responses/${q.question_id}`}`,
      });

      children.push({
        title: "Ask Tacit AI",
        path: `${`/results/${getResultQuestionsId}/summary/${q.question_id}`}`,
      });

      return {
        title: `${q.question.slice(0, 13)}...`,
        path: "#",
        icon: <Iconify icon="carbon:task-add" width={1} />,
        children,
      };
    }) || [];

  const conversationItems = [
    {
      title: "Home",
      path: `${`/results/${getResultQuestionsId}`}`,
      icon: <Iconify icon="carbon:home" width={1} />,
    },
  ];

  // if (questionHasPhase2) {
  //   conversationItems.push({
  //     title: "Ideas",
  //     path: `${`/results/${getResultQuestionsId}/ideas`}`,
  //     icon: <Iconify icon="carbon:idea" width={1} />,
  //   });
  // }
  // conversationItems.push({
  //   title: "Data",
  //   path: `${`/results/${getResultQuestionsId}/data`}`,
  //   icon: <Iconify icon="carbon:group-access" width={1} />,
  // });

  // conversationItems.push({
  //   title: "Feedback",
  //   path: `${`/results/${getResultQuestionsId}/feedback`}`,
  //   icon: <Iconify icon="carbon:certificate-check" width={1} />,
  // });

  const NAV_ITEMS = [
    {
      subheader: "Overview",
      items: conversationItems,
    },
    {
      subheader: "Results",
      items: questionList,
    },
  ];

  return (
    <NavSectionVertical
      data={NAV_ITEMS}
      config={config}
      sx={{
        py: 2,
        borderRadius: 2,
        minWidth: "200px",
        bgcolor: "background.paper",
        boxShadow: (theme) => theme.customShadows.z20,
      }}
    />
  );
}
