import { useState, useCallback } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton, { toggleButtonClasses } from "@mui/material/ToggleButton";
import { Button } from "@mui/material";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { _pricing01 } from "../pricing/_pricing";

import PlanCard from "../pricing/account-pricing-card";
import { useDispatch, useSelector } from "react-redux";
import { purchaseProduct } from "src/actions/paymentActions";

// ----------------------------------------------------------------------
const TABS = ["Team Plans", "Individual Plans", "Enterprise"];

export default function AccountPaymentView() {
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState("monthly");
  const [tab, setTab] = useState(TABS[0]);

  const handleChangeSubscription = useCallback((event, newValue) => {
    if (newValue !== null) {
      setSubscription(newValue);
    }
  }, []);

  const handleChangeTab = useCallback((event, newValue) => {
    setTab(newValue);
  }, []);

  const handlePurchase = async () => {
    try {
      const response = await dispatch(
        purchaseProduct({ price_id: "price_1NxgqjBl1kNMLgALrHGji1Xz" })
      );
      if (response && response.url) {
        window.location.href = response.url;
      }
    } catch (error) {
      console.error("Error while purchasing:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h2" align="center" paragraph>
        Choose a Plan that Works for You
      </Typography>
      <Button onClick={handlePurchase}>Test Purchase</Button>

      <Stack sx={{ my: { xs: 5, md: 8 } }} alignItems="center">
        <Tabs
          value={tab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
          sx={{
            "& .MuiTab-root": { fontSize: "1rem", padding: "20px 30px" },
          }}
        >
          {TABS.map((category) => (
            <Tab key={category} value={category} label={category} />
          ))}
        </Tabs>
      </Stack>

      <Box
        sx={{
          gap: 4,
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
          },
        }}
      >
        {_pricing01.map((plan) => (
          <PlanCard key={plan.license} plan={plan} />
        ))}
      </Box>
    </Container>
  );
}
