import React, { useState, useRef, useEffect } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { sendMessage } from "src/actions/messageActions";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import AlertDialog from "./alert";
import { useForm, Controller } from "react-hook-form";
import Stack from "@mui/material/Stack";

import Avatar from "@mui/material/Avatar";
import { useResponsive } from "src/hooks/use-responsive";

import Iconify from "src/components/iconify";
import { fDate } from "src/utils/format-time";
import Rating from "@mui/material/Rating";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import ProgressBar from "./progressBar";
import { useTheme } from "@mui/material/styles";
import SkipAlert from "src/components/questions/chatbox/skip-alert";
import Divider from "@mui/material/Divider";
import Slider from "@mui/material/Slider";
import StarIcon from "@mui/icons-material/Star";
import { palette } from "src/theme/palette";
import { pl } from "date-fns/locale";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";

import { summarizeAndIdeas } from "src/actions/questionActions";

const ChatBox = ({
  questionId,
  question,
  setNextQuestion,
  currentQuestionIndex,
  numQuestions,
  results,
  phase2,
  order,
  username,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stop, setStop] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [phase, setPhase] = useState(1);
  const [score, setScore] = useState(0); // Score for exposed ideas
  const [relevancy, setRelevancy] = useState(-1); // Score for relevancy
  const [typingMessage, setTypingMessage] = useState(null);
  const [index, setIndex] = useState(0);
  const [conversationLength, setConversationLength] = useState(1);
  const words = question.split(" ");
  const messagesEndRef = useRef(null);
  const mdUp = useResponsive("up", "md");

  const { user } = useSelector((state) => state.userData);
  const { isLoading: isLoadingMessages, messages } = useSelector(
    (state) => state.messageData
  );

  const {
    isLoading: isLoadingBaseConversation,
    conversation: baseConversation,
  } = useSelector((state) => state.conversationData);

  const [conversation, setConversation] = useState([]);
  const [typingStarted, setTypingStarted] = useState(false);
  const [stopWriting, setStopWriting] = useState(false);

  // const ratingQuestion1 = "You were familiar with the original question topic.";

  // const ratingQuestion2 = "The questions didn't feel repetitive.";

  const ratingQuestion3 = "The conversation felt natural and organic.";

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.returnValue = "Are you sure you want to leave?";
      // In some older browsers, returning a string also works.
      return "Are you sure you want to leave?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(
    () => {
      setStopWriting(message.length > 1000);
      if (!typingStarted && conversation.length === 0) {
        dispatch(
          sendMessage({
            message_type: "phase1",
            text: question,
            question: questionId,
            sender_type: "G",
            sender: user.user_id,
            rating: score,
            related: relevancy,
            skip: false,
          })
        );
        setTypingStarted(true);
        firstAnimation();
      }
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    },
    [conversation, relevancy],
    message
  );

  useEffect(() => {
    setConversationLength(conversation.length);
  }, [conversation]);

  const firstAnimation = async () => {
    // Start a new typing animation
    setTypingMessage({
      id: conversation.length + 1,
      from: "Tacit AI",
    });

    const words = question.split(" ");
    let messageText = "";

    const typingInterval = setInterval(() => {
      messageText += (messageText ? " " : "") + words.shift();
      setTypingMessage((prevMessage) => ({
        ...prevMessage,
        text: messageText,
      }));

      // If all words have been added, add the message to the conversation and stop the interval
      if (words.length === 0) {
        clearInterval(typingInterval);
        setConversation([
          {
            id: 1,
            from: "chatGPT",
            text: question,
            phase: phase,
          },
        ]);
        setTypingStarted(false);
        setTypingMessage("");
      }
    }, 50);
  };

  const handleSendMessage = async () => {
    console.log(score);
    setConversationLength(conversation.length + 1);
    const sentMessage = message;
    setIndex(index + 1);
    setMessage("");
    if (message.trim() !== "" || phase === 2) {
      if (message.trim() !== "") {
        setConversation((prevConversation) => [
          ...prevConversation,
          {
            id: prevConversation.length + 1,
            from: "user",
            text: sentMessage,
            phase: phase,
          },
        ]);
      }

      let gpt_response;
      try {
        gpt_response = await dispatch(
          sendMessage({
            message_type: "phase1",
            text: sentMessage,
            question: questionId,
            sender_type: "U",
            sender: user.user_id,
            rating: score,
            related: relevancy,
            skip: false,
          })
        );

        if (gpt_response?.phase == undefined) {
          console.log("Error: bad response: ", gpt_response);
          gpt_response = { text: "Sorry, an error occurred.", phase: phase };
        } else {
          setStop(gpt_response?.stop);
          setProgress(gpt_response?.progress);
          setPhase(gpt_response?.phase);

          if (gpt_response?.stop) {
            dispatch(
              summarizeAndIdeas({
                question: questionId,
                user: user.user_id,
              })
            );
          }
        }
      } catch (error) {
        console.error("Error occurred while sending message:", error);
        gpt_response = { text: "Sorry, an error occurred.", phase: phase };
      }

      setScore(0);
      setRelevancy(-1);

      // Start a new typing animation
      let typingIndex = 0;
      setTypingMessage({
        id: conversation.length + 2, // This should match the ID of the message that will eventually be added
        from: "chatGPT",
        text: "",
        phase: gpt_response?.phase,
      });

      const words = gpt_response?.text.split(" ");
      let wordIndex = 0;

      const typingInterval = setInterval(() => {
        wordIndex++;
        setTypingMessage((prevMessage) => ({
          ...prevMessage,
          text:
            prevMessage.text +
            (prevMessage.text ? " " : "") +
            words[wordIndex - 1],
        }));

        // If all words have been added, add the message to the conversation and stop the interval
        if (wordIndex >= words?.length) {
          clearInterval(typingInterval);
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              id: prevConversation.length + 1,
              from: "chatGPT",
              text: gpt_response?.text,
              phase: gpt_response?.phase,
            },
          ]);
          setTypingMessage(null);
        }
      }, 50);
    }
  };

  const endQuestion = () => {
    if (!stop && conversation.length > 1) {
      dispatch(
        summarizeAndIdeas({
          question: questionId,
          user: user.user_id,
        })
      );
    }

    if (currentQuestionIndex < numQuestions - 1) {
      setNextQuestion();
      setStop(false);
    } else {
      console.log("Results: ", results);
      navigate("/finished", { state: { results: results } });
    }
  };

  let UpperLevel;
  if (mdUp) {
    UpperLevel = Container;
  } else {
    UpperLevel = Stack;
  }

  return (
    <UpperLevel mb={!mdUp ? 7 : null}>
      {/* {!mdUp ? (
        <Stack
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h5" mb={3}>
            Topic {order}: {question}
          </Typography>
        </Stack>
      ) : null} */}

      <Container
        sx={{ position: "sticky", top: 60, zIndex: 1000, bgcolor: "white" }}
      >
        <Stack direction="column" pb={mdUp ? 2 : 1}>
          {/* <Typography variant="h5">
            Topic {order}: {question}
          </Typography> */}

          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <ProgressBar
              title={
                mdUp && phase == 1
                  ? "Individual Insight Progress"
                  : mdUp && phase == 2
                  ? "Crowd Validation Progress"
                  : !mdUp && phase == 1
                  ? "Ideation Progress"
                  : "Rating Progress"
              }
              currentProgress={progress}
              color={phase == 1 ? "primary" : "progress"}
            />

            {!stop ? (
              <AlertDialog
                openAlert={stop}
                title={
                  currentQuestionIndex < numQuestions - 1
                    ? "Go to next question?"
                    : "End conversation"
                }
                description="Questioning will end automatically, however sometimes the conversatoin fizzles out. Confirm to move on."
                buttonText={mdUp ? "End Question" : "End"}
                onConfirm={endQuestion}
                questionId={questionId}
                userId={user.user_id}
                // ratingQuestion1={ratingQuestion1}
                // ratingQuestion2={ratingQuestion2}
                ratingQuestion3={ratingQuestion3}
                // ratingQuestion4={ratingQuestion4}
                // ratingQuestion5={ratingQuestion5}
                skipped={true}
              />
            ) : null}
          </Stack>
        </Stack>
      </Container>
      {mdUp && (
        <Stack pb={1}>
          <Divider color={theme.palette.grey[600]} />
        </Stack>
      )}

      <Box pb={mdUp ? 10 : 0}>
        {conversation.map(({ id, from, text, phase }) => (
          <Box key={id} mb={1}>
            <ReviewItem
              name={from === "user" ? "You" : "Tacit AI"}
              message={text}
              username={username}
              phase={phase}
              score={score}
              setScore={setScore}
              setRelevancy={setRelevancy}
              last={id === conversationLength}
              stop={stop}
            />
          </Box>
        ))}

        {typingMessage && (
          <Box key={typingMessage.id}>
            <ReviewItem
              name={typingMessage.from === "user" ? "You" : "Tacit AI"}
              message={typingMessage.text}
              username={username}
              phase={typingMessage.phase}
              score={score}
              setScore={setScore}
              setRelevancy={setRelevancy}
            />
          </Box>
        )}

        {isLoadingMessages && <CircularProgress />}
      </Box>
      <div ref={messagesEndRef} />

      {stop ? null : (
        <Stack
          direction="row"
          pt={1}
          pb={mdUp ? 4 : 1}
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          bgcolor="white"
        >
          <Container>
            <Stack direction="row" alignItems="center">
              <TextField
                variant="outlined"
                size="small"
                multiline
                maxRows={3}
                value={message.slice(0, 750)}
                disabled={stop}
                placeholder={
                  phase == 2 ? "Additional thoughts? (optional)" : null
                }
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey && !stop) {
                    e.preventDefault(); // prevents the addition of a new line when enter is pressed
                    handleSendMessage();
                  }
                }}
                fullWidth
                inputProps={{
                  style: { fontSize: 16 },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendMessage}
                style={{
                  marginLeft: "10px",
                  height: "40px",
                }}
                disabled={
                  stop ||
                  isLoadingMessages ||
                  (message.length === 0 && phase === 1) ||
                  (phase == 2 && relevancy == -1) ||
                  (phase == 2 && relevancy == 1 && score < 1)
                }
              >
                {/* <ArrowUpwardIcon /> */}
                Reply
              </Button>
            </Stack>
          </Container>
        </Stack>
      )}

      {stop ? (
        <AlertDialog
          openAlert={stop}
          title={
            currentQuestionIndex < numQuestions - 1
              ? "Go to next question?"
              : "End conversation"
          }
          // description="Please rate the question before moving on."
          description="Thank you for finishing the question. Confirm to move on."
          buttonText={mdUp ? "Next Question" : "Next"}
          onConfirm={endQuestion}
          questionId={questionId}
          userId={user.user_id}
          // ratingQuestion1={ratingQuestion1}
          // ratingQuestion2={ratingQuestion2}
          // ratingQuestion3={ratingQuestion3}
          // ratingQuestion4={ratingQuestion4}
          // ratingQuestion5={ratingQuestion5}
          skipped={false}
        />
      ) : null}
    </UpperLevel>
  );
};

export default ChatBox;
// ----------------------------------------------------------------------

function ReviewItem({
  name,
  message,
  username,
  phase,
  score,
  setScore,
  setRelevancy,
  last,
  stop,
}) {
  const theme = useTheme();
  const mdUp = useResponsive("up", "md");
  const { control, reset } = useForm();
  const [yesClicked, setYesClicked] = useState(false);
  const [noClicked, setNoClicked] = useState(false);

  const labels = [
    "Strongly Disagree /\n Very Low Quality",
    "Disagree / Low Quality ",
    "Neutral / Moderate Quality",
    "Agree / High Quality",
    "Strongly Agree / Very High Quality",
  ];

  const valueLabelFormat = (value) => {
    return labels[value - 1];
  };

  const marks = labels.map((label, index) => ({
    value: index,
    label: label,
  }));

  return (
    <Stack
      direction={mdUp ? "row" : "column"}
      spacing={2}
      alignItems={mdUp ? "center" : null}
      sx={{
        borderRadius: 2,
        py: 4,
        p: 3,
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        bgcolor:
          phase === 2 && name === "Tacit AI"
            ? "warning.light"
            : name === "Tacit AI"
            ? "grey.200"
            : "#CAF0F8",
      }}
    >
      {mdUp ? (
        <Avatar
          alt={username}
          src={
            phase === 2 && name === "Tacit AI"
              ? "/assets/images/avatar/group.png"
              : name === "Tacit AI"
              ? "/assets/images/avatar/avatar_tacit.jpg"
              : "username"
          }
          sx={{
            width: 64,
            height: 64,
            mr: 2.5,
            bgcolor:
              name != "Tacit AI"
                ? theme.palette.primary.darker
                : phase == 1
                ? theme.palette.primary.main
                : null,
            color: "#ffffff",
            border:
              phase === 2 && name === "Tacit AI" ? "2px solid #000000" : null,
          }}
        />
      ) : null}
      <Stack direction="column">
        <Stack>
          {!mdUp ? <Typography variant="h6">{name}</Typography> : null}

          <Typography variant="body1">{message}</Typography>
        </Stack>

        {phase == 2 && name === "Tacit AI" && !stop && last && (
          <Stack
            spacing={1}
            direction="column"
            alignItems="right"
            sx={{ pt: 2 }}
          >
            <Typography variant="subtitle2">
              Is this idea relevant to the original topic?
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                size="small"
                color="inherit"
                onClick={() => {
                  setYesClicked(true);
                  setNoClicked(false);
                  setRelevancy(1);
                  setScore(3);
                }}
                startIcon={
                  <Iconify
                    icon={
                      yesClicked
                        ? "carbon:thumbs-up-filled"
                        : "carbon:thumbs-up"
                    }
                  />
                }
              >
                Yes
              </Button>

              <Button
                size="small"
                color="inherit"
                onClick={() => {
                  setNoClicked(true);
                  setYesClicked(false);
                  setRelevancy(0);
                  setScore(0);
                  reset();
                }}
                startIcon={
                  <Iconify
                    icon={
                      noClicked
                        ? "carbon:thumbs-down-filled"
                        : "carbon:thumbs-down"
                    }
                  />
                }
              >
                No
              </Button>
            </Stack>
            {yesClicked && (
              <Stack>
                <Typography variant="subtitle2" gutterBottom>
                  Your evaluation of this idea:
                </Typography>
                <Slider
                  track={false}
                  value={score}
                  step={1}
                  min={1}
                  max={5}
                  marks
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value, index) =>
                    ValueLabelComponent(value)
                  }
                  onChange={(event, newValue) => {
                    setScore(newValue);
                  }}
                  sx={{
                    width: "200px",
                    color: "black",
                    ml: { xs: 5, md: 0 },
                    // pt: 6,
                  }}
                />
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

function ValueLabelComponent(value) {
  const labels = [
    "Strongly Disagree",
    "Disagree",
    "Neutral",
    "Agree",
    "Strongly Agree",
  ];

  const labels2 = [
    "Very Low Quality",
    "Low Quality",
    "Moderate Quality",
    "High Quality",
    "Very High Quality",
  ];

  return (
    <Stack alignItems="center">
      <div>{labels[value - 1]}</div>
      <div>{labels2[value - 1]}</div>
    </Stack>
  );
}
