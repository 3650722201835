import { useEffect } from "react";
import ResultsIdeaAbout from "../idea/results-idea-about";
import ResultsIdeaTable from "../idea/results-idea-table";
export default function ResultsIdeaView() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ResultsIdeaAbout />
      <ResultsIdeaTable />
    </>
  );
}
