import {
  START_LOADING_CREATED_CONVERSATIONS,
  END_LOADING_CREATED_CONVERSATIONS,
  FETCH_CREATED_CONVERSATIONS,
  START_LOADING_CREATED_CONVERSATION,
  END_LOADING_CREATED_CONVERSATION,
  FETCH_CREATED_CONVERSATION,
  CHANGE_CONVERSATION_STATUS,
  CHANGE_CONVERSATION_REJOIN,
  DELETE_CONVERSATION,
  START_LOADING_ADD_ACCOUNT,
  END_LOADING_ADD_ACCOUNT,
  START_LOADING_REMOVE_ACCOUNT,
  END_LOADING_REMOVE_ACCOUNT,
} from "../actions/actionTypes";

const initialState = {
  isLoadingCreatedConversations: false,
  isLoadingCreatedConversation: false,
  isLoadingAddAccount: false,
  isLoadingRemoveAccount: false,
  createdConversations: [],
  createdConversation: {},
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case START_LOADING_CREATED_CONVERSATIONS:
      return {
        ...state,
        isLoadingCreatedConversations: true,
      };
    case END_LOADING_CREATED_CONVERSATIONS:
      return {
        ...state,
        isLoadingCreatedConversations: false,
      };
    case START_LOADING_ADD_ACCOUNT:
      return {
        ...state,
        isLoadingAddAccount: true,
      };
    case END_LOADING_ADD_ACCOUNT:
      return {
        ...state,
        isLoadingAddAccount: false,
      };
    case START_LOADING_REMOVE_ACCOUNT:
      return {
        ...state,
        isLoadingRemoveAccount: true,
      };
    case END_LOADING_REMOVE_ACCOUNT:
      return {
        ...state,
        isLoadingRemoveAccount: false,
      };
    case FETCH_CREATED_CONVERSATIONS:
      return {
        ...state,
        createdConversations: action.payload,
      };
    case START_LOADING_CREATED_CONVERSATION:
      return {
        ...state,
        isLoadingCreatedConversation: true,
      };
    case END_LOADING_CREATED_CONVERSATION:
      return {
        ...state,
        isLoadingCreatedConversation: false,
      };
    case FETCH_CREATED_CONVERSATION:
      return {
        ...state,
        createdConversation: action.payload,
      };
    case DELETE_CONVERSATION:
      return {
        ...state,
        createdConversation: {},
      };
    case CHANGE_CONVERSATION_STATUS:
      return state;
    case CHANGE_CONVERSATION_REJOIN:
      return state;
    default:
      return state;
  }
}
