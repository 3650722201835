import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import { useResponsive } from "src/hooks/use-responsive";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ChatHome({ title }) {
  const mdUp = useResponsive("up", "md");
  return (
    <Box
      sx={{
        pt: 5,
        // ...bgGradient({
        //   color: alpha(theme.palette.background.default, 0.9),
        //   imgUrl: "/assets/background/overlay_1.jpg",
        // }),

        overflow: "hidden",
        textAlign: "center",
      }}
    >
      {mdUp && (
        <Container
          sx={{
            pt: 5,
          }}
        >
          <Typography
            paragraph
            variant="overline"
            sx={{
              color: "primary.main",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Conversation
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Grid xs={12} md={6} lg={5}>
              <Typography variant="h3">{title}</Typography>
            </Grid>

            {/* <Grid xs={12} md={6} lg={6} sx={{ color: "text.secondary" }}>
              <Stack
                spacing={{ xs: 3, md: 10 }}
                direction={{ xs: "column", md: "row" }}
              >
                <Typography>
                  Get ready to experience interaction like never before. Delve
                  into the depths of Tacit AI's cognitive abilities, challenge
                  its understanding, and allow it to challenge you back. Embark
                  on a journey of exploration, learning, and engagement.
                </Typography>
              </Stack>
            </Grid> */}
          </Grid>{" "}
        </Container>
      )}
    </Box>
  );
}
