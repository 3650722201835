import { useEffect } from "react";
import ConversationFinishedLanding from "../conversation-finished-landing";

export default function ConversationFinishedView() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ConversationFinishedLanding />
    </>
  );
}
