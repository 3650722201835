import {
  FETCH_SINGLE_CATEGORY,
  START_LOADING_SINGLE_CATEGORY,
  END_LOADING_SINGLE_CATEGORY,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  category: [],
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_SINGLE_CATEGORY:
      return { ...state, isLoading: true };
    case END_LOADING_SINGLE_CATEGORY:
      return { ...state, isLoading: false };
    case FETCH_SINGLE_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
