import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  List,
  ListItemText,
  IconButton,
  Snackbar,
  Grid,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import CodeIcon from "@mui/icons-material/Code";
import { styled } from "@mui/system";
import { alpha } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const CustomIconButton = styled(IconButton)({
  color: "#03045E",
});

const LinksBox = () => {
  const { conversation, results } = useParams(); // this will get the conversation and results from the url
  const baseURL = window.location.origin;
  const shareURL = `${baseURL}/questions/${conversation}`;
  const responseURL = `${baseURL}/results/${results}`;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(shareURL);
    setOpen(true);
  };
  const handleClickResults = () => {
    navigator.clipboard.writeText(responseURL);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        borderWidth: 2,
        borderRadius: 3,
        textAlign: "center",
        borderStyle: "dashed",
        borderColor: (theme) => alpha(theme.palette.grey[500], 0.32),
        px: { xs: 3, md: 8 },
        py: { xs: 6, md: 8 },
        width: { xs: "95%", md: "50%" },
        margin: "auto",
      }}
    >
      <List>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={9}>
            <ListItemText>
              <Typography variant="h6">
                Share this URL with your participants:
              </Typography>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                <a
                  href={shareURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {shareURL}
                </a>
              </Typography>
            </ListItemText>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Tooltip title="Copy to Clipboard">
              <CustomIconButton
                edge="end"
                aria-label="share"
                onClick={handleClick}
              >
                <ShareIcon />
              </CustomIconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={9}>
            <ListItemText>
              <Typography variant="h6">
                Use this URL to view results:
              </Typography>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                <a
                  href={responseURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {responseURL}
                </a>
              </Typography>
            </ListItemText>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Tooltip title="Copy to Clipboard">
              <CustomIconButton
                edge="end"
                aria-label="share"
                onClick={handleClickResults}
              >
                <CodeIcon />
              </CustomIconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </List>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Link copied to clipboard"
      />
    </Box>
  );
};

export default LinksBox;
